import { z } from 'zod';
import { Prisma } from '@prisma/client';

/////////////////////////////////////////
// HELPER FUNCTIONS
/////////////////////////////////////////

// JSON
//------------------------------------------------------

export type NullableJsonInput = Prisma.JsonValue | null | 'JsonNull' | 'DbNull' | Prisma.NullTypes.DbNull | Prisma.NullTypes.JsonNull;

export const transformJsonNull = (v?: NullableJsonInput) => {
  if (!v || v === 'DbNull') return Prisma.DbNull;
  if (v === 'JsonNull') return Prisma.JsonNull;
  return v;
};

export const JsonValueSchema: z.ZodType<Prisma.JsonValue> = z.lazy(() =>
  z.union([
    z.string(),
    z.number(),
    z.boolean(),
    z.literal(null),
    z.record(z.lazy(() => JsonValueSchema.optional())),
    z.array(z.lazy(() => JsonValueSchema)),
  ])
);

export type JsonValueType = z.infer<typeof JsonValueSchema>;

export const NullableJsonValue = z
  .union([JsonValueSchema, z.literal('DbNull'), z.literal('JsonNull')])
  .nullable()
  .transform((v) => transformJsonNull(v));

export type NullableJsonValueType = z.infer<typeof NullableJsonValue>;

export const InputJsonValueSchema: z.ZodType<Prisma.InputJsonValue> = z.lazy(() =>
  z.union([
    z.string(),
    z.number(),
    z.boolean(),
    z.object({ toJSON: z.function(z.tuple([]), z.any()) }),
    z.record(z.lazy(() => z.union([InputJsonValueSchema, z.literal(null)]))),
    z.array(z.lazy(() => z.union([InputJsonValueSchema, z.literal(null)]))),
  ])
);

export type InputJsonValueType = z.infer<typeof InputJsonValueSchema>;


/////////////////////////////////////////
// ENUMS
/////////////////////////////////////////

export const TransactionIsolationLevelSchema = z.enum(['ReadUncommitted','ReadCommitted','RepeatableRead','Serializable']);

export const WorldScalarFieldEnumSchema = z.enum(['id','title','video','image','description','createdAt','updatedAt']);

export const TagScalarFieldEnumSchema = z.enum(['id','title','createdAt','updatedAt']);

export const SlapScalarFieldEnumSchema = z.enum(['id','slug','title','bpm','key','currentBpm','currentKey','isRoot','isPremade','isDeleted','duration','skinId','parentId','rootId','creatorId','createdAt','updatedAt']);

export const SkinScalarFieldEnumSchema = z.enum(['id','video','image','bgColor','textColor','createdAt','updatedAt']);

export const TrackScalarFieldEnumSchema = z.enum(['id','src','title','data','isSoloed','isMuted','slapId','createdAt','updatedAt']);

export const AccountScalarFieldEnumSchema = z.enum(['id','userId','type','provider','providerAccountId','refresh_token','access_token','expires_at','token_type','scope','id_token','session_state']);

export const SessionScalarFieldEnumSchema = z.enum(['id','sessionToken','userId','expires']);

export const VerificationTokenScalarFieldEnumSchema = z.enum(['identifier','token','expires']);

export const UserScalarFieldEnumSchema = z.enum(['id','name','email','createdAt','updatedAt','lastSeen','emailVerified','role','image']);

export const SampleScalarFieldEnumSchema = z.enum(['id','createdAt','updatedAt','instrument','source','genre','artist','title','original_filename','url','bpm','key','duration','downbeat_times']);

export const SortOrderSchema = z.enum(['asc','desc']);

export const QueryModeSchema = z.enum(['default','insensitive']);

export const NullsOrderSchema = z.enum(['first','last']);

export const RoleSchema = z.enum(['ADMIN','USER']);

export type RoleType = `${z.infer<typeof RoleSchema>}`

/////////////////////////////////////////
// MODELS
/////////////////////////////////////////

/////////////////////////////////////////
// WORLD SCHEMA
/////////////////////////////////////////

export const WorldSchema = z.object({
  id: z.string(),
  title: z.string(),
  video: z.string(),
  image: z.string(),
  description: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export type World = z.infer<typeof WorldSchema>

/////////////////////////////////////////
// TAG SCHEMA
/////////////////////////////////////////

export const TagSchema = z.object({
  id: z.string(),
  title: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export type Tag = z.infer<typeof TagSchema>

/////////////////////////////////////////
// SLAP SCHEMA
/////////////////////////////////////////

export const SlapSchema = z.object({
  id: z.string(),
  slug: z.string().nullable(),
  title: z.string(),
  bpm: z.number().int(),
  key: z.string(),
  currentBpm: z.number().int().nullable(),
  currentKey: z.string().nullable(),
  isRoot: z.boolean(),
  isPremade: z.boolean(),
  isDeleted: z.boolean(),
  duration: z.number(),
  skinId: z.string(),
  parentId: z.string().nullable(),
  rootId: z.string().nullable(),
  creatorId: z.string().nullable(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export type Slap = z.infer<typeof SlapSchema>

/////////////////////////////////////////
// SKIN SCHEMA
/////////////////////////////////////////

export const SkinSchema = z.object({
  id: z.string(),
  video: z.string(),
  image: z.string(),
  bgColor: z.string(),
  textColor: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export type Skin = z.infer<typeof SkinSchema>

/////////////////////////////////////////
// TRACK SCHEMA
/////////////////////////////////////////

export const TrackSchema = z.object({
  id: z.string(),
  src: z.string(),
  title: z.string(),
  data: JsonValueSchema.array(),
  isSoloed: z.boolean().nullable(),
  isMuted: z.boolean().nullable(),
  slapId: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export type Track = z.infer<typeof TrackSchema>

/////////////////////////////////////////
// ACCOUNT SCHEMA
/////////////////////////////////////////

export const AccountSchema = z.object({
  id: z.string(),
  userId: z.string(),
  type: z.string(),
  provider: z.string(),
  providerAccountId: z.string(),
  refresh_token: z.string().nullable(),
  access_token: z.string().nullable(),
  expires_at: z.number().int().nullable(),
  token_type: z.string().nullable(),
  scope: z.string().nullable(),
  id_token: z.string().nullable(),
  session_state: z.string().nullable(),
})

export type Account = z.infer<typeof AccountSchema>

/////////////////////////////////////////
// SESSION SCHEMA
/////////////////////////////////////////

export const SessionSchema = z.object({
  id: z.string(),
  sessionToken: z.string(),
  userId: z.string(),
  expires: z.coerce.date(),
})

export type Session = z.infer<typeof SessionSchema>

/////////////////////////////////////////
// VERIFICATION TOKEN SCHEMA
/////////////////////////////////////////

export const VerificationTokenSchema = z.object({
  identifier: z.string(),
  token: z.string(),
  expires: z.coerce.date(),
})

export type VerificationToken = z.infer<typeof VerificationTokenSchema>

/////////////////////////////////////////
// USER SCHEMA
/////////////////////////////////////////

export const UserSchema = z.object({
  role: RoleSchema,
  id: z.string(),
  name: z.string().nullable(),
  email: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  lastSeen: z.coerce.date().nullable(),
  emailVerified: z.coerce.date().nullable(),
  image: z.string().nullable(),
})

export type User = z.infer<typeof UserSchema>

/////////////////////////////////////////
// SAMPLE SCHEMA
/////////////////////////////////////////

export const SampleSchema = z.object({
  id: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  instrument: z.string(),
  source: z.string(),
  genre: z.string().array(),
  artist: z.string().nullable(),
  title: z.string(),
  original_filename: z.string(),
  url: z.string(),
  bpm: z.number().int(),
  key: z.string(),
  duration: z.number(),
  downbeat_times: z.number().array(),
})

export type Sample = z.infer<typeof SampleSchema>

/////////////////////////////////////////
// SELECT & INCLUDE
/////////////////////////////////////////

// WORLD
//------------------------------------------------------

export const WorldIncludeSchema: z.ZodType<Prisma.WorldInclude> = z.object({
  users: z.union([z.boolean(),z.lazy(() => UserFindManyArgsSchema)]).optional(),
  slaps: z.union([z.boolean(),z.lazy(() => SlapFindManyArgsSchema)]).optional(),
  tags: z.union([z.boolean(),z.lazy(() => TagFindManyArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => WorldCountOutputTypeArgsSchema)]).optional(),
}).strict()

export const WorldArgsSchema: z.ZodType<Prisma.WorldDefaultArgs> = z.object({
  select: z.lazy(() => WorldSelectSchema).optional(),
  include: z.lazy(() => WorldIncludeSchema).optional(),
}).strict();

export const WorldCountOutputTypeArgsSchema: z.ZodType<Prisma.WorldCountOutputTypeDefaultArgs> = z.object({
  select: z.lazy(() => WorldCountOutputTypeSelectSchema).nullish(),
}).strict();

export const WorldCountOutputTypeSelectSchema: z.ZodType<Prisma.WorldCountOutputTypeSelect> = z.object({
  users: z.boolean().optional(),
  slaps: z.boolean().optional(),
  tags: z.boolean().optional(),
}).strict();

export const WorldSelectSchema: z.ZodType<Prisma.WorldSelect> = z.object({
  id: z.boolean().optional(),
  title: z.boolean().optional(),
  video: z.boolean().optional(),
  image: z.boolean().optional(),
  description: z.boolean().optional(),
  createdAt: z.boolean().optional(),
  updatedAt: z.boolean().optional(),
  users: z.union([z.boolean(),z.lazy(() => UserFindManyArgsSchema)]).optional(),
  slaps: z.union([z.boolean(),z.lazy(() => SlapFindManyArgsSchema)]).optional(),
  tags: z.union([z.boolean(),z.lazy(() => TagFindManyArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => WorldCountOutputTypeArgsSchema)]).optional(),
}).strict()

// TAG
//------------------------------------------------------

export const TagIncludeSchema: z.ZodType<Prisma.TagInclude> = z.object({
  slaps: z.union([z.boolean(),z.lazy(() => SlapFindManyArgsSchema)]).optional(),
  worlds: z.union([z.boolean(),z.lazy(() => WorldFindManyArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => TagCountOutputTypeArgsSchema)]).optional(),
}).strict()

export const TagArgsSchema: z.ZodType<Prisma.TagDefaultArgs> = z.object({
  select: z.lazy(() => TagSelectSchema).optional(),
  include: z.lazy(() => TagIncludeSchema).optional(),
}).strict();

export const TagCountOutputTypeArgsSchema: z.ZodType<Prisma.TagCountOutputTypeDefaultArgs> = z.object({
  select: z.lazy(() => TagCountOutputTypeSelectSchema).nullish(),
}).strict();

export const TagCountOutputTypeSelectSchema: z.ZodType<Prisma.TagCountOutputTypeSelect> = z.object({
  slaps: z.boolean().optional(),
  worlds: z.boolean().optional(),
}).strict();

export const TagSelectSchema: z.ZodType<Prisma.TagSelect> = z.object({
  id: z.boolean().optional(),
  title: z.boolean().optional(),
  createdAt: z.boolean().optional(),
  updatedAt: z.boolean().optional(),
  slaps: z.union([z.boolean(),z.lazy(() => SlapFindManyArgsSchema)]).optional(),
  worlds: z.union([z.boolean(),z.lazy(() => WorldFindManyArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => TagCountOutputTypeArgsSchema)]).optional(),
}).strict()

// SLAP
//------------------------------------------------------

export const SlapIncludeSchema: z.ZodType<Prisma.SlapInclude> = z.object({
  skin: z.union([z.boolean(),z.lazy(() => SkinArgsSchema)]).optional(),
  creator: z.union([z.boolean(),z.lazy(() => UserArgsSchema)]).optional(),
  worlds: z.union([z.boolean(),z.lazy(() => WorldFindManyArgsSchema)]).optional(),
  tags: z.union([z.boolean(),z.lazy(() => TagFindManyArgsSchema)]).optional(),
  tracks: z.union([z.boolean(),z.lazy(() => TrackFindManyArgsSchema)]).optional(),
  parent: z.union([z.boolean(),z.lazy(() => SlapArgsSchema)]).optional(),
  root: z.union([z.boolean(),z.lazy(() => SlapArgsSchema)]).optional(),
  children: z.union([z.boolean(),z.lazy(() => SlapFindManyArgsSchema)]).optional(),
  descendants: z.union([z.boolean(),z.lazy(() => SlapFindManyArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => SlapCountOutputTypeArgsSchema)]).optional(),
}).strict()

export const SlapArgsSchema: z.ZodType<Prisma.SlapDefaultArgs> = z.object({
  select: z.lazy(() => SlapSelectSchema).optional(),
  include: z.lazy(() => SlapIncludeSchema).optional(),
}).strict();

export const SlapCountOutputTypeArgsSchema: z.ZodType<Prisma.SlapCountOutputTypeDefaultArgs> = z.object({
  select: z.lazy(() => SlapCountOutputTypeSelectSchema).nullish(),
}).strict();

export const SlapCountOutputTypeSelectSchema: z.ZodType<Prisma.SlapCountOutputTypeSelect> = z.object({
  worlds: z.boolean().optional(),
  tags: z.boolean().optional(),
  tracks: z.boolean().optional(),
  children: z.boolean().optional(),
  descendants: z.boolean().optional(),
}).strict();

export const SlapSelectSchema: z.ZodType<Prisma.SlapSelect> = z.object({
  id: z.boolean().optional(),
  slug: z.boolean().optional(),
  title: z.boolean().optional(),
  bpm: z.boolean().optional(),
  key: z.boolean().optional(),
  currentBpm: z.boolean().optional(),
  currentKey: z.boolean().optional(),
  isRoot: z.boolean().optional(),
  isPremade: z.boolean().optional(),
  isDeleted: z.boolean().optional(),
  duration: z.boolean().optional(),
  skinId: z.boolean().optional(),
  parentId: z.boolean().optional(),
  rootId: z.boolean().optional(),
  creatorId: z.boolean().optional(),
  createdAt: z.boolean().optional(),
  updatedAt: z.boolean().optional(),
  skin: z.union([z.boolean(),z.lazy(() => SkinArgsSchema)]).optional(),
  creator: z.union([z.boolean(),z.lazy(() => UserArgsSchema)]).optional(),
  worlds: z.union([z.boolean(),z.lazy(() => WorldFindManyArgsSchema)]).optional(),
  tags: z.union([z.boolean(),z.lazy(() => TagFindManyArgsSchema)]).optional(),
  tracks: z.union([z.boolean(),z.lazy(() => TrackFindManyArgsSchema)]).optional(),
  parent: z.union([z.boolean(),z.lazy(() => SlapArgsSchema)]).optional(),
  root: z.union([z.boolean(),z.lazy(() => SlapArgsSchema)]).optional(),
  children: z.union([z.boolean(),z.lazy(() => SlapFindManyArgsSchema)]).optional(),
  descendants: z.union([z.boolean(),z.lazy(() => SlapFindManyArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => SlapCountOutputTypeArgsSchema)]).optional(),
}).strict()

// SKIN
//------------------------------------------------------

export const SkinIncludeSchema: z.ZodType<Prisma.SkinInclude> = z.object({
  slaps: z.union([z.boolean(),z.lazy(() => SlapFindManyArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => SkinCountOutputTypeArgsSchema)]).optional(),
}).strict()

export const SkinArgsSchema: z.ZodType<Prisma.SkinDefaultArgs> = z.object({
  select: z.lazy(() => SkinSelectSchema).optional(),
  include: z.lazy(() => SkinIncludeSchema).optional(),
}).strict();

export const SkinCountOutputTypeArgsSchema: z.ZodType<Prisma.SkinCountOutputTypeDefaultArgs> = z.object({
  select: z.lazy(() => SkinCountOutputTypeSelectSchema).nullish(),
}).strict();

export const SkinCountOutputTypeSelectSchema: z.ZodType<Prisma.SkinCountOutputTypeSelect> = z.object({
  slaps: z.boolean().optional(),
}).strict();

export const SkinSelectSchema: z.ZodType<Prisma.SkinSelect> = z.object({
  id: z.boolean().optional(),
  video: z.boolean().optional(),
  image: z.boolean().optional(),
  bgColor: z.boolean().optional(),
  textColor: z.boolean().optional(),
  createdAt: z.boolean().optional(),
  updatedAt: z.boolean().optional(),
  slaps: z.union([z.boolean(),z.lazy(() => SlapFindManyArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => SkinCountOutputTypeArgsSchema)]).optional(),
}).strict()

// TRACK
//------------------------------------------------------

export const TrackIncludeSchema: z.ZodType<Prisma.TrackInclude> = z.object({
  slap: z.union([z.boolean(),z.lazy(() => SlapArgsSchema)]).optional(),
}).strict()

export const TrackArgsSchema: z.ZodType<Prisma.TrackDefaultArgs> = z.object({
  select: z.lazy(() => TrackSelectSchema).optional(),
  include: z.lazy(() => TrackIncludeSchema).optional(),
}).strict();

export const TrackSelectSchema: z.ZodType<Prisma.TrackSelect> = z.object({
  id: z.boolean().optional(),
  src: z.boolean().optional(),
  title: z.boolean().optional(),
  data: z.boolean().optional(),
  isSoloed: z.boolean().optional(),
  isMuted: z.boolean().optional(),
  slapId: z.boolean().optional(),
  createdAt: z.boolean().optional(),
  updatedAt: z.boolean().optional(),
  slap: z.union([z.boolean(),z.lazy(() => SlapArgsSchema)]).optional(),
}).strict()

// ACCOUNT
//------------------------------------------------------

export const AccountIncludeSchema: z.ZodType<Prisma.AccountInclude> = z.object({
  user: z.union([z.boolean(),z.lazy(() => UserArgsSchema)]).optional(),
}).strict()

export const AccountArgsSchema: z.ZodType<Prisma.AccountDefaultArgs> = z.object({
  select: z.lazy(() => AccountSelectSchema).optional(),
  include: z.lazy(() => AccountIncludeSchema).optional(),
}).strict();

export const AccountSelectSchema: z.ZodType<Prisma.AccountSelect> = z.object({
  id: z.boolean().optional(),
  userId: z.boolean().optional(),
  type: z.boolean().optional(),
  provider: z.boolean().optional(),
  providerAccountId: z.boolean().optional(),
  refresh_token: z.boolean().optional(),
  access_token: z.boolean().optional(),
  expires_at: z.boolean().optional(),
  token_type: z.boolean().optional(),
  scope: z.boolean().optional(),
  id_token: z.boolean().optional(),
  session_state: z.boolean().optional(),
  user: z.union([z.boolean(),z.lazy(() => UserArgsSchema)]).optional(),
}).strict()

// SESSION
//------------------------------------------------------

export const SessionIncludeSchema: z.ZodType<Prisma.SessionInclude> = z.object({
  user: z.union([z.boolean(),z.lazy(() => UserArgsSchema)]).optional(),
}).strict()

export const SessionArgsSchema: z.ZodType<Prisma.SessionDefaultArgs> = z.object({
  select: z.lazy(() => SessionSelectSchema).optional(),
  include: z.lazy(() => SessionIncludeSchema).optional(),
}).strict();

export const SessionSelectSchema: z.ZodType<Prisma.SessionSelect> = z.object({
  id: z.boolean().optional(),
  sessionToken: z.boolean().optional(),
  userId: z.boolean().optional(),
  expires: z.boolean().optional(),
  user: z.union([z.boolean(),z.lazy(() => UserArgsSchema)]).optional(),
}).strict()

// VERIFICATION TOKEN
//------------------------------------------------------

export const VerificationTokenSelectSchema: z.ZodType<Prisma.VerificationTokenSelect> = z.object({
  identifier: z.boolean().optional(),
  token: z.boolean().optional(),
  expires: z.boolean().optional(),
}).strict()

// USER
//------------------------------------------------------

export const UserIncludeSchema: z.ZodType<Prisma.UserInclude> = z.object({
  accounts: z.union([z.boolean(),z.lazy(() => AccountFindManyArgsSchema)]).optional(),
  sessions: z.union([z.boolean(),z.lazy(() => SessionFindManyArgsSchema)]).optional(),
  slaps: z.union([z.boolean(),z.lazy(() => SlapFindManyArgsSchema)]).optional(),
  worlds: z.union([z.boolean(),z.lazy(() => WorldFindManyArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => UserCountOutputTypeArgsSchema)]).optional(),
}).strict()

export const UserArgsSchema: z.ZodType<Prisma.UserDefaultArgs> = z.object({
  select: z.lazy(() => UserSelectSchema).optional(),
  include: z.lazy(() => UserIncludeSchema).optional(),
}).strict();

export const UserCountOutputTypeArgsSchema: z.ZodType<Prisma.UserCountOutputTypeDefaultArgs> = z.object({
  select: z.lazy(() => UserCountOutputTypeSelectSchema).nullish(),
}).strict();

export const UserCountOutputTypeSelectSchema: z.ZodType<Prisma.UserCountOutputTypeSelect> = z.object({
  accounts: z.boolean().optional(),
  sessions: z.boolean().optional(),
  slaps: z.boolean().optional(),
  worlds: z.boolean().optional(),
}).strict();

export const UserSelectSchema: z.ZodType<Prisma.UserSelect> = z.object({
  id: z.boolean().optional(),
  name: z.boolean().optional(),
  email: z.boolean().optional(),
  createdAt: z.boolean().optional(),
  updatedAt: z.boolean().optional(),
  lastSeen: z.boolean().optional(),
  emailVerified: z.boolean().optional(),
  role: z.boolean().optional(),
  image: z.boolean().optional(),
  accounts: z.union([z.boolean(),z.lazy(() => AccountFindManyArgsSchema)]).optional(),
  sessions: z.union([z.boolean(),z.lazy(() => SessionFindManyArgsSchema)]).optional(),
  slaps: z.union([z.boolean(),z.lazy(() => SlapFindManyArgsSchema)]).optional(),
  worlds: z.union([z.boolean(),z.lazy(() => WorldFindManyArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => UserCountOutputTypeArgsSchema)]).optional(),
}).strict()

// SAMPLE
//------------------------------------------------------

export const SampleSelectSchema: z.ZodType<Prisma.SampleSelect> = z.object({
  id: z.boolean().optional(),
  createdAt: z.boolean().optional(),
  updatedAt: z.boolean().optional(),
  instrument: z.boolean().optional(),
  source: z.boolean().optional(),
  genre: z.boolean().optional(),
  artist: z.boolean().optional(),
  title: z.boolean().optional(),
  original_filename: z.boolean().optional(),
  url: z.boolean().optional(),
  bpm: z.boolean().optional(),
  key: z.boolean().optional(),
  duration: z.boolean().optional(),
  downbeat_times: z.boolean().optional(),
}).strict()


/////////////////////////////////////////
// INPUT TYPES
/////////////////////////////////////////

export const WorldWhereInputSchema: z.ZodType<Prisma.WorldWhereInput> = z.object({
  AND: z.union([ z.lazy(() => WorldWhereInputSchema),z.lazy(() => WorldWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => WorldWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => WorldWhereInputSchema),z.lazy(() => WorldWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  title: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  video: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  image: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  description: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  users: z.lazy(() => UserListRelationFilterSchema).optional(),
  slaps: z.lazy(() => SlapListRelationFilterSchema).optional(),
  tags: z.lazy(() => TagListRelationFilterSchema).optional()
}).strict();

export const WorldOrderByWithRelationInputSchema: z.ZodType<Prisma.WorldOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  title: z.lazy(() => SortOrderSchema).optional(),
  video: z.lazy(() => SortOrderSchema).optional(),
  image: z.lazy(() => SortOrderSchema).optional(),
  description: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  users: z.lazy(() => UserOrderByRelationAggregateInputSchema).optional(),
  slaps: z.lazy(() => SlapOrderByRelationAggregateInputSchema).optional(),
  tags: z.lazy(() => TagOrderByRelationAggregateInputSchema).optional()
}).strict();

export const WorldWhereUniqueInputSchema: z.ZodType<Prisma.WorldWhereUniqueInput> = z.union([
  z.object({
    id: z.string(),
    title: z.string()
  }),
  z.object({
    id: z.string(),
  }),
  z.object({
    title: z.string(),
  }),
])
.and(z.object({
  id: z.string().optional(),
  title: z.string().optional(),
  AND: z.union([ z.lazy(() => WorldWhereInputSchema),z.lazy(() => WorldWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => WorldWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => WorldWhereInputSchema),z.lazy(() => WorldWhereInputSchema).array() ]).optional(),
  video: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  image: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  description: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  users: z.lazy(() => UserListRelationFilterSchema).optional(),
  slaps: z.lazy(() => SlapListRelationFilterSchema).optional(),
  tags: z.lazy(() => TagListRelationFilterSchema).optional()
}).strict());

export const WorldOrderByWithAggregationInputSchema: z.ZodType<Prisma.WorldOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  title: z.lazy(() => SortOrderSchema).optional(),
  video: z.lazy(() => SortOrderSchema).optional(),
  image: z.lazy(() => SortOrderSchema).optional(),
  description: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => WorldCountOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => WorldMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => WorldMinOrderByAggregateInputSchema).optional()
}).strict();

export const WorldScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.WorldScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => WorldScalarWhereWithAggregatesInputSchema),z.lazy(() => WorldScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => WorldScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => WorldScalarWhereWithAggregatesInputSchema),z.lazy(() => WorldScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  title: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  video: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  image: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  description: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
}).strict();

export const TagWhereInputSchema: z.ZodType<Prisma.TagWhereInput> = z.object({
  AND: z.union([ z.lazy(() => TagWhereInputSchema),z.lazy(() => TagWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => TagWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => TagWhereInputSchema),z.lazy(() => TagWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  title: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  slaps: z.lazy(() => SlapListRelationFilterSchema).optional(),
  worlds: z.lazy(() => WorldListRelationFilterSchema).optional()
}).strict();

export const TagOrderByWithRelationInputSchema: z.ZodType<Prisma.TagOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  title: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  slaps: z.lazy(() => SlapOrderByRelationAggregateInputSchema).optional(),
  worlds: z.lazy(() => WorldOrderByRelationAggregateInputSchema).optional()
}).strict();

export const TagWhereUniqueInputSchema: z.ZodType<Prisma.TagWhereUniqueInput> = z.union([
  z.object({
    id: z.string(),
    title: z.string()
  }),
  z.object({
    id: z.string(),
  }),
  z.object({
    title: z.string(),
  }),
])
.and(z.object({
  id: z.string().optional(),
  title: z.string().optional(),
  AND: z.union([ z.lazy(() => TagWhereInputSchema),z.lazy(() => TagWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => TagWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => TagWhereInputSchema),z.lazy(() => TagWhereInputSchema).array() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  slaps: z.lazy(() => SlapListRelationFilterSchema).optional(),
  worlds: z.lazy(() => WorldListRelationFilterSchema).optional()
}).strict());

export const TagOrderByWithAggregationInputSchema: z.ZodType<Prisma.TagOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  title: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => TagCountOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => TagMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => TagMinOrderByAggregateInputSchema).optional()
}).strict();

export const TagScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.TagScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => TagScalarWhereWithAggregatesInputSchema),z.lazy(() => TagScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => TagScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => TagScalarWhereWithAggregatesInputSchema),z.lazy(() => TagScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  title: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
}).strict();

export const SlapWhereInputSchema: z.ZodType<Prisma.SlapWhereInput> = z.object({
  AND: z.union([ z.lazy(() => SlapWhereInputSchema),z.lazy(() => SlapWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => SlapWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => SlapWhereInputSchema),z.lazy(() => SlapWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  slug: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  title: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  bpm: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  key: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  currentBpm: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  currentKey: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  isRoot: z.union([ z.lazy(() => BoolFilterSchema),z.boolean() ]).optional(),
  isPremade: z.union([ z.lazy(() => BoolFilterSchema),z.boolean() ]).optional(),
  isDeleted: z.union([ z.lazy(() => BoolFilterSchema),z.boolean() ]).optional(),
  duration: z.union([ z.lazy(() => FloatFilterSchema),z.number() ]).optional(),
  skinId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  parentId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  rootId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  creatorId: z.union([ z.lazy(() => UuidNullableFilterSchema),z.string() ]).optional().nullable(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  skin: z.union([ z.lazy(() => SkinRelationFilterSchema),z.lazy(() => SkinWhereInputSchema) ]).optional(),
  creator: z.union([ z.lazy(() => UserNullableRelationFilterSchema),z.lazy(() => UserWhereInputSchema) ]).optional().nullable(),
  worlds: z.lazy(() => WorldListRelationFilterSchema).optional(),
  tags: z.lazy(() => TagListRelationFilterSchema).optional(),
  tracks: z.lazy(() => TrackListRelationFilterSchema).optional(),
  parent: z.union([ z.lazy(() => SlapNullableRelationFilterSchema),z.lazy(() => SlapWhereInputSchema) ]).optional().nullable(),
  root: z.union([ z.lazy(() => SlapNullableRelationFilterSchema),z.lazy(() => SlapWhereInputSchema) ]).optional().nullable(),
  children: z.lazy(() => SlapListRelationFilterSchema).optional(),
  descendants: z.lazy(() => SlapListRelationFilterSchema).optional()
}).strict();

export const SlapOrderByWithRelationInputSchema: z.ZodType<Prisma.SlapOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  slug: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  title: z.lazy(() => SortOrderSchema).optional(),
  bpm: z.lazy(() => SortOrderSchema).optional(),
  key: z.lazy(() => SortOrderSchema).optional(),
  currentBpm: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  currentKey: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  isRoot: z.lazy(() => SortOrderSchema).optional(),
  isPremade: z.lazy(() => SortOrderSchema).optional(),
  isDeleted: z.lazy(() => SortOrderSchema).optional(),
  duration: z.lazy(() => SortOrderSchema).optional(),
  skinId: z.lazy(() => SortOrderSchema).optional(),
  parentId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  rootId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  creatorId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  skin: z.lazy(() => SkinOrderByWithRelationInputSchema).optional(),
  creator: z.lazy(() => UserOrderByWithRelationInputSchema).optional(),
  worlds: z.lazy(() => WorldOrderByRelationAggregateInputSchema).optional(),
  tags: z.lazy(() => TagOrderByRelationAggregateInputSchema).optional(),
  tracks: z.lazy(() => TrackOrderByRelationAggregateInputSchema).optional(),
  parent: z.lazy(() => SlapOrderByWithRelationInputSchema).optional(),
  root: z.lazy(() => SlapOrderByWithRelationInputSchema).optional(),
  children: z.lazy(() => SlapOrderByRelationAggregateInputSchema).optional(),
  descendants: z.lazy(() => SlapOrderByRelationAggregateInputSchema).optional()
}).strict();

export const SlapWhereUniqueInputSchema: z.ZodType<Prisma.SlapWhereUniqueInput> = z.union([
  z.object({
    id: z.string(),
    slug: z.string()
  }),
  z.object({
    id: z.string(),
  }),
  z.object({
    slug: z.string(),
  }),
])
.and(z.object({
  id: z.string().optional(),
  slug: z.string().optional(),
  AND: z.union([ z.lazy(() => SlapWhereInputSchema),z.lazy(() => SlapWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => SlapWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => SlapWhereInputSchema),z.lazy(() => SlapWhereInputSchema).array() ]).optional(),
  title: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  bpm: z.union([ z.lazy(() => IntFilterSchema),z.number().int() ]).optional(),
  key: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  currentBpm: z.union([ z.lazy(() => IntNullableFilterSchema),z.number().int() ]).optional().nullable(),
  currentKey: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  isRoot: z.union([ z.lazy(() => BoolFilterSchema),z.boolean() ]).optional(),
  isPremade: z.union([ z.lazy(() => BoolFilterSchema),z.boolean() ]).optional(),
  isDeleted: z.union([ z.lazy(() => BoolFilterSchema),z.boolean() ]).optional(),
  duration: z.union([ z.lazy(() => FloatFilterSchema),z.number() ]).optional(),
  skinId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  parentId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  rootId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  creatorId: z.union([ z.lazy(() => UuidNullableFilterSchema),z.string() ]).optional().nullable(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  skin: z.union([ z.lazy(() => SkinRelationFilterSchema),z.lazy(() => SkinWhereInputSchema) ]).optional(),
  creator: z.union([ z.lazy(() => UserNullableRelationFilterSchema),z.lazy(() => UserWhereInputSchema) ]).optional().nullable(),
  worlds: z.lazy(() => WorldListRelationFilterSchema).optional(),
  tags: z.lazy(() => TagListRelationFilterSchema).optional(),
  tracks: z.lazy(() => TrackListRelationFilterSchema).optional(),
  parent: z.union([ z.lazy(() => SlapNullableRelationFilterSchema),z.lazy(() => SlapWhereInputSchema) ]).optional().nullable(),
  root: z.union([ z.lazy(() => SlapNullableRelationFilterSchema),z.lazy(() => SlapWhereInputSchema) ]).optional().nullable(),
  children: z.lazy(() => SlapListRelationFilterSchema).optional(),
  descendants: z.lazy(() => SlapListRelationFilterSchema).optional()
}).strict());

export const SlapOrderByWithAggregationInputSchema: z.ZodType<Prisma.SlapOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  slug: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  title: z.lazy(() => SortOrderSchema).optional(),
  bpm: z.lazy(() => SortOrderSchema).optional(),
  key: z.lazy(() => SortOrderSchema).optional(),
  currentBpm: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  currentKey: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  isRoot: z.lazy(() => SortOrderSchema).optional(),
  isPremade: z.lazy(() => SortOrderSchema).optional(),
  isDeleted: z.lazy(() => SortOrderSchema).optional(),
  duration: z.lazy(() => SortOrderSchema).optional(),
  skinId: z.lazy(() => SortOrderSchema).optional(),
  parentId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  rootId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  creatorId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => SlapCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => SlapAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => SlapMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => SlapMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => SlapSumOrderByAggregateInputSchema).optional()
}).strict();

export const SlapScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.SlapScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => SlapScalarWhereWithAggregatesInputSchema),z.lazy(() => SlapScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => SlapScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => SlapScalarWhereWithAggregatesInputSchema),z.lazy(() => SlapScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  slug: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  title: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  bpm: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  key: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  currentBpm: z.union([ z.lazy(() => IntNullableWithAggregatesFilterSchema),z.number() ]).optional().nullable(),
  currentKey: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  isRoot: z.union([ z.lazy(() => BoolWithAggregatesFilterSchema),z.boolean() ]).optional(),
  isPremade: z.union([ z.lazy(() => BoolWithAggregatesFilterSchema),z.boolean() ]).optional(),
  isDeleted: z.union([ z.lazy(() => BoolWithAggregatesFilterSchema),z.boolean() ]).optional(),
  duration: z.union([ z.lazy(() => FloatWithAggregatesFilterSchema),z.number() ]).optional(),
  skinId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  parentId: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  rootId: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  creatorId: z.union([ z.lazy(() => UuidNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  createdAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
}).strict();

export const SkinWhereInputSchema: z.ZodType<Prisma.SkinWhereInput> = z.object({
  AND: z.union([ z.lazy(() => SkinWhereInputSchema),z.lazy(() => SkinWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => SkinWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => SkinWhereInputSchema),z.lazy(() => SkinWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  video: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  image: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  bgColor: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  textColor: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  slaps: z.lazy(() => SlapListRelationFilterSchema).optional()
}).strict();

export const SkinOrderByWithRelationInputSchema: z.ZodType<Prisma.SkinOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  video: z.lazy(() => SortOrderSchema).optional(),
  image: z.lazy(() => SortOrderSchema).optional(),
  bgColor: z.lazy(() => SortOrderSchema).optional(),
  textColor: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  slaps: z.lazy(() => SlapOrderByRelationAggregateInputSchema).optional()
}).strict();

export const SkinWhereUniqueInputSchema: z.ZodType<Prisma.SkinWhereUniqueInput> = z.object({
  id: z.string()
})
.and(z.object({
  id: z.string().optional(),
  AND: z.union([ z.lazy(() => SkinWhereInputSchema),z.lazy(() => SkinWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => SkinWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => SkinWhereInputSchema),z.lazy(() => SkinWhereInputSchema).array() ]).optional(),
  video: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  image: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  bgColor: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  textColor: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  slaps: z.lazy(() => SlapListRelationFilterSchema).optional()
}).strict());

export const SkinOrderByWithAggregationInputSchema: z.ZodType<Prisma.SkinOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  video: z.lazy(() => SortOrderSchema).optional(),
  image: z.lazy(() => SortOrderSchema).optional(),
  bgColor: z.lazy(() => SortOrderSchema).optional(),
  textColor: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => SkinCountOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => SkinMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => SkinMinOrderByAggregateInputSchema).optional()
}).strict();

export const SkinScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.SkinScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => SkinScalarWhereWithAggregatesInputSchema),z.lazy(() => SkinScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => SkinScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => SkinScalarWhereWithAggregatesInputSchema),z.lazy(() => SkinScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  video: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  image: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  bgColor: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  textColor: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
}).strict();

export const TrackWhereInputSchema: z.ZodType<Prisma.TrackWhereInput> = z.object({
  AND: z.union([ z.lazy(() => TrackWhereInputSchema),z.lazy(() => TrackWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => TrackWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => TrackWhereInputSchema),z.lazy(() => TrackWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  src: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  title: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  data: z.lazy(() => JsonNullableListFilterSchema).optional(),
  isSoloed: z.union([ z.lazy(() => BoolNullableFilterSchema),z.boolean() ]).optional().nullable(),
  isMuted: z.union([ z.lazy(() => BoolNullableFilterSchema),z.boolean() ]).optional().nullable(),
  slapId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  slap: z.union([ z.lazy(() => SlapRelationFilterSchema),z.lazy(() => SlapWhereInputSchema) ]).optional(),
}).strict();

export const TrackOrderByWithRelationInputSchema: z.ZodType<Prisma.TrackOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  src: z.lazy(() => SortOrderSchema).optional(),
  title: z.lazy(() => SortOrderSchema).optional(),
  data: z.lazy(() => SortOrderSchema).optional(),
  isSoloed: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  isMuted: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  slapId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  slap: z.lazy(() => SlapOrderByWithRelationInputSchema).optional()
}).strict();

export const TrackWhereUniqueInputSchema: z.ZodType<Prisma.TrackWhereUniqueInput> = z.object({
  id: z.string()
})
.and(z.object({
  id: z.string().optional(),
  AND: z.union([ z.lazy(() => TrackWhereInputSchema),z.lazy(() => TrackWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => TrackWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => TrackWhereInputSchema),z.lazy(() => TrackWhereInputSchema).array() ]).optional(),
  src: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  title: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  data: z.lazy(() => JsonNullableListFilterSchema).optional(),
  isSoloed: z.union([ z.lazy(() => BoolNullableFilterSchema),z.boolean() ]).optional().nullable(),
  isMuted: z.union([ z.lazy(() => BoolNullableFilterSchema),z.boolean() ]).optional().nullable(),
  slapId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  slap: z.union([ z.lazy(() => SlapRelationFilterSchema),z.lazy(() => SlapWhereInputSchema) ]).optional(),
}).strict());

export const TrackOrderByWithAggregationInputSchema: z.ZodType<Prisma.TrackOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  src: z.lazy(() => SortOrderSchema).optional(),
  title: z.lazy(() => SortOrderSchema).optional(),
  data: z.lazy(() => SortOrderSchema).optional(),
  isSoloed: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  isMuted: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  slapId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => TrackCountOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => TrackMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => TrackMinOrderByAggregateInputSchema).optional()
}).strict();

export const TrackScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.TrackScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => TrackScalarWhereWithAggregatesInputSchema),z.lazy(() => TrackScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => TrackScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => TrackScalarWhereWithAggregatesInputSchema),z.lazy(() => TrackScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  src: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  title: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  data: z.lazy(() => JsonNullableListFilterSchema).optional(),
  isSoloed: z.union([ z.lazy(() => BoolNullableWithAggregatesFilterSchema),z.boolean() ]).optional().nullable(),
  isMuted: z.union([ z.lazy(() => BoolNullableWithAggregatesFilterSchema),z.boolean() ]).optional().nullable(),
  slapId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
}).strict();

export const AccountWhereInputSchema: z.ZodType<Prisma.AccountWhereInput> = z.object({
  AND: z.union([ z.lazy(() => AccountWhereInputSchema),z.lazy(() => AccountWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => AccountWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => AccountWhereInputSchema),z.lazy(() => AccountWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => UuidFilterSchema),z.string() ]).optional(),
  userId: z.union([ z.lazy(() => UuidFilterSchema),z.string() ]).optional(),
  type: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  provider: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  providerAccountId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  refresh_token: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  access_token: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  expires_at: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  token_type: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  scope: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  id_token: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  session_state: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  user: z.union([ z.lazy(() => UserRelationFilterSchema),z.lazy(() => UserWhereInputSchema) ]).optional(),
}).strict();

export const AccountOrderByWithRelationInputSchema: z.ZodType<Prisma.AccountOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  userId: z.lazy(() => SortOrderSchema).optional(),
  type: z.lazy(() => SortOrderSchema).optional(),
  provider: z.lazy(() => SortOrderSchema).optional(),
  providerAccountId: z.lazy(() => SortOrderSchema).optional(),
  refresh_token: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  access_token: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  expires_at: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  token_type: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  scope: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  id_token: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  session_state: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  user: z.lazy(() => UserOrderByWithRelationInputSchema).optional()
}).strict();

export const AccountWhereUniqueInputSchema: z.ZodType<Prisma.AccountWhereUniqueInput> = z.union([
  z.object({
    id: z.string(),
    provider_providerAccountId: z.lazy(() => AccountProviderProviderAccountIdCompoundUniqueInputSchema)
  }),
  z.object({
    id: z.string(),
  }),
  z.object({
    provider_providerAccountId: z.lazy(() => AccountProviderProviderAccountIdCompoundUniqueInputSchema),
  }),
])
.and(z.object({
  id: z.string().optional(),
  provider_providerAccountId: z.lazy(() => AccountProviderProviderAccountIdCompoundUniqueInputSchema).optional(),
  AND: z.union([ z.lazy(() => AccountWhereInputSchema),z.lazy(() => AccountWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => AccountWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => AccountWhereInputSchema),z.lazy(() => AccountWhereInputSchema).array() ]).optional(),
  userId: z.union([ z.lazy(() => UuidFilterSchema),z.string() ]).optional(),
  type: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  provider: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  providerAccountId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  refresh_token: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  access_token: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  expires_at: z.union([ z.lazy(() => IntNullableFilterSchema),z.number().int() ]).optional().nullable(),
  token_type: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  scope: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  id_token: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  session_state: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  user: z.union([ z.lazy(() => UserRelationFilterSchema),z.lazy(() => UserWhereInputSchema) ]).optional(),
}).strict());

export const AccountOrderByWithAggregationInputSchema: z.ZodType<Prisma.AccountOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  userId: z.lazy(() => SortOrderSchema).optional(),
  type: z.lazy(() => SortOrderSchema).optional(),
  provider: z.lazy(() => SortOrderSchema).optional(),
  providerAccountId: z.lazy(() => SortOrderSchema).optional(),
  refresh_token: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  access_token: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  expires_at: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  token_type: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  scope: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  id_token: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  session_state: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  _count: z.lazy(() => AccountCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => AccountAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => AccountMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => AccountMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => AccountSumOrderByAggregateInputSchema).optional()
}).strict();

export const AccountScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.AccountScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => AccountScalarWhereWithAggregatesInputSchema),z.lazy(() => AccountScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => AccountScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => AccountScalarWhereWithAggregatesInputSchema),z.lazy(() => AccountScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => UuidWithAggregatesFilterSchema),z.string() ]).optional(),
  userId: z.union([ z.lazy(() => UuidWithAggregatesFilterSchema),z.string() ]).optional(),
  type: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  provider: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  providerAccountId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  refresh_token: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  access_token: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  expires_at: z.union([ z.lazy(() => IntNullableWithAggregatesFilterSchema),z.number() ]).optional().nullable(),
  token_type: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  scope: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  id_token: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  session_state: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
}).strict();

export const SessionWhereInputSchema: z.ZodType<Prisma.SessionWhereInput> = z.object({
  AND: z.union([ z.lazy(() => SessionWhereInputSchema),z.lazy(() => SessionWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => SessionWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => SessionWhereInputSchema),z.lazy(() => SessionWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => UuidFilterSchema),z.string() ]).optional(),
  sessionToken: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  userId: z.union([ z.lazy(() => UuidFilterSchema),z.string() ]).optional(),
  expires: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  user: z.union([ z.lazy(() => UserRelationFilterSchema),z.lazy(() => UserWhereInputSchema) ]).optional(),
}).strict();

export const SessionOrderByWithRelationInputSchema: z.ZodType<Prisma.SessionOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  sessionToken: z.lazy(() => SortOrderSchema).optional(),
  userId: z.lazy(() => SortOrderSchema).optional(),
  expires: z.lazy(() => SortOrderSchema).optional(),
  user: z.lazy(() => UserOrderByWithRelationInputSchema).optional()
}).strict();

export const SessionWhereUniqueInputSchema: z.ZodType<Prisma.SessionWhereUniqueInput> = z.union([
  z.object({
    id: z.string(),
    sessionToken: z.string()
  }),
  z.object({
    id: z.string(),
  }),
  z.object({
    sessionToken: z.string(),
  }),
])
.and(z.object({
  id: z.string().optional(),
  sessionToken: z.string().optional(),
  AND: z.union([ z.lazy(() => SessionWhereInputSchema),z.lazy(() => SessionWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => SessionWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => SessionWhereInputSchema),z.lazy(() => SessionWhereInputSchema).array() ]).optional(),
  userId: z.union([ z.lazy(() => UuidFilterSchema),z.string() ]).optional(),
  expires: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  user: z.union([ z.lazy(() => UserRelationFilterSchema),z.lazy(() => UserWhereInputSchema) ]).optional(),
}).strict());

export const SessionOrderByWithAggregationInputSchema: z.ZodType<Prisma.SessionOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  sessionToken: z.lazy(() => SortOrderSchema).optional(),
  userId: z.lazy(() => SortOrderSchema).optional(),
  expires: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => SessionCountOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => SessionMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => SessionMinOrderByAggregateInputSchema).optional()
}).strict();

export const SessionScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.SessionScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => SessionScalarWhereWithAggregatesInputSchema),z.lazy(() => SessionScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => SessionScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => SessionScalarWhereWithAggregatesInputSchema),z.lazy(() => SessionScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => UuidWithAggregatesFilterSchema),z.string() ]).optional(),
  sessionToken: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  userId: z.union([ z.lazy(() => UuidWithAggregatesFilterSchema),z.string() ]).optional(),
  expires: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
}).strict();

export const VerificationTokenWhereInputSchema: z.ZodType<Prisma.VerificationTokenWhereInput> = z.object({
  AND: z.union([ z.lazy(() => VerificationTokenWhereInputSchema),z.lazy(() => VerificationTokenWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => VerificationTokenWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => VerificationTokenWhereInputSchema),z.lazy(() => VerificationTokenWhereInputSchema).array() ]).optional(),
  identifier: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  token: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  expires: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
}).strict();

export const VerificationTokenOrderByWithRelationInputSchema: z.ZodType<Prisma.VerificationTokenOrderByWithRelationInput> = z.object({
  identifier: z.lazy(() => SortOrderSchema).optional(),
  token: z.lazy(() => SortOrderSchema).optional(),
  expires: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const VerificationTokenWhereUniqueInputSchema: z.ZodType<Prisma.VerificationTokenWhereUniqueInput> = z.union([
  z.object({
    token: z.string(),
    identifier_token: z.lazy(() => VerificationTokenIdentifierTokenCompoundUniqueInputSchema)
  }),
  z.object({
    token: z.string(),
  }),
  z.object({
    identifier_token: z.lazy(() => VerificationTokenIdentifierTokenCompoundUniqueInputSchema),
  }),
])
.and(z.object({
  token: z.string().optional(),
  identifier_token: z.lazy(() => VerificationTokenIdentifierTokenCompoundUniqueInputSchema).optional(),
  AND: z.union([ z.lazy(() => VerificationTokenWhereInputSchema),z.lazy(() => VerificationTokenWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => VerificationTokenWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => VerificationTokenWhereInputSchema),z.lazy(() => VerificationTokenWhereInputSchema).array() ]).optional(),
  identifier: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  expires: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
}).strict());

export const VerificationTokenOrderByWithAggregationInputSchema: z.ZodType<Prisma.VerificationTokenOrderByWithAggregationInput> = z.object({
  identifier: z.lazy(() => SortOrderSchema).optional(),
  token: z.lazy(() => SortOrderSchema).optional(),
  expires: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => VerificationTokenCountOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => VerificationTokenMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => VerificationTokenMinOrderByAggregateInputSchema).optional()
}).strict();

export const VerificationTokenScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.VerificationTokenScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => VerificationTokenScalarWhereWithAggregatesInputSchema),z.lazy(() => VerificationTokenScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => VerificationTokenScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => VerificationTokenScalarWhereWithAggregatesInputSchema),z.lazy(() => VerificationTokenScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  identifier: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  token: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  expires: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
}).strict();

export const UserWhereInputSchema: z.ZodType<Prisma.UserWhereInput> = z.object({
  AND: z.union([ z.lazy(() => UserWhereInputSchema),z.lazy(() => UserWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => UserWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => UserWhereInputSchema),z.lazy(() => UserWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => UuidFilterSchema),z.string() ]).optional(),
  name: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  email: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  lastSeen: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  emailVerified: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  role: z.union([ z.lazy(() => EnumRoleFilterSchema),z.lazy(() => RoleSchema) ]).optional(),
  image: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  accounts: z.lazy(() => AccountListRelationFilterSchema).optional(),
  sessions: z.lazy(() => SessionListRelationFilterSchema).optional(),
  slaps: z.lazy(() => SlapListRelationFilterSchema).optional(),
  worlds: z.lazy(() => WorldListRelationFilterSchema).optional()
}).strict();

export const UserOrderByWithRelationInputSchema: z.ZodType<Prisma.UserOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  name: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  email: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  lastSeen: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  emailVerified: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  role: z.lazy(() => SortOrderSchema).optional(),
  image: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  accounts: z.lazy(() => AccountOrderByRelationAggregateInputSchema).optional(),
  sessions: z.lazy(() => SessionOrderByRelationAggregateInputSchema).optional(),
  slaps: z.lazy(() => SlapOrderByRelationAggregateInputSchema).optional(),
  worlds: z.lazy(() => WorldOrderByRelationAggregateInputSchema).optional()
}).strict();

export const UserWhereUniqueInputSchema: z.ZodType<Prisma.UserWhereUniqueInput> = z.union([
  z.object({
    id: z.string(),
    email: z.string()
  }),
  z.object({
    id: z.string(),
  }),
  z.object({
    email: z.string(),
  }),
])
.and(z.object({
  id: z.string().optional(),
  email: z.string().optional(),
  AND: z.union([ z.lazy(() => UserWhereInputSchema),z.lazy(() => UserWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => UserWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => UserWhereInputSchema),z.lazy(() => UserWhereInputSchema).array() ]).optional(),
  name: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  lastSeen: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  emailVerified: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  role: z.union([ z.lazy(() => EnumRoleFilterSchema),z.lazy(() => RoleSchema) ]).optional(),
  image: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  accounts: z.lazy(() => AccountListRelationFilterSchema).optional(),
  sessions: z.lazy(() => SessionListRelationFilterSchema).optional(),
  slaps: z.lazy(() => SlapListRelationFilterSchema).optional(),
  worlds: z.lazy(() => WorldListRelationFilterSchema).optional()
}).strict());

export const UserOrderByWithAggregationInputSchema: z.ZodType<Prisma.UserOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  name: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  email: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  lastSeen: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  emailVerified: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  role: z.lazy(() => SortOrderSchema).optional(),
  image: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  _count: z.lazy(() => UserCountOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => UserMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => UserMinOrderByAggregateInputSchema).optional()
}).strict();

export const UserScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.UserScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => UserScalarWhereWithAggregatesInputSchema),z.lazy(() => UserScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => UserScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => UserScalarWhereWithAggregatesInputSchema),z.lazy(() => UserScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => UuidWithAggregatesFilterSchema),z.string() ]).optional(),
  name: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  email: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  lastSeen: z.union([ z.lazy(() => DateTimeNullableWithAggregatesFilterSchema),z.coerce.date() ]).optional().nullable(),
  emailVerified: z.union([ z.lazy(() => DateTimeNullableWithAggregatesFilterSchema),z.coerce.date() ]).optional().nullable(),
  role: z.union([ z.lazy(() => EnumRoleWithAggregatesFilterSchema),z.lazy(() => RoleSchema) ]).optional(),
  image: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
}).strict();

export const SampleWhereInputSchema: z.ZodType<Prisma.SampleWhereInput> = z.object({
  AND: z.union([ z.lazy(() => SampleWhereInputSchema),z.lazy(() => SampleWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => SampleWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => SampleWhereInputSchema),z.lazy(() => SampleWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  instrument: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  source: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  genre: z.lazy(() => StringNullableListFilterSchema).optional(),
  artist: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  title: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  original_filename: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  url: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  bpm: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  key: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  duration: z.union([ z.lazy(() => FloatFilterSchema),z.number() ]).optional(),
  downbeat_times: z.lazy(() => FloatNullableListFilterSchema).optional()
}).strict();

export const SampleOrderByWithRelationInputSchema: z.ZodType<Prisma.SampleOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  instrument: z.lazy(() => SortOrderSchema).optional(),
  source: z.lazy(() => SortOrderSchema).optional(),
  genre: z.lazy(() => SortOrderSchema).optional(),
  artist: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  title: z.lazy(() => SortOrderSchema).optional(),
  original_filename: z.lazy(() => SortOrderSchema).optional(),
  url: z.lazy(() => SortOrderSchema).optional(),
  bpm: z.lazy(() => SortOrderSchema).optional(),
  key: z.lazy(() => SortOrderSchema).optional(),
  duration: z.lazy(() => SortOrderSchema).optional(),
  downbeat_times: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const SampleWhereUniqueInputSchema: z.ZodType<Prisma.SampleWhereUniqueInput> = z.object({
  id: z.string()
})
.and(z.object({
  id: z.string().optional(),
  AND: z.union([ z.lazy(() => SampleWhereInputSchema),z.lazy(() => SampleWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => SampleWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => SampleWhereInputSchema),z.lazy(() => SampleWhereInputSchema).array() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  instrument: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  source: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  genre: z.lazy(() => StringNullableListFilterSchema).optional(),
  artist: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  title: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  original_filename: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  url: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  bpm: z.union([ z.lazy(() => IntFilterSchema),z.number().int() ]).optional(),
  key: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  duration: z.union([ z.lazy(() => FloatFilterSchema),z.number() ]).optional(),
  downbeat_times: z.lazy(() => FloatNullableListFilterSchema).optional()
}).strict());

export const SampleOrderByWithAggregationInputSchema: z.ZodType<Prisma.SampleOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  instrument: z.lazy(() => SortOrderSchema).optional(),
  source: z.lazy(() => SortOrderSchema).optional(),
  genre: z.lazy(() => SortOrderSchema).optional(),
  artist: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  title: z.lazy(() => SortOrderSchema).optional(),
  original_filename: z.lazy(() => SortOrderSchema).optional(),
  url: z.lazy(() => SortOrderSchema).optional(),
  bpm: z.lazy(() => SortOrderSchema).optional(),
  key: z.lazy(() => SortOrderSchema).optional(),
  duration: z.lazy(() => SortOrderSchema).optional(),
  downbeat_times: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => SampleCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => SampleAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => SampleMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => SampleMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => SampleSumOrderByAggregateInputSchema).optional()
}).strict();

export const SampleScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.SampleScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => SampleScalarWhereWithAggregatesInputSchema),z.lazy(() => SampleScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => SampleScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => SampleScalarWhereWithAggregatesInputSchema),z.lazy(() => SampleScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  instrument: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  source: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  genre: z.lazy(() => StringNullableListFilterSchema).optional(),
  artist: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  title: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  original_filename: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  url: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  bpm: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  key: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  duration: z.union([ z.lazy(() => FloatWithAggregatesFilterSchema),z.number() ]).optional(),
  downbeat_times: z.lazy(() => FloatNullableListFilterSchema).optional()
}).strict();

export const WorldCreateInputSchema: z.ZodType<Prisma.WorldCreateInput> = z.object({
  id: z.string().optional(),
  title: z.string().optional(),
  video: z.string(),
  image: z.string(),
  description: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  users: z.lazy(() => UserCreateNestedManyWithoutWorldsInputSchema).optional(),
  slaps: z.lazy(() => SlapCreateNestedManyWithoutWorldsInputSchema).optional(),
  tags: z.lazy(() => TagCreateNestedManyWithoutWorldsInputSchema).optional()
}).strict();

export const WorldUncheckedCreateInputSchema: z.ZodType<Prisma.WorldUncheckedCreateInput> = z.object({
  id: z.string().optional(),
  title: z.string().optional(),
  video: z.string(),
  image: z.string(),
  description: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  users: z.lazy(() => UserUncheckedCreateNestedManyWithoutWorldsInputSchema).optional(),
  slaps: z.lazy(() => SlapUncheckedCreateNestedManyWithoutWorldsInputSchema).optional(),
  tags: z.lazy(() => TagUncheckedCreateNestedManyWithoutWorldsInputSchema).optional()
}).strict();

export const WorldUpdateInputSchema: z.ZodType<Prisma.WorldUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  title: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  video: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  image: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  description: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  users: z.lazy(() => UserUpdateManyWithoutWorldsNestedInputSchema).optional(),
  slaps: z.lazy(() => SlapUpdateManyWithoutWorldsNestedInputSchema).optional(),
  tags: z.lazy(() => TagUpdateManyWithoutWorldsNestedInputSchema).optional()
}).strict();

export const WorldUncheckedUpdateInputSchema: z.ZodType<Prisma.WorldUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  title: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  video: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  image: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  description: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  users: z.lazy(() => UserUncheckedUpdateManyWithoutWorldsNestedInputSchema).optional(),
  slaps: z.lazy(() => SlapUncheckedUpdateManyWithoutWorldsNestedInputSchema).optional(),
  tags: z.lazy(() => TagUncheckedUpdateManyWithoutWorldsNestedInputSchema).optional()
}).strict();

export const WorldCreateManyInputSchema: z.ZodType<Prisma.WorldCreateManyInput> = z.object({
  id: z.string().optional(),
  title: z.string().optional(),
  video: z.string(),
  image: z.string(),
  description: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const WorldUpdateManyMutationInputSchema: z.ZodType<Prisma.WorldUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  title: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  video: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  image: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  description: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const WorldUncheckedUpdateManyInputSchema: z.ZodType<Prisma.WorldUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  title: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  video: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  image: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  description: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const TagCreateInputSchema: z.ZodType<Prisma.TagCreateInput> = z.object({
  id: z.string().optional(),
  title: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  slaps: z.lazy(() => SlapCreateNestedManyWithoutTagsInputSchema).optional(),
  worlds: z.lazy(() => WorldCreateNestedManyWithoutTagsInputSchema).optional()
}).strict();

export const TagUncheckedCreateInputSchema: z.ZodType<Prisma.TagUncheckedCreateInput> = z.object({
  id: z.string().optional(),
  title: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  slaps: z.lazy(() => SlapUncheckedCreateNestedManyWithoutTagsInputSchema).optional(),
  worlds: z.lazy(() => WorldUncheckedCreateNestedManyWithoutTagsInputSchema).optional()
}).strict();

export const TagUpdateInputSchema: z.ZodType<Prisma.TagUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  title: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  slaps: z.lazy(() => SlapUpdateManyWithoutTagsNestedInputSchema).optional(),
  worlds: z.lazy(() => WorldUpdateManyWithoutTagsNestedInputSchema).optional()
}).strict();

export const TagUncheckedUpdateInputSchema: z.ZodType<Prisma.TagUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  title: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  slaps: z.lazy(() => SlapUncheckedUpdateManyWithoutTagsNestedInputSchema).optional(),
  worlds: z.lazy(() => WorldUncheckedUpdateManyWithoutTagsNestedInputSchema).optional()
}).strict();

export const TagCreateManyInputSchema: z.ZodType<Prisma.TagCreateManyInput> = z.object({
  id: z.string().optional(),
  title: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const TagUpdateManyMutationInputSchema: z.ZodType<Prisma.TagUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  title: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const TagUncheckedUpdateManyInputSchema: z.ZodType<Prisma.TagUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  title: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const SlapCreateInputSchema: z.ZodType<Prisma.SlapCreateInput> = z.object({
  id: z.string().optional(),
  slug: z.string().optional().nullable(),
  title: z.string(),
  bpm: z.number().int(),
  key: z.string(),
  currentBpm: z.number().int().optional().nullable(),
  currentKey: z.string().optional().nullable(),
  isRoot: z.boolean().optional(),
  isPremade: z.boolean().optional(),
  isDeleted: z.boolean().optional(),
  duration: z.number(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  skin: z.lazy(() => SkinCreateNestedOneWithoutSlapsInputSchema),
  creator: z.lazy(() => UserCreateNestedOneWithoutSlapsInputSchema).optional(),
  worlds: z.lazy(() => WorldCreateNestedManyWithoutSlapsInputSchema).optional(),
  tags: z.lazy(() => TagCreateNestedManyWithoutSlapsInputSchema).optional(),
  tracks: z.lazy(() => TrackCreateNestedManyWithoutSlapInputSchema).optional(),
  parent: z.lazy(() => SlapCreateNestedOneWithoutChildrenInputSchema).optional(),
  root: z.lazy(() => SlapCreateNestedOneWithoutDescendantsInputSchema).optional(),
  children: z.lazy(() => SlapCreateNestedManyWithoutParentInputSchema).optional(),
  descendants: z.lazy(() => SlapCreateNestedManyWithoutRootInputSchema).optional()
}).strict();

export const SlapUncheckedCreateInputSchema: z.ZodType<Prisma.SlapUncheckedCreateInput> = z.object({
  id: z.string().optional(),
  slug: z.string().optional().nullable(),
  title: z.string(),
  bpm: z.number().int(),
  key: z.string(),
  currentBpm: z.number().int().optional().nullable(),
  currentKey: z.string().optional().nullable(),
  isRoot: z.boolean().optional(),
  isPremade: z.boolean().optional(),
  isDeleted: z.boolean().optional(),
  duration: z.number(),
  skinId: z.string(),
  parentId: z.string().optional().nullable(),
  rootId: z.string().optional().nullable(),
  creatorId: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  worlds: z.lazy(() => WorldUncheckedCreateNestedManyWithoutSlapsInputSchema).optional(),
  tags: z.lazy(() => TagUncheckedCreateNestedManyWithoutSlapsInputSchema).optional(),
  tracks: z.lazy(() => TrackUncheckedCreateNestedManyWithoutSlapInputSchema).optional(),
  children: z.lazy(() => SlapUncheckedCreateNestedManyWithoutParentInputSchema).optional(),
  descendants: z.lazy(() => SlapUncheckedCreateNestedManyWithoutRootInputSchema).optional()
}).strict();

export const SlapUpdateInputSchema: z.ZodType<Prisma.SlapUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  slug: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  title: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  bpm: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  key: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  currentBpm: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currentKey: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  isRoot: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  isPremade: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  isDeleted: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  duration: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  skin: z.lazy(() => SkinUpdateOneRequiredWithoutSlapsNestedInputSchema).optional(),
  creator: z.lazy(() => UserUpdateOneWithoutSlapsNestedInputSchema).optional(),
  worlds: z.lazy(() => WorldUpdateManyWithoutSlapsNestedInputSchema).optional(),
  tags: z.lazy(() => TagUpdateManyWithoutSlapsNestedInputSchema).optional(),
  tracks: z.lazy(() => TrackUpdateManyWithoutSlapNestedInputSchema).optional(),
  parent: z.lazy(() => SlapUpdateOneWithoutChildrenNestedInputSchema).optional(),
  root: z.lazy(() => SlapUpdateOneWithoutDescendantsNestedInputSchema).optional(),
  children: z.lazy(() => SlapUpdateManyWithoutParentNestedInputSchema).optional(),
  descendants: z.lazy(() => SlapUpdateManyWithoutRootNestedInputSchema).optional()
}).strict();

export const SlapUncheckedUpdateInputSchema: z.ZodType<Prisma.SlapUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  slug: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  title: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  bpm: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  key: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  currentBpm: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currentKey: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  isRoot: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  isPremade: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  isDeleted: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  duration: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  skinId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  parentId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  rootId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  creatorId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  worlds: z.lazy(() => WorldUncheckedUpdateManyWithoutSlapsNestedInputSchema).optional(),
  tags: z.lazy(() => TagUncheckedUpdateManyWithoutSlapsNestedInputSchema).optional(),
  tracks: z.lazy(() => TrackUncheckedUpdateManyWithoutSlapNestedInputSchema).optional(),
  children: z.lazy(() => SlapUncheckedUpdateManyWithoutParentNestedInputSchema).optional(),
  descendants: z.lazy(() => SlapUncheckedUpdateManyWithoutRootNestedInputSchema).optional()
}).strict();

export const SlapCreateManyInputSchema: z.ZodType<Prisma.SlapCreateManyInput> = z.object({
  id: z.string().optional(),
  slug: z.string().optional().nullable(),
  title: z.string(),
  bpm: z.number().int(),
  key: z.string(),
  currentBpm: z.number().int().optional().nullable(),
  currentKey: z.string().optional().nullable(),
  isRoot: z.boolean().optional(),
  isPremade: z.boolean().optional(),
  isDeleted: z.boolean().optional(),
  duration: z.number(),
  skinId: z.string(),
  parentId: z.string().optional().nullable(),
  rootId: z.string().optional().nullable(),
  creatorId: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const SlapUpdateManyMutationInputSchema: z.ZodType<Prisma.SlapUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  slug: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  title: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  bpm: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  key: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  currentBpm: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currentKey: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  isRoot: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  isPremade: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  isDeleted: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  duration: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const SlapUncheckedUpdateManyInputSchema: z.ZodType<Prisma.SlapUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  slug: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  title: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  bpm: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  key: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  currentBpm: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currentKey: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  isRoot: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  isPremade: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  isDeleted: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  duration: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  skinId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  parentId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  rootId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  creatorId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const SkinCreateInputSchema: z.ZodType<Prisma.SkinCreateInput> = z.object({
  id: z.string().optional(),
  video: z.string(),
  image: z.string(),
  bgColor: z.string(),
  textColor: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  slaps: z.lazy(() => SlapCreateNestedManyWithoutSkinInputSchema).optional()
}).strict();

export const SkinUncheckedCreateInputSchema: z.ZodType<Prisma.SkinUncheckedCreateInput> = z.object({
  id: z.string().optional(),
  video: z.string(),
  image: z.string(),
  bgColor: z.string(),
  textColor: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  slaps: z.lazy(() => SlapUncheckedCreateNestedManyWithoutSkinInputSchema).optional()
}).strict();

export const SkinUpdateInputSchema: z.ZodType<Prisma.SkinUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  video: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  image: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  bgColor: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  textColor: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  slaps: z.lazy(() => SlapUpdateManyWithoutSkinNestedInputSchema).optional()
}).strict();

export const SkinUncheckedUpdateInputSchema: z.ZodType<Prisma.SkinUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  video: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  image: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  bgColor: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  textColor: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  slaps: z.lazy(() => SlapUncheckedUpdateManyWithoutSkinNestedInputSchema).optional()
}).strict();

export const SkinCreateManyInputSchema: z.ZodType<Prisma.SkinCreateManyInput> = z.object({
  id: z.string().optional(),
  video: z.string(),
  image: z.string(),
  bgColor: z.string(),
  textColor: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const SkinUpdateManyMutationInputSchema: z.ZodType<Prisma.SkinUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  video: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  image: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  bgColor: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  textColor: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const SkinUncheckedUpdateManyInputSchema: z.ZodType<Prisma.SkinUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  video: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  image: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  bgColor: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  textColor: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const TrackCreateInputSchema: z.ZodType<Prisma.TrackCreateInput> = z.object({
  id: z.string().optional(),
  src: z.string(),
  title: z.string(),
  data: z.union([ z.lazy(() => TrackCreatedataInputSchema),InputJsonValueSchema.array() ]).optional(),
  isSoloed: z.boolean().optional().nullable(),
  isMuted: z.boolean().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  slap: z.lazy(() => SlapCreateNestedOneWithoutTracksInputSchema)
}).strict();

export const TrackUncheckedCreateInputSchema: z.ZodType<Prisma.TrackUncheckedCreateInput> = z.object({
  id: z.string().optional(),
  src: z.string(),
  title: z.string(),
  data: z.union([ z.lazy(() => TrackCreatedataInputSchema),InputJsonValueSchema.array() ]).optional(),
  isSoloed: z.boolean().optional().nullable(),
  isMuted: z.boolean().optional().nullable(),
  slapId: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const TrackUpdateInputSchema: z.ZodType<Prisma.TrackUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  src: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  title: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  data: z.union([ z.lazy(() => TrackUpdatedataInputSchema),InputJsonValueSchema.array() ]).optional(),
  isSoloed: z.union([ z.boolean(),z.lazy(() => NullableBoolFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  isMuted: z.union([ z.boolean(),z.lazy(() => NullableBoolFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  slap: z.lazy(() => SlapUpdateOneRequiredWithoutTracksNestedInputSchema).optional()
}).strict();

export const TrackUncheckedUpdateInputSchema: z.ZodType<Prisma.TrackUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  src: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  title: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  data: z.union([ z.lazy(() => TrackUpdatedataInputSchema),InputJsonValueSchema.array() ]).optional(),
  isSoloed: z.union([ z.boolean(),z.lazy(() => NullableBoolFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  isMuted: z.union([ z.boolean(),z.lazy(() => NullableBoolFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  slapId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const TrackCreateManyInputSchema: z.ZodType<Prisma.TrackCreateManyInput> = z.object({
  id: z.string().optional(),
  src: z.string(),
  title: z.string(),
  data: z.union([ z.lazy(() => TrackCreatedataInputSchema),InputJsonValueSchema.array() ]).optional(),
  isSoloed: z.boolean().optional().nullable(),
  isMuted: z.boolean().optional().nullable(),
  slapId: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const TrackUpdateManyMutationInputSchema: z.ZodType<Prisma.TrackUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  src: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  title: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  data: z.union([ z.lazy(() => TrackUpdatedataInputSchema),InputJsonValueSchema.array() ]).optional(),
  isSoloed: z.union([ z.boolean(),z.lazy(() => NullableBoolFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  isMuted: z.union([ z.boolean(),z.lazy(() => NullableBoolFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const TrackUncheckedUpdateManyInputSchema: z.ZodType<Prisma.TrackUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  src: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  title: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  data: z.union([ z.lazy(() => TrackUpdatedataInputSchema),InputJsonValueSchema.array() ]).optional(),
  isSoloed: z.union([ z.boolean(),z.lazy(() => NullableBoolFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  isMuted: z.union([ z.boolean(),z.lazy(() => NullableBoolFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  slapId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const AccountCreateInputSchema: z.ZodType<Prisma.AccountCreateInput> = z.object({
  id: z.string().optional(),
  type: z.string(),
  provider: z.string(),
  providerAccountId: z.string(),
  refresh_token: z.string().optional().nullable(),
  access_token: z.string().optional().nullable(),
  expires_at: z.number().int().optional().nullable(),
  token_type: z.string().optional().nullable(),
  scope: z.string().optional().nullable(),
  id_token: z.string().optional().nullable(),
  session_state: z.string().optional().nullable(),
  user: z.lazy(() => UserCreateNestedOneWithoutAccountsInputSchema)
}).strict();

export const AccountUncheckedCreateInputSchema: z.ZodType<Prisma.AccountUncheckedCreateInput> = z.object({
  id: z.string().optional(),
  userId: z.string(),
  type: z.string(),
  provider: z.string(),
  providerAccountId: z.string(),
  refresh_token: z.string().optional().nullable(),
  access_token: z.string().optional().nullable(),
  expires_at: z.number().int().optional().nullable(),
  token_type: z.string().optional().nullable(),
  scope: z.string().optional().nullable(),
  id_token: z.string().optional().nullable(),
  session_state: z.string().optional().nullable()
}).strict();

export const AccountUpdateInputSchema: z.ZodType<Prisma.AccountUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  provider: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  providerAccountId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  refresh_token: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  access_token: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  expires_at: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  token_type: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  scope: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  id_token: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  session_state: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  user: z.lazy(() => UserUpdateOneRequiredWithoutAccountsNestedInputSchema).optional()
}).strict();

export const AccountUncheckedUpdateInputSchema: z.ZodType<Prisma.AccountUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  userId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  provider: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  providerAccountId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  refresh_token: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  access_token: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  expires_at: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  token_type: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  scope: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  id_token: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  session_state: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const AccountCreateManyInputSchema: z.ZodType<Prisma.AccountCreateManyInput> = z.object({
  id: z.string().optional(),
  userId: z.string(),
  type: z.string(),
  provider: z.string(),
  providerAccountId: z.string(),
  refresh_token: z.string().optional().nullable(),
  access_token: z.string().optional().nullable(),
  expires_at: z.number().int().optional().nullable(),
  token_type: z.string().optional().nullable(),
  scope: z.string().optional().nullable(),
  id_token: z.string().optional().nullable(),
  session_state: z.string().optional().nullable()
}).strict();

export const AccountUpdateManyMutationInputSchema: z.ZodType<Prisma.AccountUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  provider: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  providerAccountId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  refresh_token: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  access_token: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  expires_at: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  token_type: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  scope: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  id_token: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  session_state: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const AccountUncheckedUpdateManyInputSchema: z.ZodType<Prisma.AccountUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  userId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  provider: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  providerAccountId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  refresh_token: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  access_token: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  expires_at: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  token_type: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  scope: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  id_token: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  session_state: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const SessionCreateInputSchema: z.ZodType<Prisma.SessionCreateInput> = z.object({
  id: z.string().optional(),
  sessionToken: z.string(),
  expires: z.coerce.date(),
  user: z.lazy(() => UserCreateNestedOneWithoutSessionsInputSchema)
}).strict();

export const SessionUncheckedCreateInputSchema: z.ZodType<Prisma.SessionUncheckedCreateInput> = z.object({
  id: z.string().optional(),
  sessionToken: z.string(),
  userId: z.string(),
  expires: z.coerce.date()
}).strict();

export const SessionUpdateInputSchema: z.ZodType<Prisma.SessionUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  sessionToken: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  expires: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  user: z.lazy(() => UserUpdateOneRequiredWithoutSessionsNestedInputSchema).optional()
}).strict();

export const SessionUncheckedUpdateInputSchema: z.ZodType<Prisma.SessionUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  sessionToken: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  userId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  expires: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const SessionCreateManyInputSchema: z.ZodType<Prisma.SessionCreateManyInput> = z.object({
  id: z.string().optional(),
  sessionToken: z.string(),
  userId: z.string(),
  expires: z.coerce.date()
}).strict();

export const SessionUpdateManyMutationInputSchema: z.ZodType<Prisma.SessionUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  sessionToken: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  expires: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const SessionUncheckedUpdateManyInputSchema: z.ZodType<Prisma.SessionUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  sessionToken: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  userId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  expires: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const VerificationTokenCreateInputSchema: z.ZodType<Prisma.VerificationTokenCreateInput> = z.object({
  identifier: z.string(),
  token: z.string(),
  expires: z.coerce.date()
}).strict();

export const VerificationTokenUncheckedCreateInputSchema: z.ZodType<Prisma.VerificationTokenUncheckedCreateInput> = z.object({
  identifier: z.string(),
  token: z.string(),
  expires: z.coerce.date()
}).strict();

export const VerificationTokenUpdateInputSchema: z.ZodType<Prisma.VerificationTokenUpdateInput> = z.object({
  identifier: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  token: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  expires: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const VerificationTokenUncheckedUpdateInputSchema: z.ZodType<Prisma.VerificationTokenUncheckedUpdateInput> = z.object({
  identifier: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  token: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  expires: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const VerificationTokenCreateManyInputSchema: z.ZodType<Prisma.VerificationTokenCreateManyInput> = z.object({
  identifier: z.string(),
  token: z.string(),
  expires: z.coerce.date()
}).strict();

export const VerificationTokenUpdateManyMutationInputSchema: z.ZodType<Prisma.VerificationTokenUpdateManyMutationInput> = z.object({
  identifier: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  token: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  expires: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const VerificationTokenUncheckedUpdateManyInputSchema: z.ZodType<Prisma.VerificationTokenUncheckedUpdateManyInput> = z.object({
  identifier: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  token: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  expires: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const UserCreateInputSchema: z.ZodType<Prisma.UserCreateInput> = z.object({
  id: z.string().optional(),
  name: z.string().optional().nullable(),
  email: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  lastSeen: z.coerce.date().optional().nullable(),
  emailVerified: z.coerce.date().optional().nullable(),
  role: z.lazy(() => RoleSchema).optional(),
  image: z.string().optional().nullable(),
  accounts: z.lazy(() => AccountCreateNestedManyWithoutUserInputSchema).optional(),
  sessions: z.lazy(() => SessionCreateNestedManyWithoutUserInputSchema).optional(),
  slaps: z.lazy(() => SlapCreateNestedManyWithoutCreatorInputSchema).optional(),
  worlds: z.lazy(() => WorldCreateNestedManyWithoutUsersInputSchema).optional()
}).strict();

export const UserUncheckedCreateInputSchema: z.ZodType<Prisma.UserUncheckedCreateInput> = z.object({
  id: z.string().optional(),
  name: z.string().optional().nullable(),
  email: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  lastSeen: z.coerce.date().optional().nullable(),
  emailVerified: z.coerce.date().optional().nullable(),
  role: z.lazy(() => RoleSchema).optional(),
  image: z.string().optional().nullable(),
  accounts: z.lazy(() => AccountUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  sessions: z.lazy(() => SessionUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  slaps: z.lazy(() => SlapUncheckedCreateNestedManyWithoutCreatorInputSchema).optional(),
  worlds: z.lazy(() => WorldUncheckedCreateNestedManyWithoutUsersInputSchema).optional()
}).strict();

export const UserUpdateInputSchema: z.ZodType<Prisma.UserUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  lastSeen: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerified: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  role: z.union([ z.lazy(() => RoleSchema),z.lazy(() => EnumRoleFieldUpdateOperationsInputSchema) ]).optional(),
  image: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  accounts: z.lazy(() => AccountUpdateManyWithoutUserNestedInputSchema).optional(),
  sessions: z.lazy(() => SessionUpdateManyWithoutUserNestedInputSchema).optional(),
  slaps: z.lazy(() => SlapUpdateManyWithoutCreatorNestedInputSchema).optional(),
  worlds: z.lazy(() => WorldUpdateManyWithoutUsersNestedInputSchema).optional()
}).strict();

export const UserUncheckedUpdateInputSchema: z.ZodType<Prisma.UserUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  lastSeen: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerified: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  role: z.union([ z.lazy(() => RoleSchema),z.lazy(() => EnumRoleFieldUpdateOperationsInputSchema) ]).optional(),
  image: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  accounts: z.lazy(() => AccountUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  sessions: z.lazy(() => SessionUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  slaps: z.lazy(() => SlapUncheckedUpdateManyWithoutCreatorNestedInputSchema).optional(),
  worlds: z.lazy(() => WorldUncheckedUpdateManyWithoutUsersNestedInputSchema).optional()
}).strict();

export const UserCreateManyInputSchema: z.ZodType<Prisma.UserCreateManyInput> = z.object({
  id: z.string().optional(),
  name: z.string().optional().nullable(),
  email: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  lastSeen: z.coerce.date().optional().nullable(),
  emailVerified: z.coerce.date().optional().nullable(),
  role: z.lazy(() => RoleSchema).optional(),
  image: z.string().optional().nullable()
}).strict();

export const UserUpdateManyMutationInputSchema: z.ZodType<Prisma.UserUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  lastSeen: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerified: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  role: z.union([ z.lazy(() => RoleSchema),z.lazy(() => EnumRoleFieldUpdateOperationsInputSchema) ]).optional(),
  image: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const UserUncheckedUpdateManyInputSchema: z.ZodType<Prisma.UserUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  lastSeen: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerified: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  role: z.union([ z.lazy(() => RoleSchema),z.lazy(() => EnumRoleFieldUpdateOperationsInputSchema) ]).optional(),
  image: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const SampleCreateInputSchema: z.ZodType<Prisma.SampleCreateInput> = z.object({
  id: z.string().optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  instrument: z.string(),
  source: z.string(),
  genre: z.union([ z.lazy(() => SampleCreategenreInputSchema),z.string().array() ]).optional(),
  artist: z.string().optional().nullable(),
  title: z.string(),
  original_filename: z.string(),
  url: z.string(),
  bpm: z.number().int(),
  key: z.string(),
  duration: z.number(),
  downbeat_times: z.union([ z.lazy(() => SampleCreatedownbeat_timesInputSchema),z.number().array() ]).optional(),
}).strict();

export const SampleUncheckedCreateInputSchema: z.ZodType<Prisma.SampleUncheckedCreateInput> = z.object({
  id: z.string().optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  instrument: z.string(),
  source: z.string(),
  genre: z.union([ z.lazy(() => SampleCreategenreInputSchema),z.string().array() ]).optional(),
  artist: z.string().optional().nullable(),
  title: z.string(),
  original_filename: z.string(),
  url: z.string(),
  bpm: z.number().int(),
  key: z.string(),
  duration: z.number(),
  downbeat_times: z.union([ z.lazy(() => SampleCreatedownbeat_timesInputSchema),z.number().array() ]).optional(),
}).strict();

export const SampleUpdateInputSchema: z.ZodType<Prisma.SampleUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  instrument: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  source: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  genre: z.union([ z.lazy(() => SampleUpdategenreInputSchema),z.string().array() ]).optional(),
  artist: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  title: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  original_filename: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  url: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  bpm: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  key: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  duration: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  downbeat_times: z.union([ z.lazy(() => SampleUpdatedownbeat_timesInputSchema),z.number().array() ]).optional(),
}).strict();

export const SampleUncheckedUpdateInputSchema: z.ZodType<Prisma.SampleUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  instrument: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  source: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  genre: z.union([ z.lazy(() => SampleUpdategenreInputSchema),z.string().array() ]).optional(),
  artist: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  title: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  original_filename: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  url: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  bpm: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  key: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  duration: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  downbeat_times: z.union([ z.lazy(() => SampleUpdatedownbeat_timesInputSchema),z.number().array() ]).optional(),
}).strict();

export const SampleCreateManyInputSchema: z.ZodType<Prisma.SampleCreateManyInput> = z.object({
  id: z.string().optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  instrument: z.string(),
  source: z.string(),
  genre: z.union([ z.lazy(() => SampleCreategenreInputSchema),z.string().array() ]).optional(),
  artist: z.string().optional().nullable(),
  title: z.string(),
  original_filename: z.string(),
  url: z.string(),
  bpm: z.number().int(),
  key: z.string(),
  duration: z.number(),
  downbeat_times: z.union([ z.lazy(() => SampleCreatedownbeat_timesInputSchema),z.number().array() ]).optional(),
}).strict();

export const SampleUpdateManyMutationInputSchema: z.ZodType<Prisma.SampleUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  instrument: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  source: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  genre: z.union([ z.lazy(() => SampleUpdategenreInputSchema),z.string().array() ]).optional(),
  artist: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  title: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  original_filename: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  url: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  bpm: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  key: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  duration: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  downbeat_times: z.union([ z.lazy(() => SampleUpdatedownbeat_timesInputSchema),z.number().array() ]).optional(),
}).strict();

export const SampleUncheckedUpdateManyInputSchema: z.ZodType<Prisma.SampleUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  instrument: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  source: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  genre: z.union([ z.lazy(() => SampleUpdategenreInputSchema),z.string().array() ]).optional(),
  artist: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  title: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  original_filename: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  url: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  bpm: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  key: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  duration: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  downbeat_times: z.union([ z.lazy(() => SampleUpdatedownbeat_timesInputSchema),z.number().array() ]).optional(),
}).strict();

export const StringFilterSchema: z.ZodType<Prisma.StringFilter> = z.object({
  equals: z.string().optional(),
  in: z.string().array().optional(),
  notIn: z.string().array().optional(),
  lt: z.string().optional(),
  lte: z.string().optional(),
  gt: z.string().optional(),
  gte: z.string().optional(),
  contains: z.string().optional(),
  startsWith: z.string().optional(),
  endsWith: z.string().optional(),
  mode: z.lazy(() => QueryModeSchema).optional(),
  not: z.union([ z.string(),z.lazy(() => NestedStringFilterSchema) ]).optional(),
}).strict();

export const DateTimeFilterSchema: z.ZodType<Prisma.DateTimeFilter> = z.object({
  equals: z.coerce.date().optional(),
  in: z.coerce.date().array().optional(),
  notIn: z.coerce.date().array().optional(),
  lt: z.coerce.date().optional(),
  lte: z.coerce.date().optional(),
  gt: z.coerce.date().optional(),
  gte: z.coerce.date().optional(),
  not: z.union([ z.coerce.date(),z.lazy(() => NestedDateTimeFilterSchema) ]).optional(),
}).strict();

export const UserListRelationFilterSchema: z.ZodType<Prisma.UserListRelationFilter> = z.object({
  every: z.lazy(() => UserWhereInputSchema).optional(),
  some: z.lazy(() => UserWhereInputSchema).optional(),
  none: z.lazy(() => UserWhereInputSchema).optional()
}).strict();

export const SlapListRelationFilterSchema: z.ZodType<Prisma.SlapListRelationFilter> = z.object({
  every: z.lazy(() => SlapWhereInputSchema).optional(),
  some: z.lazy(() => SlapWhereInputSchema).optional(),
  none: z.lazy(() => SlapWhereInputSchema).optional()
}).strict();

export const TagListRelationFilterSchema: z.ZodType<Prisma.TagListRelationFilter> = z.object({
  every: z.lazy(() => TagWhereInputSchema).optional(),
  some: z.lazy(() => TagWhereInputSchema).optional(),
  none: z.lazy(() => TagWhereInputSchema).optional()
}).strict();

export const UserOrderByRelationAggregateInputSchema: z.ZodType<Prisma.UserOrderByRelationAggregateInput> = z.object({
  _count: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const SlapOrderByRelationAggregateInputSchema: z.ZodType<Prisma.SlapOrderByRelationAggregateInput> = z.object({
  _count: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const TagOrderByRelationAggregateInputSchema: z.ZodType<Prisma.TagOrderByRelationAggregateInput> = z.object({
  _count: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const WorldCountOrderByAggregateInputSchema: z.ZodType<Prisma.WorldCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  title: z.lazy(() => SortOrderSchema).optional(),
  video: z.lazy(() => SortOrderSchema).optional(),
  image: z.lazy(() => SortOrderSchema).optional(),
  description: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const WorldMaxOrderByAggregateInputSchema: z.ZodType<Prisma.WorldMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  title: z.lazy(() => SortOrderSchema).optional(),
  video: z.lazy(() => SortOrderSchema).optional(),
  image: z.lazy(() => SortOrderSchema).optional(),
  description: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const WorldMinOrderByAggregateInputSchema: z.ZodType<Prisma.WorldMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  title: z.lazy(() => SortOrderSchema).optional(),
  video: z.lazy(() => SortOrderSchema).optional(),
  image: z.lazy(() => SortOrderSchema).optional(),
  description: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const StringWithAggregatesFilterSchema: z.ZodType<Prisma.StringWithAggregatesFilter> = z.object({
  equals: z.string().optional(),
  in: z.string().array().optional(),
  notIn: z.string().array().optional(),
  lt: z.string().optional(),
  lte: z.string().optional(),
  gt: z.string().optional(),
  gte: z.string().optional(),
  contains: z.string().optional(),
  startsWith: z.string().optional(),
  endsWith: z.string().optional(),
  mode: z.lazy(() => QueryModeSchema).optional(),
  not: z.union([ z.string(),z.lazy(() => NestedStringWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedStringFilterSchema).optional(),
  _max: z.lazy(() => NestedStringFilterSchema).optional()
}).strict();

export const DateTimeWithAggregatesFilterSchema: z.ZodType<Prisma.DateTimeWithAggregatesFilter> = z.object({
  equals: z.coerce.date().optional(),
  in: z.coerce.date().array().optional(),
  notIn: z.coerce.date().array().optional(),
  lt: z.coerce.date().optional(),
  lte: z.coerce.date().optional(),
  gt: z.coerce.date().optional(),
  gte: z.coerce.date().optional(),
  not: z.union([ z.coerce.date(),z.lazy(() => NestedDateTimeWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedDateTimeFilterSchema).optional(),
  _max: z.lazy(() => NestedDateTimeFilterSchema).optional()
}).strict();

export const WorldListRelationFilterSchema: z.ZodType<Prisma.WorldListRelationFilter> = z.object({
  every: z.lazy(() => WorldWhereInputSchema).optional(),
  some: z.lazy(() => WorldWhereInputSchema).optional(),
  none: z.lazy(() => WorldWhereInputSchema).optional()
}).strict();

export const WorldOrderByRelationAggregateInputSchema: z.ZodType<Prisma.WorldOrderByRelationAggregateInput> = z.object({
  _count: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const TagCountOrderByAggregateInputSchema: z.ZodType<Prisma.TagCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  title: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const TagMaxOrderByAggregateInputSchema: z.ZodType<Prisma.TagMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  title: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const TagMinOrderByAggregateInputSchema: z.ZodType<Prisma.TagMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  title: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const StringNullableFilterSchema: z.ZodType<Prisma.StringNullableFilter> = z.object({
  equals: z.string().optional().nullable(),
  in: z.string().array().optional().nullable(),
  notIn: z.string().array().optional().nullable(),
  lt: z.string().optional(),
  lte: z.string().optional(),
  gt: z.string().optional(),
  gte: z.string().optional(),
  contains: z.string().optional(),
  startsWith: z.string().optional(),
  endsWith: z.string().optional(),
  mode: z.lazy(() => QueryModeSchema).optional(),
  not: z.union([ z.string(),z.lazy(() => NestedStringNullableFilterSchema) ]).optional().nullable(),
}).strict();

export const IntFilterSchema: z.ZodType<Prisma.IntFilter> = z.object({
  equals: z.number().optional(),
  in: z.number().array().optional(),
  notIn: z.number().array().optional(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedIntFilterSchema) ]).optional(),
}).strict();

export const IntNullableFilterSchema: z.ZodType<Prisma.IntNullableFilter> = z.object({
  equals: z.number().optional().nullable(),
  in: z.number().array().optional().nullable(),
  notIn: z.number().array().optional().nullable(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedIntNullableFilterSchema) ]).optional().nullable(),
}).strict();

export const BoolFilterSchema: z.ZodType<Prisma.BoolFilter> = z.object({
  equals: z.boolean().optional(),
  not: z.union([ z.boolean(),z.lazy(() => NestedBoolFilterSchema) ]).optional(),
}).strict();

export const FloatFilterSchema: z.ZodType<Prisma.FloatFilter> = z.object({
  equals: z.number().optional(),
  in: z.number().array().optional(),
  notIn: z.number().array().optional(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedFloatFilterSchema) ]).optional(),
}).strict();

export const UuidNullableFilterSchema: z.ZodType<Prisma.UuidNullableFilter> = z.object({
  equals: z.string().optional().nullable(),
  in: z.string().array().optional().nullable(),
  notIn: z.string().array().optional().nullable(),
  lt: z.string().optional(),
  lte: z.string().optional(),
  gt: z.string().optional(),
  gte: z.string().optional(),
  mode: z.lazy(() => QueryModeSchema).optional(),
  not: z.union([ z.string(),z.lazy(() => NestedUuidNullableFilterSchema) ]).optional().nullable(),
}).strict();

export const SkinRelationFilterSchema: z.ZodType<Prisma.SkinRelationFilter> = z.object({
  is: z.lazy(() => SkinWhereInputSchema).optional(),
  isNot: z.lazy(() => SkinWhereInputSchema).optional()
}).strict();

export const UserNullableRelationFilterSchema: z.ZodType<Prisma.UserNullableRelationFilter> = z.object({
  is: z.lazy(() => UserWhereInputSchema).optional().nullable(),
  isNot: z.lazy(() => UserWhereInputSchema).optional().nullable()
}).strict();

export const TrackListRelationFilterSchema: z.ZodType<Prisma.TrackListRelationFilter> = z.object({
  every: z.lazy(() => TrackWhereInputSchema).optional(),
  some: z.lazy(() => TrackWhereInputSchema).optional(),
  none: z.lazy(() => TrackWhereInputSchema).optional()
}).strict();

export const SlapNullableRelationFilterSchema: z.ZodType<Prisma.SlapNullableRelationFilter> = z.object({
  is: z.lazy(() => SlapWhereInputSchema).optional().nullable(),
  isNot: z.lazy(() => SlapWhereInputSchema).optional().nullable()
}).strict();

export const SortOrderInputSchema: z.ZodType<Prisma.SortOrderInput> = z.object({
  sort: z.lazy(() => SortOrderSchema),
  nulls: z.lazy(() => NullsOrderSchema).optional()
}).strict();

export const TrackOrderByRelationAggregateInputSchema: z.ZodType<Prisma.TrackOrderByRelationAggregateInput> = z.object({
  _count: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const SlapCountOrderByAggregateInputSchema: z.ZodType<Prisma.SlapCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  slug: z.lazy(() => SortOrderSchema).optional(),
  title: z.lazy(() => SortOrderSchema).optional(),
  bpm: z.lazy(() => SortOrderSchema).optional(),
  key: z.lazy(() => SortOrderSchema).optional(),
  currentBpm: z.lazy(() => SortOrderSchema).optional(),
  currentKey: z.lazy(() => SortOrderSchema).optional(),
  isRoot: z.lazy(() => SortOrderSchema).optional(),
  isPremade: z.lazy(() => SortOrderSchema).optional(),
  isDeleted: z.lazy(() => SortOrderSchema).optional(),
  duration: z.lazy(() => SortOrderSchema).optional(),
  skinId: z.lazy(() => SortOrderSchema).optional(),
  parentId: z.lazy(() => SortOrderSchema).optional(),
  rootId: z.lazy(() => SortOrderSchema).optional(),
  creatorId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const SlapAvgOrderByAggregateInputSchema: z.ZodType<Prisma.SlapAvgOrderByAggregateInput> = z.object({
  bpm: z.lazy(() => SortOrderSchema).optional(),
  currentBpm: z.lazy(() => SortOrderSchema).optional(),
  duration: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const SlapMaxOrderByAggregateInputSchema: z.ZodType<Prisma.SlapMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  slug: z.lazy(() => SortOrderSchema).optional(),
  title: z.lazy(() => SortOrderSchema).optional(),
  bpm: z.lazy(() => SortOrderSchema).optional(),
  key: z.lazy(() => SortOrderSchema).optional(),
  currentBpm: z.lazy(() => SortOrderSchema).optional(),
  currentKey: z.lazy(() => SortOrderSchema).optional(),
  isRoot: z.lazy(() => SortOrderSchema).optional(),
  isPremade: z.lazy(() => SortOrderSchema).optional(),
  isDeleted: z.lazy(() => SortOrderSchema).optional(),
  duration: z.lazy(() => SortOrderSchema).optional(),
  skinId: z.lazy(() => SortOrderSchema).optional(),
  parentId: z.lazy(() => SortOrderSchema).optional(),
  rootId: z.lazy(() => SortOrderSchema).optional(),
  creatorId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const SlapMinOrderByAggregateInputSchema: z.ZodType<Prisma.SlapMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  slug: z.lazy(() => SortOrderSchema).optional(),
  title: z.lazy(() => SortOrderSchema).optional(),
  bpm: z.lazy(() => SortOrderSchema).optional(),
  key: z.lazy(() => SortOrderSchema).optional(),
  currentBpm: z.lazy(() => SortOrderSchema).optional(),
  currentKey: z.lazy(() => SortOrderSchema).optional(),
  isRoot: z.lazy(() => SortOrderSchema).optional(),
  isPremade: z.lazy(() => SortOrderSchema).optional(),
  isDeleted: z.lazy(() => SortOrderSchema).optional(),
  duration: z.lazy(() => SortOrderSchema).optional(),
  skinId: z.lazy(() => SortOrderSchema).optional(),
  parentId: z.lazy(() => SortOrderSchema).optional(),
  rootId: z.lazy(() => SortOrderSchema).optional(),
  creatorId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const SlapSumOrderByAggregateInputSchema: z.ZodType<Prisma.SlapSumOrderByAggregateInput> = z.object({
  bpm: z.lazy(() => SortOrderSchema).optional(),
  currentBpm: z.lazy(() => SortOrderSchema).optional(),
  duration: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const StringNullableWithAggregatesFilterSchema: z.ZodType<Prisma.StringNullableWithAggregatesFilter> = z.object({
  equals: z.string().optional().nullable(),
  in: z.string().array().optional().nullable(),
  notIn: z.string().array().optional().nullable(),
  lt: z.string().optional(),
  lte: z.string().optional(),
  gt: z.string().optional(),
  gte: z.string().optional(),
  contains: z.string().optional(),
  startsWith: z.string().optional(),
  endsWith: z.string().optional(),
  mode: z.lazy(() => QueryModeSchema).optional(),
  not: z.union([ z.string(),z.lazy(() => NestedStringNullableWithAggregatesFilterSchema) ]).optional().nullable(),
  _count: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _min: z.lazy(() => NestedStringNullableFilterSchema).optional(),
  _max: z.lazy(() => NestedStringNullableFilterSchema).optional()
}).strict();

export const IntWithAggregatesFilterSchema: z.ZodType<Prisma.IntWithAggregatesFilter> = z.object({
  equals: z.number().optional(),
  in: z.number().array().optional(),
  notIn: z.number().array().optional(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedIntWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _avg: z.lazy(() => NestedFloatFilterSchema).optional(),
  _sum: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedIntFilterSchema).optional(),
  _max: z.lazy(() => NestedIntFilterSchema).optional()
}).strict();

export const IntNullableWithAggregatesFilterSchema: z.ZodType<Prisma.IntNullableWithAggregatesFilter> = z.object({
  equals: z.number().optional().nullable(),
  in: z.number().array().optional().nullable(),
  notIn: z.number().array().optional().nullable(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedIntNullableWithAggregatesFilterSchema) ]).optional().nullable(),
  _count: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _avg: z.lazy(() => NestedFloatNullableFilterSchema).optional(),
  _sum: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _min: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _max: z.lazy(() => NestedIntNullableFilterSchema).optional()
}).strict();

export const BoolWithAggregatesFilterSchema: z.ZodType<Prisma.BoolWithAggregatesFilter> = z.object({
  equals: z.boolean().optional(),
  not: z.union([ z.boolean(),z.lazy(() => NestedBoolWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedBoolFilterSchema).optional(),
  _max: z.lazy(() => NestedBoolFilterSchema).optional()
}).strict();

export const FloatWithAggregatesFilterSchema: z.ZodType<Prisma.FloatWithAggregatesFilter> = z.object({
  equals: z.number().optional(),
  in: z.number().array().optional(),
  notIn: z.number().array().optional(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedFloatWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _avg: z.lazy(() => NestedFloatFilterSchema).optional(),
  _sum: z.lazy(() => NestedFloatFilterSchema).optional(),
  _min: z.lazy(() => NestedFloatFilterSchema).optional(),
  _max: z.lazy(() => NestedFloatFilterSchema).optional()
}).strict();

export const UuidNullableWithAggregatesFilterSchema: z.ZodType<Prisma.UuidNullableWithAggregatesFilter> = z.object({
  equals: z.string().optional().nullable(),
  in: z.string().array().optional().nullable(),
  notIn: z.string().array().optional().nullable(),
  lt: z.string().optional(),
  lte: z.string().optional(),
  gt: z.string().optional(),
  gte: z.string().optional(),
  mode: z.lazy(() => QueryModeSchema).optional(),
  not: z.union([ z.string(),z.lazy(() => NestedUuidNullableWithAggregatesFilterSchema) ]).optional().nullable(),
  _count: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _min: z.lazy(() => NestedStringNullableFilterSchema).optional(),
  _max: z.lazy(() => NestedStringNullableFilterSchema).optional()
}).strict();

export const SkinCountOrderByAggregateInputSchema: z.ZodType<Prisma.SkinCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  video: z.lazy(() => SortOrderSchema).optional(),
  image: z.lazy(() => SortOrderSchema).optional(),
  bgColor: z.lazy(() => SortOrderSchema).optional(),
  textColor: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const SkinMaxOrderByAggregateInputSchema: z.ZodType<Prisma.SkinMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  video: z.lazy(() => SortOrderSchema).optional(),
  image: z.lazy(() => SortOrderSchema).optional(),
  bgColor: z.lazy(() => SortOrderSchema).optional(),
  textColor: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const SkinMinOrderByAggregateInputSchema: z.ZodType<Prisma.SkinMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  video: z.lazy(() => SortOrderSchema).optional(),
  image: z.lazy(() => SortOrderSchema).optional(),
  bgColor: z.lazy(() => SortOrderSchema).optional(),
  textColor: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const JsonNullableListFilterSchema: z.ZodType<Prisma.JsonNullableListFilter> = z.object({
  equals: InputJsonValueSchema.array().optional().nullable(),
  has: InputJsonValueSchema.optional().nullable(),
  hasEvery: InputJsonValueSchema.array().optional(),
  hasSome: InputJsonValueSchema.array().optional(),
  isEmpty: z.boolean().optional()
}).strict();

export const BoolNullableFilterSchema: z.ZodType<Prisma.BoolNullableFilter> = z.object({
  equals: z.boolean().optional().nullable(),
  not: z.union([ z.boolean(),z.lazy(() => NestedBoolNullableFilterSchema) ]).optional().nullable(),
}).strict();

export const SlapRelationFilterSchema: z.ZodType<Prisma.SlapRelationFilter> = z.object({
  is: z.lazy(() => SlapWhereInputSchema).optional(),
  isNot: z.lazy(() => SlapWhereInputSchema).optional()
}).strict();

export const TrackCountOrderByAggregateInputSchema: z.ZodType<Prisma.TrackCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  src: z.lazy(() => SortOrderSchema).optional(),
  title: z.lazy(() => SortOrderSchema).optional(),
  data: z.lazy(() => SortOrderSchema).optional(),
  isSoloed: z.lazy(() => SortOrderSchema).optional(),
  isMuted: z.lazy(() => SortOrderSchema).optional(),
  slapId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const TrackMaxOrderByAggregateInputSchema: z.ZodType<Prisma.TrackMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  src: z.lazy(() => SortOrderSchema).optional(),
  title: z.lazy(() => SortOrderSchema).optional(),
  isSoloed: z.lazy(() => SortOrderSchema).optional(),
  isMuted: z.lazy(() => SortOrderSchema).optional(),
  slapId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const TrackMinOrderByAggregateInputSchema: z.ZodType<Prisma.TrackMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  src: z.lazy(() => SortOrderSchema).optional(),
  title: z.lazy(() => SortOrderSchema).optional(),
  isSoloed: z.lazy(() => SortOrderSchema).optional(),
  isMuted: z.lazy(() => SortOrderSchema).optional(),
  slapId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const BoolNullableWithAggregatesFilterSchema: z.ZodType<Prisma.BoolNullableWithAggregatesFilter> = z.object({
  equals: z.boolean().optional().nullable(),
  not: z.union([ z.boolean(),z.lazy(() => NestedBoolNullableWithAggregatesFilterSchema) ]).optional().nullable(),
  _count: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _min: z.lazy(() => NestedBoolNullableFilterSchema).optional(),
  _max: z.lazy(() => NestedBoolNullableFilterSchema).optional()
}).strict();

export const UuidFilterSchema: z.ZodType<Prisma.UuidFilter> = z.object({
  equals: z.string().optional(),
  in: z.string().array().optional(),
  notIn: z.string().array().optional(),
  lt: z.string().optional(),
  lte: z.string().optional(),
  gt: z.string().optional(),
  gte: z.string().optional(),
  mode: z.lazy(() => QueryModeSchema).optional(),
  not: z.union([ z.string(),z.lazy(() => NestedUuidFilterSchema) ]).optional(),
}).strict();

export const UserRelationFilterSchema: z.ZodType<Prisma.UserRelationFilter> = z.object({
  is: z.lazy(() => UserWhereInputSchema).optional(),
  isNot: z.lazy(() => UserWhereInputSchema).optional()
}).strict();

export const AccountProviderProviderAccountIdCompoundUniqueInputSchema: z.ZodType<Prisma.AccountProviderProviderAccountIdCompoundUniqueInput> = z.object({
  provider: z.string(),
  providerAccountId: z.string()
}).strict();

export const AccountCountOrderByAggregateInputSchema: z.ZodType<Prisma.AccountCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  userId: z.lazy(() => SortOrderSchema).optional(),
  type: z.lazy(() => SortOrderSchema).optional(),
  provider: z.lazy(() => SortOrderSchema).optional(),
  providerAccountId: z.lazy(() => SortOrderSchema).optional(),
  refresh_token: z.lazy(() => SortOrderSchema).optional(),
  access_token: z.lazy(() => SortOrderSchema).optional(),
  expires_at: z.lazy(() => SortOrderSchema).optional(),
  token_type: z.lazy(() => SortOrderSchema).optional(),
  scope: z.lazy(() => SortOrderSchema).optional(),
  id_token: z.lazy(() => SortOrderSchema).optional(),
  session_state: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const AccountAvgOrderByAggregateInputSchema: z.ZodType<Prisma.AccountAvgOrderByAggregateInput> = z.object({
  expires_at: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const AccountMaxOrderByAggregateInputSchema: z.ZodType<Prisma.AccountMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  userId: z.lazy(() => SortOrderSchema).optional(),
  type: z.lazy(() => SortOrderSchema).optional(),
  provider: z.lazy(() => SortOrderSchema).optional(),
  providerAccountId: z.lazy(() => SortOrderSchema).optional(),
  refresh_token: z.lazy(() => SortOrderSchema).optional(),
  access_token: z.lazy(() => SortOrderSchema).optional(),
  expires_at: z.lazy(() => SortOrderSchema).optional(),
  token_type: z.lazy(() => SortOrderSchema).optional(),
  scope: z.lazy(() => SortOrderSchema).optional(),
  id_token: z.lazy(() => SortOrderSchema).optional(),
  session_state: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const AccountMinOrderByAggregateInputSchema: z.ZodType<Prisma.AccountMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  userId: z.lazy(() => SortOrderSchema).optional(),
  type: z.lazy(() => SortOrderSchema).optional(),
  provider: z.lazy(() => SortOrderSchema).optional(),
  providerAccountId: z.lazy(() => SortOrderSchema).optional(),
  refresh_token: z.lazy(() => SortOrderSchema).optional(),
  access_token: z.lazy(() => SortOrderSchema).optional(),
  expires_at: z.lazy(() => SortOrderSchema).optional(),
  token_type: z.lazy(() => SortOrderSchema).optional(),
  scope: z.lazy(() => SortOrderSchema).optional(),
  id_token: z.lazy(() => SortOrderSchema).optional(),
  session_state: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const AccountSumOrderByAggregateInputSchema: z.ZodType<Prisma.AccountSumOrderByAggregateInput> = z.object({
  expires_at: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const UuidWithAggregatesFilterSchema: z.ZodType<Prisma.UuidWithAggregatesFilter> = z.object({
  equals: z.string().optional(),
  in: z.string().array().optional(),
  notIn: z.string().array().optional(),
  lt: z.string().optional(),
  lte: z.string().optional(),
  gt: z.string().optional(),
  gte: z.string().optional(),
  mode: z.lazy(() => QueryModeSchema).optional(),
  not: z.union([ z.string(),z.lazy(() => NestedUuidWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedStringFilterSchema).optional(),
  _max: z.lazy(() => NestedStringFilterSchema).optional()
}).strict();

export const SessionCountOrderByAggregateInputSchema: z.ZodType<Prisma.SessionCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  sessionToken: z.lazy(() => SortOrderSchema).optional(),
  userId: z.lazy(() => SortOrderSchema).optional(),
  expires: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const SessionMaxOrderByAggregateInputSchema: z.ZodType<Prisma.SessionMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  sessionToken: z.lazy(() => SortOrderSchema).optional(),
  userId: z.lazy(() => SortOrderSchema).optional(),
  expires: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const SessionMinOrderByAggregateInputSchema: z.ZodType<Prisma.SessionMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  sessionToken: z.lazy(() => SortOrderSchema).optional(),
  userId: z.lazy(() => SortOrderSchema).optional(),
  expires: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const VerificationTokenIdentifierTokenCompoundUniqueInputSchema: z.ZodType<Prisma.VerificationTokenIdentifierTokenCompoundUniqueInput> = z.object({
  identifier: z.string(),
  token: z.string()
}).strict();

export const VerificationTokenCountOrderByAggregateInputSchema: z.ZodType<Prisma.VerificationTokenCountOrderByAggregateInput> = z.object({
  identifier: z.lazy(() => SortOrderSchema).optional(),
  token: z.lazy(() => SortOrderSchema).optional(),
  expires: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const VerificationTokenMaxOrderByAggregateInputSchema: z.ZodType<Prisma.VerificationTokenMaxOrderByAggregateInput> = z.object({
  identifier: z.lazy(() => SortOrderSchema).optional(),
  token: z.lazy(() => SortOrderSchema).optional(),
  expires: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const VerificationTokenMinOrderByAggregateInputSchema: z.ZodType<Prisma.VerificationTokenMinOrderByAggregateInput> = z.object({
  identifier: z.lazy(() => SortOrderSchema).optional(),
  token: z.lazy(() => SortOrderSchema).optional(),
  expires: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const DateTimeNullableFilterSchema: z.ZodType<Prisma.DateTimeNullableFilter> = z.object({
  equals: z.coerce.date().optional().nullable(),
  in: z.coerce.date().array().optional().nullable(),
  notIn: z.coerce.date().array().optional().nullable(),
  lt: z.coerce.date().optional(),
  lte: z.coerce.date().optional(),
  gt: z.coerce.date().optional(),
  gte: z.coerce.date().optional(),
  not: z.union([ z.coerce.date(),z.lazy(() => NestedDateTimeNullableFilterSchema) ]).optional().nullable(),
}).strict();

export const EnumRoleFilterSchema: z.ZodType<Prisma.EnumRoleFilter> = z.object({
  equals: z.lazy(() => RoleSchema).optional(),
  in: z.lazy(() => RoleSchema).array().optional(),
  notIn: z.lazy(() => RoleSchema).array().optional(),
  not: z.union([ z.lazy(() => RoleSchema),z.lazy(() => NestedEnumRoleFilterSchema) ]).optional(),
}).strict();

export const AccountListRelationFilterSchema: z.ZodType<Prisma.AccountListRelationFilter> = z.object({
  every: z.lazy(() => AccountWhereInputSchema).optional(),
  some: z.lazy(() => AccountWhereInputSchema).optional(),
  none: z.lazy(() => AccountWhereInputSchema).optional()
}).strict();

export const SessionListRelationFilterSchema: z.ZodType<Prisma.SessionListRelationFilter> = z.object({
  every: z.lazy(() => SessionWhereInputSchema).optional(),
  some: z.lazy(() => SessionWhereInputSchema).optional(),
  none: z.lazy(() => SessionWhereInputSchema).optional()
}).strict();

export const AccountOrderByRelationAggregateInputSchema: z.ZodType<Prisma.AccountOrderByRelationAggregateInput> = z.object({
  _count: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const SessionOrderByRelationAggregateInputSchema: z.ZodType<Prisma.SessionOrderByRelationAggregateInput> = z.object({
  _count: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const UserCountOrderByAggregateInputSchema: z.ZodType<Prisma.UserCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  email: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  lastSeen: z.lazy(() => SortOrderSchema).optional(),
  emailVerified: z.lazy(() => SortOrderSchema).optional(),
  role: z.lazy(() => SortOrderSchema).optional(),
  image: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const UserMaxOrderByAggregateInputSchema: z.ZodType<Prisma.UserMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  email: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  lastSeen: z.lazy(() => SortOrderSchema).optional(),
  emailVerified: z.lazy(() => SortOrderSchema).optional(),
  role: z.lazy(() => SortOrderSchema).optional(),
  image: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const UserMinOrderByAggregateInputSchema: z.ZodType<Prisma.UserMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  email: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  lastSeen: z.lazy(() => SortOrderSchema).optional(),
  emailVerified: z.lazy(() => SortOrderSchema).optional(),
  role: z.lazy(() => SortOrderSchema).optional(),
  image: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const DateTimeNullableWithAggregatesFilterSchema: z.ZodType<Prisma.DateTimeNullableWithAggregatesFilter> = z.object({
  equals: z.coerce.date().optional().nullable(),
  in: z.coerce.date().array().optional().nullable(),
  notIn: z.coerce.date().array().optional().nullable(),
  lt: z.coerce.date().optional(),
  lte: z.coerce.date().optional(),
  gt: z.coerce.date().optional(),
  gte: z.coerce.date().optional(),
  not: z.union([ z.coerce.date(),z.lazy(() => NestedDateTimeNullableWithAggregatesFilterSchema) ]).optional().nullable(),
  _count: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _min: z.lazy(() => NestedDateTimeNullableFilterSchema).optional(),
  _max: z.lazy(() => NestedDateTimeNullableFilterSchema).optional()
}).strict();

export const EnumRoleWithAggregatesFilterSchema: z.ZodType<Prisma.EnumRoleWithAggregatesFilter> = z.object({
  equals: z.lazy(() => RoleSchema).optional(),
  in: z.lazy(() => RoleSchema).array().optional(),
  notIn: z.lazy(() => RoleSchema).array().optional(),
  not: z.union([ z.lazy(() => RoleSchema),z.lazy(() => NestedEnumRoleWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumRoleFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumRoleFilterSchema).optional()
}).strict();

export const StringNullableListFilterSchema: z.ZodType<Prisma.StringNullableListFilter> = z.object({
  equals: z.string().array().optional().nullable(),
  has: z.string().optional().nullable(),
  hasEvery: z.string().array().optional(),
  hasSome: z.string().array().optional(),
  isEmpty: z.boolean().optional()
}).strict();

export const FloatNullableListFilterSchema: z.ZodType<Prisma.FloatNullableListFilter> = z.object({
  equals: z.number().array().optional().nullable(),
  has: z.number().optional().nullable(),
  hasEvery: z.number().array().optional(),
  hasSome: z.number().array().optional(),
  isEmpty: z.boolean().optional()
}).strict();

export const SampleCountOrderByAggregateInputSchema: z.ZodType<Prisma.SampleCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  instrument: z.lazy(() => SortOrderSchema).optional(),
  source: z.lazy(() => SortOrderSchema).optional(),
  genre: z.lazy(() => SortOrderSchema).optional(),
  artist: z.lazy(() => SortOrderSchema).optional(),
  title: z.lazy(() => SortOrderSchema).optional(),
  original_filename: z.lazy(() => SortOrderSchema).optional(),
  url: z.lazy(() => SortOrderSchema).optional(),
  bpm: z.lazy(() => SortOrderSchema).optional(),
  key: z.lazy(() => SortOrderSchema).optional(),
  duration: z.lazy(() => SortOrderSchema).optional(),
  downbeat_times: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const SampleAvgOrderByAggregateInputSchema: z.ZodType<Prisma.SampleAvgOrderByAggregateInput> = z.object({
  bpm: z.lazy(() => SortOrderSchema).optional(),
  duration: z.lazy(() => SortOrderSchema).optional(),
  downbeat_times: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const SampleMaxOrderByAggregateInputSchema: z.ZodType<Prisma.SampleMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  instrument: z.lazy(() => SortOrderSchema).optional(),
  source: z.lazy(() => SortOrderSchema).optional(),
  artist: z.lazy(() => SortOrderSchema).optional(),
  title: z.lazy(() => SortOrderSchema).optional(),
  original_filename: z.lazy(() => SortOrderSchema).optional(),
  url: z.lazy(() => SortOrderSchema).optional(),
  bpm: z.lazy(() => SortOrderSchema).optional(),
  key: z.lazy(() => SortOrderSchema).optional(),
  duration: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const SampleMinOrderByAggregateInputSchema: z.ZodType<Prisma.SampleMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  instrument: z.lazy(() => SortOrderSchema).optional(),
  source: z.lazy(() => SortOrderSchema).optional(),
  artist: z.lazy(() => SortOrderSchema).optional(),
  title: z.lazy(() => SortOrderSchema).optional(),
  original_filename: z.lazy(() => SortOrderSchema).optional(),
  url: z.lazy(() => SortOrderSchema).optional(),
  bpm: z.lazy(() => SortOrderSchema).optional(),
  key: z.lazy(() => SortOrderSchema).optional(),
  duration: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const SampleSumOrderByAggregateInputSchema: z.ZodType<Prisma.SampleSumOrderByAggregateInput> = z.object({
  bpm: z.lazy(() => SortOrderSchema).optional(),
  duration: z.lazy(() => SortOrderSchema).optional(),
  downbeat_times: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const UserCreateNestedManyWithoutWorldsInputSchema: z.ZodType<Prisma.UserCreateNestedManyWithoutWorldsInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutWorldsInputSchema),z.lazy(() => UserCreateWithoutWorldsInputSchema).array(),z.lazy(() => UserUncheckedCreateWithoutWorldsInputSchema),z.lazy(() => UserUncheckedCreateWithoutWorldsInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => UserCreateOrConnectWithoutWorldsInputSchema),z.lazy(() => UserCreateOrConnectWithoutWorldsInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => UserWhereUniqueInputSchema),z.lazy(() => UserWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const SlapCreateNestedManyWithoutWorldsInputSchema: z.ZodType<Prisma.SlapCreateNestedManyWithoutWorldsInput> = z.object({
  create: z.union([ z.lazy(() => SlapCreateWithoutWorldsInputSchema),z.lazy(() => SlapCreateWithoutWorldsInputSchema).array(),z.lazy(() => SlapUncheckedCreateWithoutWorldsInputSchema),z.lazy(() => SlapUncheckedCreateWithoutWorldsInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => SlapCreateOrConnectWithoutWorldsInputSchema),z.lazy(() => SlapCreateOrConnectWithoutWorldsInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => SlapWhereUniqueInputSchema),z.lazy(() => SlapWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const TagCreateNestedManyWithoutWorldsInputSchema: z.ZodType<Prisma.TagCreateNestedManyWithoutWorldsInput> = z.object({
  create: z.union([ z.lazy(() => TagCreateWithoutWorldsInputSchema),z.lazy(() => TagCreateWithoutWorldsInputSchema).array(),z.lazy(() => TagUncheckedCreateWithoutWorldsInputSchema),z.lazy(() => TagUncheckedCreateWithoutWorldsInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => TagCreateOrConnectWithoutWorldsInputSchema),z.lazy(() => TagCreateOrConnectWithoutWorldsInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => TagWhereUniqueInputSchema),z.lazy(() => TagWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const UserUncheckedCreateNestedManyWithoutWorldsInputSchema: z.ZodType<Prisma.UserUncheckedCreateNestedManyWithoutWorldsInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutWorldsInputSchema),z.lazy(() => UserCreateWithoutWorldsInputSchema).array(),z.lazy(() => UserUncheckedCreateWithoutWorldsInputSchema),z.lazy(() => UserUncheckedCreateWithoutWorldsInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => UserCreateOrConnectWithoutWorldsInputSchema),z.lazy(() => UserCreateOrConnectWithoutWorldsInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => UserWhereUniqueInputSchema),z.lazy(() => UserWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const SlapUncheckedCreateNestedManyWithoutWorldsInputSchema: z.ZodType<Prisma.SlapUncheckedCreateNestedManyWithoutWorldsInput> = z.object({
  create: z.union([ z.lazy(() => SlapCreateWithoutWorldsInputSchema),z.lazy(() => SlapCreateWithoutWorldsInputSchema).array(),z.lazy(() => SlapUncheckedCreateWithoutWorldsInputSchema),z.lazy(() => SlapUncheckedCreateWithoutWorldsInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => SlapCreateOrConnectWithoutWorldsInputSchema),z.lazy(() => SlapCreateOrConnectWithoutWorldsInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => SlapWhereUniqueInputSchema),z.lazy(() => SlapWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const TagUncheckedCreateNestedManyWithoutWorldsInputSchema: z.ZodType<Prisma.TagUncheckedCreateNestedManyWithoutWorldsInput> = z.object({
  create: z.union([ z.lazy(() => TagCreateWithoutWorldsInputSchema),z.lazy(() => TagCreateWithoutWorldsInputSchema).array(),z.lazy(() => TagUncheckedCreateWithoutWorldsInputSchema),z.lazy(() => TagUncheckedCreateWithoutWorldsInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => TagCreateOrConnectWithoutWorldsInputSchema),z.lazy(() => TagCreateOrConnectWithoutWorldsInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => TagWhereUniqueInputSchema),z.lazy(() => TagWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const StringFieldUpdateOperationsInputSchema: z.ZodType<Prisma.StringFieldUpdateOperationsInput> = z.object({
  set: z.string().optional()
}).strict();

export const DateTimeFieldUpdateOperationsInputSchema: z.ZodType<Prisma.DateTimeFieldUpdateOperationsInput> = z.object({
  set: z.coerce.date().optional()
}).strict();

export const UserUpdateManyWithoutWorldsNestedInputSchema: z.ZodType<Prisma.UserUpdateManyWithoutWorldsNestedInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutWorldsInputSchema),z.lazy(() => UserCreateWithoutWorldsInputSchema).array(),z.lazy(() => UserUncheckedCreateWithoutWorldsInputSchema),z.lazy(() => UserUncheckedCreateWithoutWorldsInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => UserCreateOrConnectWithoutWorldsInputSchema),z.lazy(() => UserCreateOrConnectWithoutWorldsInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => UserUpsertWithWhereUniqueWithoutWorldsInputSchema),z.lazy(() => UserUpsertWithWhereUniqueWithoutWorldsInputSchema).array() ]).optional(),
  set: z.union([ z.lazy(() => UserWhereUniqueInputSchema),z.lazy(() => UserWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => UserWhereUniqueInputSchema),z.lazy(() => UserWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => UserWhereUniqueInputSchema),z.lazy(() => UserWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => UserWhereUniqueInputSchema),z.lazy(() => UserWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => UserUpdateWithWhereUniqueWithoutWorldsInputSchema),z.lazy(() => UserUpdateWithWhereUniqueWithoutWorldsInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => UserUpdateManyWithWhereWithoutWorldsInputSchema),z.lazy(() => UserUpdateManyWithWhereWithoutWorldsInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => UserScalarWhereInputSchema),z.lazy(() => UserScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const SlapUpdateManyWithoutWorldsNestedInputSchema: z.ZodType<Prisma.SlapUpdateManyWithoutWorldsNestedInput> = z.object({
  create: z.union([ z.lazy(() => SlapCreateWithoutWorldsInputSchema),z.lazy(() => SlapCreateWithoutWorldsInputSchema).array(),z.lazy(() => SlapUncheckedCreateWithoutWorldsInputSchema),z.lazy(() => SlapUncheckedCreateWithoutWorldsInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => SlapCreateOrConnectWithoutWorldsInputSchema),z.lazy(() => SlapCreateOrConnectWithoutWorldsInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => SlapUpsertWithWhereUniqueWithoutWorldsInputSchema),z.lazy(() => SlapUpsertWithWhereUniqueWithoutWorldsInputSchema).array() ]).optional(),
  set: z.union([ z.lazy(() => SlapWhereUniqueInputSchema),z.lazy(() => SlapWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => SlapWhereUniqueInputSchema),z.lazy(() => SlapWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => SlapWhereUniqueInputSchema),z.lazy(() => SlapWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => SlapWhereUniqueInputSchema),z.lazy(() => SlapWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => SlapUpdateWithWhereUniqueWithoutWorldsInputSchema),z.lazy(() => SlapUpdateWithWhereUniqueWithoutWorldsInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => SlapUpdateManyWithWhereWithoutWorldsInputSchema),z.lazy(() => SlapUpdateManyWithWhereWithoutWorldsInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => SlapScalarWhereInputSchema),z.lazy(() => SlapScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const TagUpdateManyWithoutWorldsNestedInputSchema: z.ZodType<Prisma.TagUpdateManyWithoutWorldsNestedInput> = z.object({
  create: z.union([ z.lazy(() => TagCreateWithoutWorldsInputSchema),z.lazy(() => TagCreateWithoutWorldsInputSchema).array(),z.lazy(() => TagUncheckedCreateWithoutWorldsInputSchema),z.lazy(() => TagUncheckedCreateWithoutWorldsInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => TagCreateOrConnectWithoutWorldsInputSchema),z.lazy(() => TagCreateOrConnectWithoutWorldsInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => TagUpsertWithWhereUniqueWithoutWorldsInputSchema),z.lazy(() => TagUpsertWithWhereUniqueWithoutWorldsInputSchema).array() ]).optional(),
  set: z.union([ z.lazy(() => TagWhereUniqueInputSchema),z.lazy(() => TagWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => TagWhereUniqueInputSchema),z.lazy(() => TagWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => TagWhereUniqueInputSchema),z.lazy(() => TagWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => TagWhereUniqueInputSchema),z.lazy(() => TagWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => TagUpdateWithWhereUniqueWithoutWorldsInputSchema),z.lazy(() => TagUpdateWithWhereUniqueWithoutWorldsInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => TagUpdateManyWithWhereWithoutWorldsInputSchema),z.lazy(() => TagUpdateManyWithWhereWithoutWorldsInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => TagScalarWhereInputSchema),z.lazy(() => TagScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const UserUncheckedUpdateManyWithoutWorldsNestedInputSchema: z.ZodType<Prisma.UserUncheckedUpdateManyWithoutWorldsNestedInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutWorldsInputSchema),z.lazy(() => UserCreateWithoutWorldsInputSchema).array(),z.lazy(() => UserUncheckedCreateWithoutWorldsInputSchema),z.lazy(() => UserUncheckedCreateWithoutWorldsInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => UserCreateOrConnectWithoutWorldsInputSchema),z.lazy(() => UserCreateOrConnectWithoutWorldsInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => UserUpsertWithWhereUniqueWithoutWorldsInputSchema),z.lazy(() => UserUpsertWithWhereUniqueWithoutWorldsInputSchema).array() ]).optional(),
  set: z.union([ z.lazy(() => UserWhereUniqueInputSchema),z.lazy(() => UserWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => UserWhereUniqueInputSchema),z.lazy(() => UserWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => UserWhereUniqueInputSchema),z.lazy(() => UserWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => UserWhereUniqueInputSchema),z.lazy(() => UserWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => UserUpdateWithWhereUniqueWithoutWorldsInputSchema),z.lazy(() => UserUpdateWithWhereUniqueWithoutWorldsInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => UserUpdateManyWithWhereWithoutWorldsInputSchema),z.lazy(() => UserUpdateManyWithWhereWithoutWorldsInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => UserScalarWhereInputSchema),z.lazy(() => UserScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const SlapUncheckedUpdateManyWithoutWorldsNestedInputSchema: z.ZodType<Prisma.SlapUncheckedUpdateManyWithoutWorldsNestedInput> = z.object({
  create: z.union([ z.lazy(() => SlapCreateWithoutWorldsInputSchema),z.lazy(() => SlapCreateWithoutWorldsInputSchema).array(),z.lazy(() => SlapUncheckedCreateWithoutWorldsInputSchema),z.lazy(() => SlapUncheckedCreateWithoutWorldsInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => SlapCreateOrConnectWithoutWorldsInputSchema),z.lazy(() => SlapCreateOrConnectWithoutWorldsInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => SlapUpsertWithWhereUniqueWithoutWorldsInputSchema),z.lazy(() => SlapUpsertWithWhereUniqueWithoutWorldsInputSchema).array() ]).optional(),
  set: z.union([ z.lazy(() => SlapWhereUniqueInputSchema),z.lazy(() => SlapWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => SlapWhereUniqueInputSchema),z.lazy(() => SlapWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => SlapWhereUniqueInputSchema),z.lazy(() => SlapWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => SlapWhereUniqueInputSchema),z.lazy(() => SlapWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => SlapUpdateWithWhereUniqueWithoutWorldsInputSchema),z.lazy(() => SlapUpdateWithWhereUniqueWithoutWorldsInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => SlapUpdateManyWithWhereWithoutWorldsInputSchema),z.lazy(() => SlapUpdateManyWithWhereWithoutWorldsInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => SlapScalarWhereInputSchema),z.lazy(() => SlapScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const TagUncheckedUpdateManyWithoutWorldsNestedInputSchema: z.ZodType<Prisma.TagUncheckedUpdateManyWithoutWorldsNestedInput> = z.object({
  create: z.union([ z.lazy(() => TagCreateWithoutWorldsInputSchema),z.lazy(() => TagCreateWithoutWorldsInputSchema).array(),z.lazy(() => TagUncheckedCreateWithoutWorldsInputSchema),z.lazy(() => TagUncheckedCreateWithoutWorldsInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => TagCreateOrConnectWithoutWorldsInputSchema),z.lazy(() => TagCreateOrConnectWithoutWorldsInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => TagUpsertWithWhereUniqueWithoutWorldsInputSchema),z.lazy(() => TagUpsertWithWhereUniqueWithoutWorldsInputSchema).array() ]).optional(),
  set: z.union([ z.lazy(() => TagWhereUniqueInputSchema),z.lazy(() => TagWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => TagWhereUniqueInputSchema),z.lazy(() => TagWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => TagWhereUniqueInputSchema),z.lazy(() => TagWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => TagWhereUniqueInputSchema),z.lazy(() => TagWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => TagUpdateWithWhereUniqueWithoutWorldsInputSchema),z.lazy(() => TagUpdateWithWhereUniqueWithoutWorldsInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => TagUpdateManyWithWhereWithoutWorldsInputSchema),z.lazy(() => TagUpdateManyWithWhereWithoutWorldsInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => TagScalarWhereInputSchema),z.lazy(() => TagScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const SlapCreateNestedManyWithoutTagsInputSchema: z.ZodType<Prisma.SlapCreateNestedManyWithoutTagsInput> = z.object({
  create: z.union([ z.lazy(() => SlapCreateWithoutTagsInputSchema),z.lazy(() => SlapCreateWithoutTagsInputSchema).array(),z.lazy(() => SlapUncheckedCreateWithoutTagsInputSchema),z.lazy(() => SlapUncheckedCreateWithoutTagsInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => SlapCreateOrConnectWithoutTagsInputSchema),z.lazy(() => SlapCreateOrConnectWithoutTagsInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => SlapWhereUniqueInputSchema),z.lazy(() => SlapWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const WorldCreateNestedManyWithoutTagsInputSchema: z.ZodType<Prisma.WorldCreateNestedManyWithoutTagsInput> = z.object({
  create: z.union([ z.lazy(() => WorldCreateWithoutTagsInputSchema),z.lazy(() => WorldCreateWithoutTagsInputSchema).array(),z.lazy(() => WorldUncheckedCreateWithoutTagsInputSchema),z.lazy(() => WorldUncheckedCreateWithoutTagsInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => WorldCreateOrConnectWithoutTagsInputSchema),z.lazy(() => WorldCreateOrConnectWithoutTagsInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => WorldWhereUniqueInputSchema),z.lazy(() => WorldWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const SlapUncheckedCreateNestedManyWithoutTagsInputSchema: z.ZodType<Prisma.SlapUncheckedCreateNestedManyWithoutTagsInput> = z.object({
  create: z.union([ z.lazy(() => SlapCreateWithoutTagsInputSchema),z.lazy(() => SlapCreateWithoutTagsInputSchema).array(),z.lazy(() => SlapUncheckedCreateWithoutTagsInputSchema),z.lazy(() => SlapUncheckedCreateWithoutTagsInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => SlapCreateOrConnectWithoutTagsInputSchema),z.lazy(() => SlapCreateOrConnectWithoutTagsInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => SlapWhereUniqueInputSchema),z.lazy(() => SlapWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const WorldUncheckedCreateNestedManyWithoutTagsInputSchema: z.ZodType<Prisma.WorldUncheckedCreateNestedManyWithoutTagsInput> = z.object({
  create: z.union([ z.lazy(() => WorldCreateWithoutTagsInputSchema),z.lazy(() => WorldCreateWithoutTagsInputSchema).array(),z.lazy(() => WorldUncheckedCreateWithoutTagsInputSchema),z.lazy(() => WorldUncheckedCreateWithoutTagsInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => WorldCreateOrConnectWithoutTagsInputSchema),z.lazy(() => WorldCreateOrConnectWithoutTagsInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => WorldWhereUniqueInputSchema),z.lazy(() => WorldWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const SlapUpdateManyWithoutTagsNestedInputSchema: z.ZodType<Prisma.SlapUpdateManyWithoutTagsNestedInput> = z.object({
  create: z.union([ z.lazy(() => SlapCreateWithoutTagsInputSchema),z.lazy(() => SlapCreateWithoutTagsInputSchema).array(),z.lazy(() => SlapUncheckedCreateWithoutTagsInputSchema),z.lazy(() => SlapUncheckedCreateWithoutTagsInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => SlapCreateOrConnectWithoutTagsInputSchema),z.lazy(() => SlapCreateOrConnectWithoutTagsInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => SlapUpsertWithWhereUniqueWithoutTagsInputSchema),z.lazy(() => SlapUpsertWithWhereUniqueWithoutTagsInputSchema).array() ]).optional(),
  set: z.union([ z.lazy(() => SlapWhereUniqueInputSchema),z.lazy(() => SlapWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => SlapWhereUniqueInputSchema),z.lazy(() => SlapWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => SlapWhereUniqueInputSchema),z.lazy(() => SlapWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => SlapWhereUniqueInputSchema),z.lazy(() => SlapWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => SlapUpdateWithWhereUniqueWithoutTagsInputSchema),z.lazy(() => SlapUpdateWithWhereUniqueWithoutTagsInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => SlapUpdateManyWithWhereWithoutTagsInputSchema),z.lazy(() => SlapUpdateManyWithWhereWithoutTagsInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => SlapScalarWhereInputSchema),z.lazy(() => SlapScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const WorldUpdateManyWithoutTagsNestedInputSchema: z.ZodType<Prisma.WorldUpdateManyWithoutTagsNestedInput> = z.object({
  create: z.union([ z.lazy(() => WorldCreateWithoutTagsInputSchema),z.lazy(() => WorldCreateWithoutTagsInputSchema).array(),z.lazy(() => WorldUncheckedCreateWithoutTagsInputSchema),z.lazy(() => WorldUncheckedCreateWithoutTagsInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => WorldCreateOrConnectWithoutTagsInputSchema),z.lazy(() => WorldCreateOrConnectWithoutTagsInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => WorldUpsertWithWhereUniqueWithoutTagsInputSchema),z.lazy(() => WorldUpsertWithWhereUniqueWithoutTagsInputSchema).array() ]).optional(),
  set: z.union([ z.lazy(() => WorldWhereUniqueInputSchema),z.lazy(() => WorldWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => WorldWhereUniqueInputSchema),z.lazy(() => WorldWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => WorldWhereUniqueInputSchema),z.lazy(() => WorldWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => WorldWhereUniqueInputSchema),z.lazy(() => WorldWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => WorldUpdateWithWhereUniqueWithoutTagsInputSchema),z.lazy(() => WorldUpdateWithWhereUniqueWithoutTagsInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => WorldUpdateManyWithWhereWithoutTagsInputSchema),z.lazy(() => WorldUpdateManyWithWhereWithoutTagsInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => WorldScalarWhereInputSchema),z.lazy(() => WorldScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const SlapUncheckedUpdateManyWithoutTagsNestedInputSchema: z.ZodType<Prisma.SlapUncheckedUpdateManyWithoutTagsNestedInput> = z.object({
  create: z.union([ z.lazy(() => SlapCreateWithoutTagsInputSchema),z.lazy(() => SlapCreateWithoutTagsInputSchema).array(),z.lazy(() => SlapUncheckedCreateWithoutTagsInputSchema),z.lazy(() => SlapUncheckedCreateWithoutTagsInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => SlapCreateOrConnectWithoutTagsInputSchema),z.lazy(() => SlapCreateOrConnectWithoutTagsInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => SlapUpsertWithWhereUniqueWithoutTagsInputSchema),z.lazy(() => SlapUpsertWithWhereUniqueWithoutTagsInputSchema).array() ]).optional(),
  set: z.union([ z.lazy(() => SlapWhereUniqueInputSchema),z.lazy(() => SlapWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => SlapWhereUniqueInputSchema),z.lazy(() => SlapWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => SlapWhereUniqueInputSchema),z.lazy(() => SlapWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => SlapWhereUniqueInputSchema),z.lazy(() => SlapWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => SlapUpdateWithWhereUniqueWithoutTagsInputSchema),z.lazy(() => SlapUpdateWithWhereUniqueWithoutTagsInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => SlapUpdateManyWithWhereWithoutTagsInputSchema),z.lazy(() => SlapUpdateManyWithWhereWithoutTagsInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => SlapScalarWhereInputSchema),z.lazy(() => SlapScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const WorldUncheckedUpdateManyWithoutTagsNestedInputSchema: z.ZodType<Prisma.WorldUncheckedUpdateManyWithoutTagsNestedInput> = z.object({
  create: z.union([ z.lazy(() => WorldCreateWithoutTagsInputSchema),z.lazy(() => WorldCreateWithoutTagsInputSchema).array(),z.lazy(() => WorldUncheckedCreateWithoutTagsInputSchema),z.lazy(() => WorldUncheckedCreateWithoutTagsInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => WorldCreateOrConnectWithoutTagsInputSchema),z.lazy(() => WorldCreateOrConnectWithoutTagsInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => WorldUpsertWithWhereUniqueWithoutTagsInputSchema),z.lazy(() => WorldUpsertWithWhereUniqueWithoutTagsInputSchema).array() ]).optional(),
  set: z.union([ z.lazy(() => WorldWhereUniqueInputSchema),z.lazy(() => WorldWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => WorldWhereUniqueInputSchema),z.lazy(() => WorldWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => WorldWhereUniqueInputSchema),z.lazy(() => WorldWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => WorldWhereUniqueInputSchema),z.lazy(() => WorldWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => WorldUpdateWithWhereUniqueWithoutTagsInputSchema),z.lazy(() => WorldUpdateWithWhereUniqueWithoutTagsInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => WorldUpdateManyWithWhereWithoutTagsInputSchema),z.lazy(() => WorldUpdateManyWithWhereWithoutTagsInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => WorldScalarWhereInputSchema),z.lazy(() => WorldScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const SkinCreateNestedOneWithoutSlapsInputSchema: z.ZodType<Prisma.SkinCreateNestedOneWithoutSlapsInput> = z.object({
  create: z.union([ z.lazy(() => SkinCreateWithoutSlapsInputSchema),z.lazy(() => SkinUncheckedCreateWithoutSlapsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => SkinCreateOrConnectWithoutSlapsInputSchema).optional(),
  connect: z.lazy(() => SkinWhereUniqueInputSchema).optional()
}).strict();

export const UserCreateNestedOneWithoutSlapsInputSchema: z.ZodType<Prisma.UserCreateNestedOneWithoutSlapsInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutSlapsInputSchema),z.lazy(() => UserUncheckedCreateWithoutSlapsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => UserCreateOrConnectWithoutSlapsInputSchema).optional(),
  connect: z.lazy(() => UserWhereUniqueInputSchema).optional()
}).strict();

export const WorldCreateNestedManyWithoutSlapsInputSchema: z.ZodType<Prisma.WorldCreateNestedManyWithoutSlapsInput> = z.object({
  create: z.union([ z.lazy(() => WorldCreateWithoutSlapsInputSchema),z.lazy(() => WorldCreateWithoutSlapsInputSchema).array(),z.lazy(() => WorldUncheckedCreateWithoutSlapsInputSchema),z.lazy(() => WorldUncheckedCreateWithoutSlapsInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => WorldCreateOrConnectWithoutSlapsInputSchema),z.lazy(() => WorldCreateOrConnectWithoutSlapsInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => WorldWhereUniqueInputSchema),z.lazy(() => WorldWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const TagCreateNestedManyWithoutSlapsInputSchema: z.ZodType<Prisma.TagCreateNestedManyWithoutSlapsInput> = z.object({
  create: z.union([ z.lazy(() => TagCreateWithoutSlapsInputSchema),z.lazy(() => TagCreateWithoutSlapsInputSchema).array(),z.lazy(() => TagUncheckedCreateWithoutSlapsInputSchema),z.lazy(() => TagUncheckedCreateWithoutSlapsInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => TagCreateOrConnectWithoutSlapsInputSchema),z.lazy(() => TagCreateOrConnectWithoutSlapsInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => TagWhereUniqueInputSchema),z.lazy(() => TagWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const TrackCreateNestedManyWithoutSlapInputSchema: z.ZodType<Prisma.TrackCreateNestedManyWithoutSlapInput> = z.object({
  create: z.union([ z.lazy(() => TrackCreateWithoutSlapInputSchema),z.lazy(() => TrackCreateWithoutSlapInputSchema).array(),z.lazy(() => TrackUncheckedCreateWithoutSlapInputSchema),z.lazy(() => TrackUncheckedCreateWithoutSlapInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => TrackCreateOrConnectWithoutSlapInputSchema),z.lazy(() => TrackCreateOrConnectWithoutSlapInputSchema).array() ]).optional(),
  createMany: z.lazy(() => TrackCreateManySlapInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => TrackWhereUniqueInputSchema),z.lazy(() => TrackWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const SlapCreateNestedOneWithoutChildrenInputSchema: z.ZodType<Prisma.SlapCreateNestedOneWithoutChildrenInput> = z.object({
  create: z.union([ z.lazy(() => SlapCreateWithoutChildrenInputSchema),z.lazy(() => SlapUncheckedCreateWithoutChildrenInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => SlapCreateOrConnectWithoutChildrenInputSchema).optional(),
  connect: z.lazy(() => SlapWhereUniqueInputSchema).optional()
}).strict();

export const SlapCreateNestedOneWithoutDescendantsInputSchema: z.ZodType<Prisma.SlapCreateNestedOneWithoutDescendantsInput> = z.object({
  create: z.union([ z.lazy(() => SlapCreateWithoutDescendantsInputSchema),z.lazy(() => SlapUncheckedCreateWithoutDescendantsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => SlapCreateOrConnectWithoutDescendantsInputSchema).optional(),
  connect: z.lazy(() => SlapWhereUniqueInputSchema).optional()
}).strict();

export const SlapCreateNestedManyWithoutParentInputSchema: z.ZodType<Prisma.SlapCreateNestedManyWithoutParentInput> = z.object({
  create: z.union([ z.lazy(() => SlapCreateWithoutParentInputSchema),z.lazy(() => SlapCreateWithoutParentInputSchema).array(),z.lazy(() => SlapUncheckedCreateWithoutParentInputSchema),z.lazy(() => SlapUncheckedCreateWithoutParentInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => SlapCreateOrConnectWithoutParentInputSchema),z.lazy(() => SlapCreateOrConnectWithoutParentInputSchema).array() ]).optional(),
  createMany: z.lazy(() => SlapCreateManyParentInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => SlapWhereUniqueInputSchema),z.lazy(() => SlapWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const SlapCreateNestedManyWithoutRootInputSchema: z.ZodType<Prisma.SlapCreateNestedManyWithoutRootInput> = z.object({
  create: z.union([ z.lazy(() => SlapCreateWithoutRootInputSchema),z.lazy(() => SlapCreateWithoutRootInputSchema).array(),z.lazy(() => SlapUncheckedCreateWithoutRootInputSchema),z.lazy(() => SlapUncheckedCreateWithoutRootInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => SlapCreateOrConnectWithoutRootInputSchema),z.lazy(() => SlapCreateOrConnectWithoutRootInputSchema).array() ]).optional(),
  createMany: z.lazy(() => SlapCreateManyRootInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => SlapWhereUniqueInputSchema),z.lazy(() => SlapWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const WorldUncheckedCreateNestedManyWithoutSlapsInputSchema: z.ZodType<Prisma.WorldUncheckedCreateNestedManyWithoutSlapsInput> = z.object({
  create: z.union([ z.lazy(() => WorldCreateWithoutSlapsInputSchema),z.lazy(() => WorldCreateWithoutSlapsInputSchema).array(),z.lazy(() => WorldUncheckedCreateWithoutSlapsInputSchema),z.lazy(() => WorldUncheckedCreateWithoutSlapsInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => WorldCreateOrConnectWithoutSlapsInputSchema),z.lazy(() => WorldCreateOrConnectWithoutSlapsInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => WorldWhereUniqueInputSchema),z.lazy(() => WorldWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const TagUncheckedCreateNestedManyWithoutSlapsInputSchema: z.ZodType<Prisma.TagUncheckedCreateNestedManyWithoutSlapsInput> = z.object({
  create: z.union([ z.lazy(() => TagCreateWithoutSlapsInputSchema),z.lazy(() => TagCreateWithoutSlapsInputSchema).array(),z.lazy(() => TagUncheckedCreateWithoutSlapsInputSchema),z.lazy(() => TagUncheckedCreateWithoutSlapsInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => TagCreateOrConnectWithoutSlapsInputSchema),z.lazy(() => TagCreateOrConnectWithoutSlapsInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => TagWhereUniqueInputSchema),z.lazy(() => TagWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const TrackUncheckedCreateNestedManyWithoutSlapInputSchema: z.ZodType<Prisma.TrackUncheckedCreateNestedManyWithoutSlapInput> = z.object({
  create: z.union([ z.lazy(() => TrackCreateWithoutSlapInputSchema),z.lazy(() => TrackCreateWithoutSlapInputSchema).array(),z.lazy(() => TrackUncheckedCreateWithoutSlapInputSchema),z.lazy(() => TrackUncheckedCreateWithoutSlapInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => TrackCreateOrConnectWithoutSlapInputSchema),z.lazy(() => TrackCreateOrConnectWithoutSlapInputSchema).array() ]).optional(),
  createMany: z.lazy(() => TrackCreateManySlapInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => TrackWhereUniqueInputSchema),z.lazy(() => TrackWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const SlapUncheckedCreateNestedManyWithoutParentInputSchema: z.ZodType<Prisma.SlapUncheckedCreateNestedManyWithoutParentInput> = z.object({
  create: z.union([ z.lazy(() => SlapCreateWithoutParentInputSchema),z.lazy(() => SlapCreateWithoutParentInputSchema).array(),z.lazy(() => SlapUncheckedCreateWithoutParentInputSchema),z.lazy(() => SlapUncheckedCreateWithoutParentInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => SlapCreateOrConnectWithoutParentInputSchema),z.lazy(() => SlapCreateOrConnectWithoutParentInputSchema).array() ]).optional(),
  createMany: z.lazy(() => SlapCreateManyParentInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => SlapWhereUniqueInputSchema),z.lazy(() => SlapWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const SlapUncheckedCreateNestedManyWithoutRootInputSchema: z.ZodType<Prisma.SlapUncheckedCreateNestedManyWithoutRootInput> = z.object({
  create: z.union([ z.lazy(() => SlapCreateWithoutRootInputSchema),z.lazy(() => SlapCreateWithoutRootInputSchema).array(),z.lazy(() => SlapUncheckedCreateWithoutRootInputSchema),z.lazy(() => SlapUncheckedCreateWithoutRootInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => SlapCreateOrConnectWithoutRootInputSchema),z.lazy(() => SlapCreateOrConnectWithoutRootInputSchema).array() ]).optional(),
  createMany: z.lazy(() => SlapCreateManyRootInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => SlapWhereUniqueInputSchema),z.lazy(() => SlapWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const NullableStringFieldUpdateOperationsInputSchema: z.ZodType<Prisma.NullableStringFieldUpdateOperationsInput> = z.object({
  set: z.string().optional().nullable()
}).strict();

export const IntFieldUpdateOperationsInputSchema: z.ZodType<Prisma.IntFieldUpdateOperationsInput> = z.object({
  set: z.number().optional(),
  increment: z.number().optional(),
  decrement: z.number().optional(),
  multiply: z.number().optional(),
  divide: z.number().optional()
}).strict();

export const NullableIntFieldUpdateOperationsInputSchema: z.ZodType<Prisma.NullableIntFieldUpdateOperationsInput> = z.object({
  set: z.number().optional().nullable(),
  increment: z.number().optional(),
  decrement: z.number().optional(),
  multiply: z.number().optional(),
  divide: z.number().optional()
}).strict();

export const BoolFieldUpdateOperationsInputSchema: z.ZodType<Prisma.BoolFieldUpdateOperationsInput> = z.object({
  set: z.boolean().optional()
}).strict();

export const FloatFieldUpdateOperationsInputSchema: z.ZodType<Prisma.FloatFieldUpdateOperationsInput> = z.object({
  set: z.number().optional(),
  increment: z.number().optional(),
  decrement: z.number().optional(),
  multiply: z.number().optional(),
  divide: z.number().optional()
}).strict();

export const SkinUpdateOneRequiredWithoutSlapsNestedInputSchema: z.ZodType<Prisma.SkinUpdateOneRequiredWithoutSlapsNestedInput> = z.object({
  create: z.union([ z.lazy(() => SkinCreateWithoutSlapsInputSchema),z.lazy(() => SkinUncheckedCreateWithoutSlapsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => SkinCreateOrConnectWithoutSlapsInputSchema).optional(),
  upsert: z.lazy(() => SkinUpsertWithoutSlapsInputSchema).optional(),
  connect: z.lazy(() => SkinWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => SkinUpdateToOneWithWhereWithoutSlapsInputSchema),z.lazy(() => SkinUpdateWithoutSlapsInputSchema),z.lazy(() => SkinUncheckedUpdateWithoutSlapsInputSchema) ]).optional(),
}).strict();

export const UserUpdateOneWithoutSlapsNestedInputSchema: z.ZodType<Prisma.UserUpdateOneWithoutSlapsNestedInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutSlapsInputSchema),z.lazy(() => UserUncheckedCreateWithoutSlapsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => UserCreateOrConnectWithoutSlapsInputSchema).optional(),
  upsert: z.lazy(() => UserUpsertWithoutSlapsInputSchema).optional(),
  disconnect: z.union([ z.boolean(),z.lazy(() => UserWhereInputSchema) ]).optional(),
  delete: z.union([ z.boolean(),z.lazy(() => UserWhereInputSchema) ]).optional(),
  connect: z.lazy(() => UserWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => UserUpdateToOneWithWhereWithoutSlapsInputSchema),z.lazy(() => UserUpdateWithoutSlapsInputSchema),z.lazy(() => UserUncheckedUpdateWithoutSlapsInputSchema) ]).optional(),
}).strict();

export const WorldUpdateManyWithoutSlapsNestedInputSchema: z.ZodType<Prisma.WorldUpdateManyWithoutSlapsNestedInput> = z.object({
  create: z.union([ z.lazy(() => WorldCreateWithoutSlapsInputSchema),z.lazy(() => WorldCreateWithoutSlapsInputSchema).array(),z.lazy(() => WorldUncheckedCreateWithoutSlapsInputSchema),z.lazy(() => WorldUncheckedCreateWithoutSlapsInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => WorldCreateOrConnectWithoutSlapsInputSchema),z.lazy(() => WorldCreateOrConnectWithoutSlapsInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => WorldUpsertWithWhereUniqueWithoutSlapsInputSchema),z.lazy(() => WorldUpsertWithWhereUniqueWithoutSlapsInputSchema).array() ]).optional(),
  set: z.union([ z.lazy(() => WorldWhereUniqueInputSchema),z.lazy(() => WorldWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => WorldWhereUniqueInputSchema),z.lazy(() => WorldWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => WorldWhereUniqueInputSchema),z.lazy(() => WorldWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => WorldWhereUniqueInputSchema),z.lazy(() => WorldWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => WorldUpdateWithWhereUniqueWithoutSlapsInputSchema),z.lazy(() => WorldUpdateWithWhereUniqueWithoutSlapsInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => WorldUpdateManyWithWhereWithoutSlapsInputSchema),z.lazy(() => WorldUpdateManyWithWhereWithoutSlapsInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => WorldScalarWhereInputSchema),z.lazy(() => WorldScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const TagUpdateManyWithoutSlapsNestedInputSchema: z.ZodType<Prisma.TagUpdateManyWithoutSlapsNestedInput> = z.object({
  create: z.union([ z.lazy(() => TagCreateWithoutSlapsInputSchema),z.lazy(() => TagCreateWithoutSlapsInputSchema).array(),z.lazy(() => TagUncheckedCreateWithoutSlapsInputSchema),z.lazy(() => TagUncheckedCreateWithoutSlapsInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => TagCreateOrConnectWithoutSlapsInputSchema),z.lazy(() => TagCreateOrConnectWithoutSlapsInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => TagUpsertWithWhereUniqueWithoutSlapsInputSchema),z.lazy(() => TagUpsertWithWhereUniqueWithoutSlapsInputSchema).array() ]).optional(),
  set: z.union([ z.lazy(() => TagWhereUniqueInputSchema),z.lazy(() => TagWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => TagWhereUniqueInputSchema),z.lazy(() => TagWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => TagWhereUniqueInputSchema),z.lazy(() => TagWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => TagWhereUniqueInputSchema),z.lazy(() => TagWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => TagUpdateWithWhereUniqueWithoutSlapsInputSchema),z.lazy(() => TagUpdateWithWhereUniqueWithoutSlapsInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => TagUpdateManyWithWhereWithoutSlapsInputSchema),z.lazy(() => TagUpdateManyWithWhereWithoutSlapsInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => TagScalarWhereInputSchema),z.lazy(() => TagScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const TrackUpdateManyWithoutSlapNestedInputSchema: z.ZodType<Prisma.TrackUpdateManyWithoutSlapNestedInput> = z.object({
  create: z.union([ z.lazy(() => TrackCreateWithoutSlapInputSchema),z.lazy(() => TrackCreateWithoutSlapInputSchema).array(),z.lazy(() => TrackUncheckedCreateWithoutSlapInputSchema),z.lazy(() => TrackUncheckedCreateWithoutSlapInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => TrackCreateOrConnectWithoutSlapInputSchema),z.lazy(() => TrackCreateOrConnectWithoutSlapInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => TrackUpsertWithWhereUniqueWithoutSlapInputSchema),z.lazy(() => TrackUpsertWithWhereUniqueWithoutSlapInputSchema).array() ]).optional(),
  createMany: z.lazy(() => TrackCreateManySlapInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => TrackWhereUniqueInputSchema),z.lazy(() => TrackWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => TrackWhereUniqueInputSchema),z.lazy(() => TrackWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => TrackWhereUniqueInputSchema),z.lazy(() => TrackWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => TrackWhereUniqueInputSchema),z.lazy(() => TrackWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => TrackUpdateWithWhereUniqueWithoutSlapInputSchema),z.lazy(() => TrackUpdateWithWhereUniqueWithoutSlapInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => TrackUpdateManyWithWhereWithoutSlapInputSchema),z.lazy(() => TrackUpdateManyWithWhereWithoutSlapInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => TrackScalarWhereInputSchema),z.lazy(() => TrackScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const SlapUpdateOneWithoutChildrenNestedInputSchema: z.ZodType<Prisma.SlapUpdateOneWithoutChildrenNestedInput> = z.object({
  create: z.union([ z.lazy(() => SlapCreateWithoutChildrenInputSchema),z.lazy(() => SlapUncheckedCreateWithoutChildrenInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => SlapCreateOrConnectWithoutChildrenInputSchema).optional(),
  upsert: z.lazy(() => SlapUpsertWithoutChildrenInputSchema).optional(),
  disconnect: z.union([ z.boolean(),z.lazy(() => SlapWhereInputSchema) ]).optional(),
  delete: z.union([ z.boolean(),z.lazy(() => SlapWhereInputSchema) ]).optional(),
  connect: z.lazy(() => SlapWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => SlapUpdateToOneWithWhereWithoutChildrenInputSchema),z.lazy(() => SlapUpdateWithoutChildrenInputSchema),z.lazy(() => SlapUncheckedUpdateWithoutChildrenInputSchema) ]).optional(),
}).strict();

export const SlapUpdateOneWithoutDescendantsNestedInputSchema: z.ZodType<Prisma.SlapUpdateOneWithoutDescendantsNestedInput> = z.object({
  create: z.union([ z.lazy(() => SlapCreateWithoutDescendantsInputSchema),z.lazy(() => SlapUncheckedCreateWithoutDescendantsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => SlapCreateOrConnectWithoutDescendantsInputSchema).optional(),
  upsert: z.lazy(() => SlapUpsertWithoutDescendantsInputSchema).optional(),
  disconnect: z.union([ z.boolean(),z.lazy(() => SlapWhereInputSchema) ]).optional(),
  delete: z.union([ z.boolean(),z.lazy(() => SlapWhereInputSchema) ]).optional(),
  connect: z.lazy(() => SlapWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => SlapUpdateToOneWithWhereWithoutDescendantsInputSchema),z.lazy(() => SlapUpdateWithoutDescendantsInputSchema),z.lazy(() => SlapUncheckedUpdateWithoutDescendantsInputSchema) ]).optional(),
}).strict();

export const SlapUpdateManyWithoutParentNestedInputSchema: z.ZodType<Prisma.SlapUpdateManyWithoutParentNestedInput> = z.object({
  create: z.union([ z.lazy(() => SlapCreateWithoutParentInputSchema),z.lazy(() => SlapCreateWithoutParentInputSchema).array(),z.lazy(() => SlapUncheckedCreateWithoutParentInputSchema),z.lazy(() => SlapUncheckedCreateWithoutParentInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => SlapCreateOrConnectWithoutParentInputSchema),z.lazy(() => SlapCreateOrConnectWithoutParentInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => SlapUpsertWithWhereUniqueWithoutParentInputSchema),z.lazy(() => SlapUpsertWithWhereUniqueWithoutParentInputSchema).array() ]).optional(),
  createMany: z.lazy(() => SlapCreateManyParentInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => SlapWhereUniqueInputSchema),z.lazy(() => SlapWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => SlapWhereUniqueInputSchema),z.lazy(() => SlapWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => SlapWhereUniqueInputSchema),z.lazy(() => SlapWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => SlapWhereUniqueInputSchema),z.lazy(() => SlapWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => SlapUpdateWithWhereUniqueWithoutParentInputSchema),z.lazy(() => SlapUpdateWithWhereUniqueWithoutParentInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => SlapUpdateManyWithWhereWithoutParentInputSchema),z.lazy(() => SlapUpdateManyWithWhereWithoutParentInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => SlapScalarWhereInputSchema),z.lazy(() => SlapScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const SlapUpdateManyWithoutRootNestedInputSchema: z.ZodType<Prisma.SlapUpdateManyWithoutRootNestedInput> = z.object({
  create: z.union([ z.lazy(() => SlapCreateWithoutRootInputSchema),z.lazy(() => SlapCreateWithoutRootInputSchema).array(),z.lazy(() => SlapUncheckedCreateWithoutRootInputSchema),z.lazy(() => SlapUncheckedCreateWithoutRootInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => SlapCreateOrConnectWithoutRootInputSchema),z.lazy(() => SlapCreateOrConnectWithoutRootInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => SlapUpsertWithWhereUniqueWithoutRootInputSchema),z.lazy(() => SlapUpsertWithWhereUniqueWithoutRootInputSchema).array() ]).optional(),
  createMany: z.lazy(() => SlapCreateManyRootInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => SlapWhereUniqueInputSchema),z.lazy(() => SlapWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => SlapWhereUniqueInputSchema),z.lazy(() => SlapWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => SlapWhereUniqueInputSchema),z.lazy(() => SlapWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => SlapWhereUniqueInputSchema),z.lazy(() => SlapWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => SlapUpdateWithWhereUniqueWithoutRootInputSchema),z.lazy(() => SlapUpdateWithWhereUniqueWithoutRootInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => SlapUpdateManyWithWhereWithoutRootInputSchema),z.lazy(() => SlapUpdateManyWithWhereWithoutRootInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => SlapScalarWhereInputSchema),z.lazy(() => SlapScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const WorldUncheckedUpdateManyWithoutSlapsNestedInputSchema: z.ZodType<Prisma.WorldUncheckedUpdateManyWithoutSlapsNestedInput> = z.object({
  create: z.union([ z.lazy(() => WorldCreateWithoutSlapsInputSchema),z.lazy(() => WorldCreateWithoutSlapsInputSchema).array(),z.lazy(() => WorldUncheckedCreateWithoutSlapsInputSchema),z.lazy(() => WorldUncheckedCreateWithoutSlapsInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => WorldCreateOrConnectWithoutSlapsInputSchema),z.lazy(() => WorldCreateOrConnectWithoutSlapsInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => WorldUpsertWithWhereUniqueWithoutSlapsInputSchema),z.lazy(() => WorldUpsertWithWhereUniqueWithoutSlapsInputSchema).array() ]).optional(),
  set: z.union([ z.lazy(() => WorldWhereUniqueInputSchema),z.lazy(() => WorldWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => WorldWhereUniqueInputSchema),z.lazy(() => WorldWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => WorldWhereUniqueInputSchema),z.lazy(() => WorldWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => WorldWhereUniqueInputSchema),z.lazy(() => WorldWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => WorldUpdateWithWhereUniqueWithoutSlapsInputSchema),z.lazy(() => WorldUpdateWithWhereUniqueWithoutSlapsInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => WorldUpdateManyWithWhereWithoutSlapsInputSchema),z.lazy(() => WorldUpdateManyWithWhereWithoutSlapsInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => WorldScalarWhereInputSchema),z.lazy(() => WorldScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const TagUncheckedUpdateManyWithoutSlapsNestedInputSchema: z.ZodType<Prisma.TagUncheckedUpdateManyWithoutSlapsNestedInput> = z.object({
  create: z.union([ z.lazy(() => TagCreateWithoutSlapsInputSchema),z.lazy(() => TagCreateWithoutSlapsInputSchema).array(),z.lazy(() => TagUncheckedCreateWithoutSlapsInputSchema),z.lazy(() => TagUncheckedCreateWithoutSlapsInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => TagCreateOrConnectWithoutSlapsInputSchema),z.lazy(() => TagCreateOrConnectWithoutSlapsInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => TagUpsertWithWhereUniqueWithoutSlapsInputSchema),z.lazy(() => TagUpsertWithWhereUniqueWithoutSlapsInputSchema).array() ]).optional(),
  set: z.union([ z.lazy(() => TagWhereUniqueInputSchema),z.lazy(() => TagWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => TagWhereUniqueInputSchema),z.lazy(() => TagWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => TagWhereUniqueInputSchema),z.lazy(() => TagWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => TagWhereUniqueInputSchema),z.lazy(() => TagWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => TagUpdateWithWhereUniqueWithoutSlapsInputSchema),z.lazy(() => TagUpdateWithWhereUniqueWithoutSlapsInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => TagUpdateManyWithWhereWithoutSlapsInputSchema),z.lazy(() => TagUpdateManyWithWhereWithoutSlapsInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => TagScalarWhereInputSchema),z.lazy(() => TagScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const TrackUncheckedUpdateManyWithoutSlapNestedInputSchema: z.ZodType<Prisma.TrackUncheckedUpdateManyWithoutSlapNestedInput> = z.object({
  create: z.union([ z.lazy(() => TrackCreateWithoutSlapInputSchema),z.lazy(() => TrackCreateWithoutSlapInputSchema).array(),z.lazy(() => TrackUncheckedCreateWithoutSlapInputSchema),z.lazy(() => TrackUncheckedCreateWithoutSlapInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => TrackCreateOrConnectWithoutSlapInputSchema),z.lazy(() => TrackCreateOrConnectWithoutSlapInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => TrackUpsertWithWhereUniqueWithoutSlapInputSchema),z.lazy(() => TrackUpsertWithWhereUniqueWithoutSlapInputSchema).array() ]).optional(),
  createMany: z.lazy(() => TrackCreateManySlapInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => TrackWhereUniqueInputSchema),z.lazy(() => TrackWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => TrackWhereUniqueInputSchema),z.lazy(() => TrackWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => TrackWhereUniqueInputSchema),z.lazy(() => TrackWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => TrackWhereUniqueInputSchema),z.lazy(() => TrackWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => TrackUpdateWithWhereUniqueWithoutSlapInputSchema),z.lazy(() => TrackUpdateWithWhereUniqueWithoutSlapInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => TrackUpdateManyWithWhereWithoutSlapInputSchema),z.lazy(() => TrackUpdateManyWithWhereWithoutSlapInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => TrackScalarWhereInputSchema),z.lazy(() => TrackScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const SlapUncheckedUpdateManyWithoutParentNestedInputSchema: z.ZodType<Prisma.SlapUncheckedUpdateManyWithoutParentNestedInput> = z.object({
  create: z.union([ z.lazy(() => SlapCreateWithoutParentInputSchema),z.lazy(() => SlapCreateWithoutParentInputSchema).array(),z.lazy(() => SlapUncheckedCreateWithoutParentInputSchema),z.lazy(() => SlapUncheckedCreateWithoutParentInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => SlapCreateOrConnectWithoutParentInputSchema),z.lazy(() => SlapCreateOrConnectWithoutParentInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => SlapUpsertWithWhereUniqueWithoutParentInputSchema),z.lazy(() => SlapUpsertWithWhereUniqueWithoutParentInputSchema).array() ]).optional(),
  createMany: z.lazy(() => SlapCreateManyParentInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => SlapWhereUniqueInputSchema),z.lazy(() => SlapWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => SlapWhereUniqueInputSchema),z.lazy(() => SlapWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => SlapWhereUniqueInputSchema),z.lazy(() => SlapWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => SlapWhereUniqueInputSchema),z.lazy(() => SlapWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => SlapUpdateWithWhereUniqueWithoutParentInputSchema),z.lazy(() => SlapUpdateWithWhereUniqueWithoutParentInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => SlapUpdateManyWithWhereWithoutParentInputSchema),z.lazy(() => SlapUpdateManyWithWhereWithoutParentInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => SlapScalarWhereInputSchema),z.lazy(() => SlapScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const SlapUncheckedUpdateManyWithoutRootNestedInputSchema: z.ZodType<Prisma.SlapUncheckedUpdateManyWithoutRootNestedInput> = z.object({
  create: z.union([ z.lazy(() => SlapCreateWithoutRootInputSchema),z.lazy(() => SlapCreateWithoutRootInputSchema).array(),z.lazy(() => SlapUncheckedCreateWithoutRootInputSchema),z.lazy(() => SlapUncheckedCreateWithoutRootInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => SlapCreateOrConnectWithoutRootInputSchema),z.lazy(() => SlapCreateOrConnectWithoutRootInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => SlapUpsertWithWhereUniqueWithoutRootInputSchema),z.lazy(() => SlapUpsertWithWhereUniqueWithoutRootInputSchema).array() ]).optional(),
  createMany: z.lazy(() => SlapCreateManyRootInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => SlapWhereUniqueInputSchema),z.lazy(() => SlapWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => SlapWhereUniqueInputSchema),z.lazy(() => SlapWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => SlapWhereUniqueInputSchema),z.lazy(() => SlapWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => SlapWhereUniqueInputSchema),z.lazy(() => SlapWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => SlapUpdateWithWhereUniqueWithoutRootInputSchema),z.lazy(() => SlapUpdateWithWhereUniqueWithoutRootInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => SlapUpdateManyWithWhereWithoutRootInputSchema),z.lazy(() => SlapUpdateManyWithWhereWithoutRootInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => SlapScalarWhereInputSchema),z.lazy(() => SlapScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const SlapCreateNestedManyWithoutSkinInputSchema: z.ZodType<Prisma.SlapCreateNestedManyWithoutSkinInput> = z.object({
  create: z.union([ z.lazy(() => SlapCreateWithoutSkinInputSchema),z.lazy(() => SlapCreateWithoutSkinInputSchema).array(),z.lazy(() => SlapUncheckedCreateWithoutSkinInputSchema),z.lazy(() => SlapUncheckedCreateWithoutSkinInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => SlapCreateOrConnectWithoutSkinInputSchema),z.lazy(() => SlapCreateOrConnectWithoutSkinInputSchema).array() ]).optional(),
  createMany: z.lazy(() => SlapCreateManySkinInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => SlapWhereUniqueInputSchema),z.lazy(() => SlapWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const SlapUncheckedCreateNestedManyWithoutSkinInputSchema: z.ZodType<Prisma.SlapUncheckedCreateNestedManyWithoutSkinInput> = z.object({
  create: z.union([ z.lazy(() => SlapCreateWithoutSkinInputSchema),z.lazy(() => SlapCreateWithoutSkinInputSchema).array(),z.lazy(() => SlapUncheckedCreateWithoutSkinInputSchema),z.lazy(() => SlapUncheckedCreateWithoutSkinInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => SlapCreateOrConnectWithoutSkinInputSchema),z.lazy(() => SlapCreateOrConnectWithoutSkinInputSchema).array() ]).optional(),
  createMany: z.lazy(() => SlapCreateManySkinInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => SlapWhereUniqueInputSchema),z.lazy(() => SlapWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const SlapUpdateManyWithoutSkinNestedInputSchema: z.ZodType<Prisma.SlapUpdateManyWithoutSkinNestedInput> = z.object({
  create: z.union([ z.lazy(() => SlapCreateWithoutSkinInputSchema),z.lazy(() => SlapCreateWithoutSkinInputSchema).array(),z.lazy(() => SlapUncheckedCreateWithoutSkinInputSchema),z.lazy(() => SlapUncheckedCreateWithoutSkinInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => SlapCreateOrConnectWithoutSkinInputSchema),z.lazy(() => SlapCreateOrConnectWithoutSkinInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => SlapUpsertWithWhereUniqueWithoutSkinInputSchema),z.lazy(() => SlapUpsertWithWhereUniqueWithoutSkinInputSchema).array() ]).optional(),
  createMany: z.lazy(() => SlapCreateManySkinInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => SlapWhereUniqueInputSchema),z.lazy(() => SlapWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => SlapWhereUniqueInputSchema),z.lazy(() => SlapWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => SlapWhereUniqueInputSchema),z.lazy(() => SlapWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => SlapWhereUniqueInputSchema),z.lazy(() => SlapWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => SlapUpdateWithWhereUniqueWithoutSkinInputSchema),z.lazy(() => SlapUpdateWithWhereUniqueWithoutSkinInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => SlapUpdateManyWithWhereWithoutSkinInputSchema),z.lazy(() => SlapUpdateManyWithWhereWithoutSkinInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => SlapScalarWhereInputSchema),z.lazy(() => SlapScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const SlapUncheckedUpdateManyWithoutSkinNestedInputSchema: z.ZodType<Prisma.SlapUncheckedUpdateManyWithoutSkinNestedInput> = z.object({
  create: z.union([ z.lazy(() => SlapCreateWithoutSkinInputSchema),z.lazy(() => SlapCreateWithoutSkinInputSchema).array(),z.lazy(() => SlapUncheckedCreateWithoutSkinInputSchema),z.lazy(() => SlapUncheckedCreateWithoutSkinInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => SlapCreateOrConnectWithoutSkinInputSchema),z.lazy(() => SlapCreateOrConnectWithoutSkinInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => SlapUpsertWithWhereUniqueWithoutSkinInputSchema),z.lazy(() => SlapUpsertWithWhereUniqueWithoutSkinInputSchema).array() ]).optional(),
  createMany: z.lazy(() => SlapCreateManySkinInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => SlapWhereUniqueInputSchema),z.lazy(() => SlapWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => SlapWhereUniqueInputSchema),z.lazy(() => SlapWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => SlapWhereUniqueInputSchema),z.lazy(() => SlapWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => SlapWhereUniqueInputSchema),z.lazy(() => SlapWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => SlapUpdateWithWhereUniqueWithoutSkinInputSchema),z.lazy(() => SlapUpdateWithWhereUniqueWithoutSkinInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => SlapUpdateManyWithWhereWithoutSkinInputSchema),z.lazy(() => SlapUpdateManyWithWhereWithoutSkinInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => SlapScalarWhereInputSchema),z.lazy(() => SlapScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const TrackCreatedataInputSchema: z.ZodType<Prisma.TrackCreatedataInput> = z.object({
  set: InputJsonValueSchema.array()
}).strict();

export const SlapCreateNestedOneWithoutTracksInputSchema: z.ZodType<Prisma.SlapCreateNestedOneWithoutTracksInput> = z.object({
  create: z.union([ z.lazy(() => SlapCreateWithoutTracksInputSchema),z.lazy(() => SlapUncheckedCreateWithoutTracksInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => SlapCreateOrConnectWithoutTracksInputSchema).optional(),
  connect: z.lazy(() => SlapWhereUniqueInputSchema).optional()
}).strict();

export const TrackUpdatedataInputSchema: z.ZodType<Prisma.TrackUpdatedataInput> = z.object({
  set: InputJsonValueSchema.array().optional(),
  push: z.union([ InputJsonValueSchema,InputJsonValueSchema.array() ]).optional(),
}).strict();

export const NullableBoolFieldUpdateOperationsInputSchema: z.ZodType<Prisma.NullableBoolFieldUpdateOperationsInput> = z.object({
  set: z.boolean().optional().nullable()
}).strict();

export const SlapUpdateOneRequiredWithoutTracksNestedInputSchema: z.ZodType<Prisma.SlapUpdateOneRequiredWithoutTracksNestedInput> = z.object({
  create: z.union([ z.lazy(() => SlapCreateWithoutTracksInputSchema),z.lazy(() => SlapUncheckedCreateWithoutTracksInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => SlapCreateOrConnectWithoutTracksInputSchema).optional(),
  upsert: z.lazy(() => SlapUpsertWithoutTracksInputSchema).optional(),
  connect: z.lazy(() => SlapWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => SlapUpdateToOneWithWhereWithoutTracksInputSchema),z.lazy(() => SlapUpdateWithoutTracksInputSchema),z.lazy(() => SlapUncheckedUpdateWithoutTracksInputSchema) ]).optional(),
}).strict();

export const UserCreateNestedOneWithoutAccountsInputSchema: z.ZodType<Prisma.UserCreateNestedOneWithoutAccountsInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutAccountsInputSchema),z.lazy(() => UserUncheckedCreateWithoutAccountsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => UserCreateOrConnectWithoutAccountsInputSchema).optional(),
  connect: z.lazy(() => UserWhereUniqueInputSchema).optional()
}).strict();

export const UserUpdateOneRequiredWithoutAccountsNestedInputSchema: z.ZodType<Prisma.UserUpdateOneRequiredWithoutAccountsNestedInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutAccountsInputSchema),z.lazy(() => UserUncheckedCreateWithoutAccountsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => UserCreateOrConnectWithoutAccountsInputSchema).optional(),
  upsert: z.lazy(() => UserUpsertWithoutAccountsInputSchema).optional(),
  connect: z.lazy(() => UserWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => UserUpdateToOneWithWhereWithoutAccountsInputSchema),z.lazy(() => UserUpdateWithoutAccountsInputSchema),z.lazy(() => UserUncheckedUpdateWithoutAccountsInputSchema) ]).optional(),
}).strict();

export const UserCreateNestedOneWithoutSessionsInputSchema: z.ZodType<Prisma.UserCreateNestedOneWithoutSessionsInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutSessionsInputSchema),z.lazy(() => UserUncheckedCreateWithoutSessionsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => UserCreateOrConnectWithoutSessionsInputSchema).optional(),
  connect: z.lazy(() => UserWhereUniqueInputSchema).optional()
}).strict();

export const UserUpdateOneRequiredWithoutSessionsNestedInputSchema: z.ZodType<Prisma.UserUpdateOneRequiredWithoutSessionsNestedInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutSessionsInputSchema),z.lazy(() => UserUncheckedCreateWithoutSessionsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => UserCreateOrConnectWithoutSessionsInputSchema).optional(),
  upsert: z.lazy(() => UserUpsertWithoutSessionsInputSchema).optional(),
  connect: z.lazy(() => UserWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => UserUpdateToOneWithWhereWithoutSessionsInputSchema),z.lazy(() => UserUpdateWithoutSessionsInputSchema),z.lazy(() => UserUncheckedUpdateWithoutSessionsInputSchema) ]).optional(),
}).strict();

export const AccountCreateNestedManyWithoutUserInputSchema: z.ZodType<Prisma.AccountCreateNestedManyWithoutUserInput> = z.object({
  create: z.union([ z.lazy(() => AccountCreateWithoutUserInputSchema),z.lazy(() => AccountCreateWithoutUserInputSchema).array(),z.lazy(() => AccountUncheckedCreateWithoutUserInputSchema),z.lazy(() => AccountUncheckedCreateWithoutUserInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => AccountCreateOrConnectWithoutUserInputSchema),z.lazy(() => AccountCreateOrConnectWithoutUserInputSchema).array() ]).optional(),
  createMany: z.lazy(() => AccountCreateManyUserInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => AccountWhereUniqueInputSchema),z.lazy(() => AccountWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const SessionCreateNestedManyWithoutUserInputSchema: z.ZodType<Prisma.SessionCreateNestedManyWithoutUserInput> = z.object({
  create: z.union([ z.lazy(() => SessionCreateWithoutUserInputSchema),z.lazy(() => SessionCreateWithoutUserInputSchema).array(),z.lazy(() => SessionUncheckedCreateWithoutUserInputSchema),z.lazy(() => SessionUncheckedCreateWithoutUserInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => SessionCreateOrConnectWithoutUserInputSchema),z.lazy(() => SessionCreateOrConnectWithoutUserInputSchema).array() ]).optional(),
  createMany: z.lazy(() => SessionCreateManyUserInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => SessionWhereUniqueInputSchema),z.lazy(() => SessionWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const SlapCreateNestedManyWithoutCreatorInputSchema: z.ZodType<Prisma.SlapCreateNestedManyWithoutCreatorInput> = z.object({
  create: z.union([ z.lazy(() => SlapCreateWithoutCreatorInputSchema),z.lazy(() => SlapCreateWithoutCreatorInputSchema).array(),z.lazy(() => SlapUncheckedCreateWithoutCreatorInputSchema),z.lazy(() => SlapUncheckedCreateWithoutCreatorInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => SlapCreateOrConnectWithoutCreatorInputSchema),z.lazy(() => SlapCreateOrConnectWithoutCreatorInputSchema).array() ]).optional(),
  createMany: z.lazy(() => SlapCreateManyCreatorInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => SlapWhereUniqueInputSchema),z.lazy(() => SlapWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const WorldCreateNestedManyWithoutUsersInputSchema: z.ZodType<Prisma.WorldCreateNestedManyWithoutUsersInput> = z.object({
  create: z.union([ z.lazy(() => WorldCreateWithoutUsersInputSchema),z.lazy(() => WorldCreateWithoutUsersInputSchema).array(),z.lazy(() => WorldUncheckedCreateWithoutUsersInputSchema),z.lazy(() => WorldUncheckedCreateWithoutUsersInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => WorldCreateOrConnectWithoutUsersInputSchema),z.lazy(() => WorldCreateOrConnectWithoutUsersInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => WorldWhereUniqueInputSchema),z.lazy(() => WorldWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const AccountUncheckedCreateNestedManyWithoutUserInputSchema: z.ZodType<Prisma.AccountUncheckedCreateNestedManyWithoutUserInput> = z.object({
  create: z.union([ z.lazy(() => AccountCreateWithoutUserInputSchema),z.lazy(() => AccountCreateWithoutUserInputSchema).array(),z.lazy(() => AccountUncheckedCreateWithoutUserInputSchema),z.lazy(() => AccountUncheckedCreateWithoutUserInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => AccountCreateOrConnectWithoutUserInputSchema),z.lazy(() => AccountCreateOrConnectWithoutUserInputSchema).array() ]).optional(),
  createMany: z.lazy(() => AccountCreateManyUserInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => AccountWhereUniqueInputSchema),z.lazy(() => AccountWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const SessionUncheckedCreateNestedManyWithoutUserInputSchema: z.ZodType<Prisma.SessionUncheckedCreateNestedManyWithoutUserInput> = z.object({
  create: z.union([ z.lazy(() => SessionCreateWithoutUserInputSchema),z.lazy(() => SessionCreateWithoutUserInputSchema).array(),z.lazy(() => SessionUncheckedCreateWithoutUserInputSchema),z.lazy(() => SessionUncheckedCreateWithoutUserInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => SessionCreateOrConnectWithoutUserInputSchema),z.lazy(() => SessionCreateOrConnectWithoutUserInputSchema).array() ]).optional(),
  createMany: z.lazy(() => SessionCreateManyUserInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => SessionWhereUniqueInputSchema),z.lazy(() => SessionWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const SlapUncheckedCreateNestedManyWithoutCreatorInputSchema: z.ZodType<Prisma.SlapUncheckedCreateNestedManyWithoutCreatorInput> = z.object({
  create: z.union([ z.lazy(() => SlapCreateWithoutCreatorInputSchema),z.lazy(() => SlapCreateWithoutCreatorInputSchema).array(),z.lazy(() => SlapUncheckedCreateWithoutCreatorInputSchema),z.lazy(() => SlapUncheckedCreateWithoutCreatorInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => SlapCreateOrConnectWithoutCreatorInputSchema),z.lazy(() => SlapCreateOrConnectWithoutCreatorInputSchema).array() ]).optional(),
  createMany: z.lazy(() => SlapCreateManyCreatorInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => SlapWhereUniqueInputSchema),z.lazy(() => SlapWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const WorldUncheckedCreateNestedManyWithoutUsersInputSchema: z.ZodType<Prisma.WorldUncheckedCreateNestedManyWithoutUsersInput> = z.object({
  create: z.union([ z.lazy(() => WorldCreateWithoutUsersInputSchema),z.lazy(() => WorldCreateWithoutUsersInputSchema).array(),z.lazy(() => WorldUncheckedCreateWithoutUsersInputSchema),z.lazy(() => WorldUncheckedCreateWithoutUsersInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => WorldCreateOrConnectWithoutUsersInputSchema),z.lazy(() => WorldCreateOrConnectWithoutUsersInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => WorldWhereUniqueInputSchema),z.lazy(() => WorldWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const NullableDateTimeFieldUpdateOperationsInputSchema: z.ZodType<Prisma.NullableDateTimeFieldUpdateOperationsInput> = z.object({
  set: z.coerce.date().optional().nullable()
}).strict();

export const EnumRoleFieldUpdateOperationsInputSchema: z.ZodType<Prisma.EnumRoleFieldUpdateOperationsInput> = z.object({
  set: z.lazy(() => RoleSchema).optional()
}).strict();

export const AccountUpdateManyWithoutUserNestedInputSchema: z.ZodType<Prisma.AccountUpdateManyWithoutUserNestedInput> = z.object({
  create: z.union([ z.lazy(() => AccountCreateWithoutUserInputSchema),z.lazy(() => AccountCreateWithoutUserInputSchema).array(),z.lazy(() => AccountUncheckedCreateWithoutUserInputSchema),z.lazy(() => AccountUncheckedCreateWithoutUserInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => AccountCreateOrConnectWithoutUserInputSchema),z.lazy(() => AccountCreateOrConnectWithoutUserInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => AccountUpsertWithWhereUniqueWithoutUserInputSchema),z.lazy(() => AccountUpsertWithWhereUniqueWithoutUserInputSchema).array() ]).optional(),
  createMany: z.lazy(() => AccountCreateManyUserInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => AccountWhereUniqueInputSchema),z.lazy(() => AccountWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => AccountWhereUniqueInputSchema),z.lazy(() => AccountWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => AccountWhereUniqueInputSchema),z.lazy(() => AccountWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => AccountWhereUniqueInputSchema),z.lazy(() => AccountWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => AccountUpdateWithWhereUniqueWithoutUserInputSchema),z.lazy(() => AccountUpdateWithWhereUniqueWithoutUserInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => AccountUpdateManyWithWhereWithoutUserInputSchema),z.lazy(() => AccountUpdateManyWithWhereWithoutUserInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => AccountScalarWhereInputSchema),z.lazy(() => AccountScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const SessionUpdateManyWithoutUserNestedInputSchema: z.ZodType<Prisma.SessionUpdateManyWithoutUserNestedInput> = z.object({
  create: z.union([ z.lazy(() => SessionCreateWithoutUserInputSchema),z.lazy(() => SessionCreateWithoutUserInputSchema).array(),z.lazy(() => SessionUncheckedCreateWithoutUserInputSchema),z.lazy(() => SessionUncheckedCreateWithoutUserInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => SessionCreateOrConnectWithoutUserInputSchema),z.lazy(() => SessionCreateOrConnectWithoutUserInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => SessionUpsertWithWhereUniqueWithoutUserInputSchema),z.lazy(() => SessionUpsertWithWhereUniqueWithoutUserInputSchema).array() ]).optional(),
  createMany: z.lazy(() => SessionCreateManyUserInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => SessionWhereUniqueInputSchema),z.lazy(() => SessionWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => SessionWhereUniqueInputSchema),z.lazy(() => SessionWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => SessionWhereUniqueInputSchema),z.lazy(() => SessionWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => SessionWhereUniqueInputSchema),z.lazy(() => SessionWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => SessionUpdateWithWhereUniqueWithoutUserInputSchema),z.lazy(() => SessionUpdateWithWhereUniqueWithoutUserInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => SessionUpdateManyWithWhereWithoutUserInputSchema),z.lazy(() => SessionUpdateManyWithWhereWithoutUserInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => SessionScalarWhereInputSchema),z.lazy(() => SessionScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const SlapUpdateManyWithoutCreatorNestedInputSchema: z.ZodType<Prisma.SlapUpdateManyWithoutCreatorNestedInput> = z.object({
  create: z.union([ z.lazy(() => SlapCreateWithoutCreatorInputSchema),z.lazy(() => SlapCreateWithoutCreatorInputSchema).array(),z.lazy(() => SlapUncheckedCreateWithoutCreatorInputSchema),z.lazy(() => SlapUncheckedCreateWithoutCreatorInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => SlapCreateOrConnectWithoutCreatorInputSchema),z.lazy(() => SlapCreateOrConnectWithoutCreatorInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => SlapUpsertWithWhereUniqueWithoutCreatorInputSchema),z.lazy(() => SlapUpsertWithWhereUniqueWithoutCreatorInputSchema).array() ]).optional(),
  createMany: z.lazy(() => SlapCreateManyCreatorInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => SlapWhereUniqueInputSchema),z.lazy(() => SlapWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => SlapWhereUniqueInputSchema),z.lazy(() => SlapWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => SlapWhereUniqueInputSchema),z.lazy(() => SlapWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => SlapWhereUniqueInputSchema),z.lazy(() => SlapWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => SlapUpdateWithWhereUniqueWithoutCreatorInputSchema),z.lazy(() => SlapUpdateWithWhereUniqueWithoutCreatorInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => SlapUpdateManyWithWhereWithoutCreatorInputSchema),z.lazy(() => SlapUpdateManyWithWhereWithoutCreatorInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => SlapScalarWhereInputSchema),z.lazy(() => SlapScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const WorldUpdateManyWithoutUsersNestedInputSchema: z.ZodType<Prisma.WorldUpdateManyWithoutUsersNestedInput> = z.object({
  create: z.union([ z.lazy(() => WorldCreateWithoutUsersInputSchema),z.lazy(() => WorldCreateWithoutUsersInputSchema).array(),z.lazy(() => WorldUncheckedCreateWithoutUsersInputSchema),z.lazy(() => WorldUncheckedCreateWithoutUsersInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => WorldCreateOrConnectWithoutUsersInputSchema),z.lazy(() => WorldCreateOrConnectWithoutUsersInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => WorldUpsertWithWhereUniqueWithoutUsersInputSchema),z.lazy(() => WorldUpsertWithWhereUniqueWithoutUsersInputSchema).array() ]).optional(),
  set: z.union([ z.lazy(() => WorldWhereUniqueInputSchema),z.lazy(() => WorldWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => WorldWhereUniqueInputSchema),z.lazy(() => WorldWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => WorldWhereUniqueInputSchema),z.lazy(() => WorldWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => WorldWhereUniqueInputSchema),z.lazy(() => WorldWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => WorldUpdateWithWhereUniqueWithoutUsersInputSchema),z.lazy(() => WorldUpdateWithWhereUniqueWithoutUsersInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => WorldUpdateManyWithWhereWithoutUsersInputSchema),z.lazy(() => WorldUpdateManyWithWhereWithoutUsersInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => WorldScalarWhereInputSchema),z.lazy(() => WorldScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const AccountUncheckedUpdateManyWithoutUserNestedInputSchema: z.ZodType<Prisma.AccountUncheckedUpdateManyWithoutUserNestedInput> = z.object({
  create: z.union([ z.lazy(() => AccountCreateWithoutUserInputSchema),z.lazy(() => AccountCreateWithoutUserInputSchema).array(),z.lazy(() => AccountUncheckedCreateWithoutUserInputSchema),z.lazy(() => AccountUncheckedCreateWithoutUserInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => AccountCreateOrConnectWithoutUserInputSchema),z.lazy(() => AccountCreateOrConnectWithoutUserInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => AccountUpsertWithWhereUniqueWithoutUserInputSchema),z.lazy(() => AccountUpsertWithWhereUniqueWithoutUserInputSchema).array() ]).optional(),
  createMany: z.lazy(() => AccountCreateManyUserInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => AccountWhereUniqueInputSchema),z.lazy(() => AccountWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => AccountWhereUniqueInputSchema),z.lazy(() => AccountWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => AccountWhereUniqueInputSchema),z.lazy(() => AccountWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => AccountWhereUniqueInputSchema),z.lazy(() => AccountWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => AccountUpdateWithWhereUniqueWithoutUserInputSchema),z.lazy(() => AccountUpdateWithWhereUniqueWithoutUserInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => AccountUpdateManyWithWhereWithoutUserInputSchema),z.lazy(() => AccountUpdateManyWithWhereWithoutUserInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => AccountScalarWhereInputSchema),z.lazy(() => AccountScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const SessionUncheckedUpdateManyWithoutUserNestedInputSchema: z.ZodType<Prisma.SessionUncheckedUpdateManyWithoutUserNestedInput> = z.object({
  create: z.union([ z.lazy(() => SessionCreateWithoutUserInputSchema),z.lazy(() => SessionCreateWithoutUserInputSchema).array(),z.lazy(() => SessionUncheckedCreateWithoutUserInputSchema),z.lazy(() => SessionUncheckedCreateWithoutUserInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => SessionCreateOrConnectWithoutUserInputSchema),z.lazy(() => SessionCreateOrConnectWithoutUserInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => SessionUpsertWithWhereUniqueWithoutUserInputSchema),z.lazy(() => SessionUpsertWithWhereUniqueWithoutUserInputSchema).array() ]).optional(),
  createMany: z.lazy(() => SessionCreateManyUserInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => SessionWhereUniqueInputSchema),z.lazy(() => SessionWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => SessionWhereUniqueInputSchema),z.lazy(() => SessionWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => SessionWhereUniqueInputSchema),z.lazy(() => SessionWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => SessionWhereUniqueInputSchema),z.lazy(() => SessionWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => SessionUpdateWithWhereUniqueWithoutUserInputSchema),z.lazy(() => SessionUpdateWithWhereUniqueWithoutUserInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => SessionUpdateManyWithWhereWithoutUserInputSchema),z.lazy(() => SessionUpdateManyWithWhereWithoutUserInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => SessionScalarWhereInputSchema),z.lazy(() => SessionScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const SlapUncheckedUpdateManyWithoutCreatorNestedInputSchema: z.ZodType<Prisma.SlapUncheckedUpdateManyWithoutCreatorNestedInput> = z.object({
  create: z.union([ z.lazy(() => SlapCreateWithoutCreatorInputSchema),z.lazy(() => SlapCreateWithoutCreatorInputSchema).array(),z.lazy(() => SlapUncheckedCreateWithoutCreatorInputSchema),z.lazy(() => SlapUncheckedCreateWithoutCreatorInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => SlapCreateOrConnectWithoutCreatorInputSchema),z.lazy(() => SlapCreateOrConnectWithoutCreatorInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => SlapUpsertWithWhereUniqueWithoutCreatorInputSchema),z.lazy(() => SlapUpsertWithWhereUniqueWithoutCreatorInputSchema).array() ]).optional(),
  createMany: z.lazy(() => SlapCreateManyCreatorInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => SlapWhereUniqueInputSchema),z.lazy(() => SlapWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => SlapWhereUniqueInputSchema),z.lazy(() => SlapWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => SlapWhereUniqueInputSchema),z.lazy(() => SlapWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => SlapWhereUniqueInputSchema),z.lazy(() => SlapWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => SlapUpdateWithWhereUniqueWithoutCreatorInputSchema),z.lazy(() => SlapUpdateWithWhereUniqueWithoutCreatorInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => SlapUpdateManyWithWhereWithoutCreatorInputSchema),z.lazy(() => SlapUpdateManyWithWhereWithoutCreatorInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => SlapScalarWhereInputSchema),z.lazy(() => SlapScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const WorldUncheckedUpdateManyWithoutUsersNestedInputSchema: z.ZodType<Prisma.WorldUncheckedUpdateManyWithoutUsersNestedInput> = z.object({
  create: z.union([ z.lazy(() => WorldCreateWithoutUsersInputSchema),z.lazy(() => WorldCreateWithoutUsersInputSchema).array(),z.lazy(() => WorldUncheckedCreateWithoutUsersInputSchema),z.lazy(() => WorldUncheckedCreateWithoutUsersInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => WorldCreateOrConnectWithoutUsersInputSchema),z.lazy(() => WorldCreateOrConnectWithoutUsersInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => WorldUpsertWithWhereUniqueWithoutUsersInputSchema),z.lazy(() => WorldUpsertWithWhereUniqueWithoutUsersInputSchema).array() ]).optional(),
  set: z.union([ z.lazy(() => WorldWhereUniqueInputSchema),z.lazy(() => WorldWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => WorldWhereUniqueInputSchema),z.lazy(() => WorldWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => WorldWhereUniqueInputSchema),z.lazy(() => WorldWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => WorldWhereUniqueInputSchema),z.lazy(() => WorldWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => WorldUpdateWithWhereUniqueWithoutUsersInputSchema),z.lazy(() => WorldUpdateWithWhereUniqueWithoutUsersInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => WorldUpdateManyWithWhereWithoutUsersInputSchema),z.lazy(() => WorldUpdateManyWithWhereWithoutUsersInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => WorldScalarWhereInputSchema),z.lazy(() => WorldScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const SampleCreategenreInputSchema: z.ZodType<Prisma.SampleCreategenreInput> = z.object({
  set: z.string().array()
}).strict();

export const SampleCreatedownbeat_timesInputSchema: z.ZodType<Prisma.SampleCreatedownbeat_timesInput> = z.object({
  set: z.number().array()
}).strict();

export const SampleUpdategenreInputSchema: z.ZodType<Prisma.SampleUpdategenreInput> = z.object({
  set: z.string().array().optional(),
  push: z.union([ z.string(),z.string().array() ]).optional(),
}).strict();

export const SampleUpdatedownbeat_timesInputSchema: z.ZodType<Prisma.SampleUpdatedownbeat_timesInput> = z.object({
  set: z.number().array().optional(),
  push: z.union([ z.number(),z.number().array() ]).optional(),
}).strict();

export const NestedStringFilterSchema: z.ZodType<Prisma.NestedStringFilter> = z.object({
  equals: z.string().optional(),
  in: z.string().array().optional(),
  notIn: z.string().array().optional(),
  lt: z.string().optional(),
  lte: z.string().optional(),
  gt: z.string().optional(),
  gte: z.string().optional(),
  contains: z.string().optional(),
  startsWith: z.string().optional(),
  endsWith: z.string().optional(),
  not: z.union([ z.string(),z.lazy(() => NestedStringFilterSchema) ]).optional(),
}).strict();

export const NestedDateTimeFilterSchema: z.ZodType<Prisma.NestedDateTimeFilter> = z.object({
  equals: z.coerce.date().optional(),
  in: z.coerce.date().array().optional(),
  notIn: z.coerce.date().array().optional(),
  lt: z.coerce.date().optional(),
  lte: z.coerce.date().optional(),
  gt: z.coerce.date().optional(),
  gte: z.coerce.date().optional(),
  not: z.union([ z.coerce.date(),z.lazy(() => NestedDateTimeFilterSchema) ]).optional(),
}).strict();

export const NestedStringWithAggregatesFilterSchema: z.ZodType<Prisma.NestedStringWithAggregatesFilter> = z.object({
  equals: z.string().optional(),
  in: z.string().array().optional(),
  notIn: z.string().array().optional(),
  lt: z.string().optional(),
  lte: z.string().optional(),
  gt: z.string().optional(),
  gte: z.string().optional(),
  contains: z.string().optional(),
  startsWith: z.string().optional(),
  endsWith: z.string().optional(),
  not: z.union([ z.string(),z.lazy(() => NestedStringWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedStringFilterSchema).optional(),
  _max: z.lazy(() => NestedStringFilterSchema).optional()
}).strict();

export const NestedIntFilterSchema: z.ZodType<Prisma.NestedIntFilter> = z.object({
  equals: z.number().optional(),
  in: z.number().array().optional(),
  notIn: z.number().array().optional(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedIntFilterSchema) ]).optional(),
}).strict();

export const NestedDateTimeWithAggregatesFilterSchema: z.ZodType<Prisma.NestedDateTimeWithAggregatesFilter> = z.object({
  equals: z.coerce.date().optional(),
  in: z.coerce.date().array().optional(),
  notIn: z.coerce.date().array().optional(),
  lt: z.coerce.date().optional(),
  lte: z.coerce.date().optional(),
  gt: z.coerce.date().optional(),
  gte: z.coerce.date().optional(),
  not: z.union([ z.coerce.date(),z.lazy(() => NestedDateTimeWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedDateTimeFilterSchema).optional(),
  _max: z.lazy(() => NestedDateTimeFilterSchema).optional()
}).strict();

export const NestedStringNullableFilterSchema: z.ZodType<Prisma.NestedStringNullableFilter> = z.object({
  equals: z.string().optional().nullable(),
  in: z.string().array().optional().nullable(),
  notIn: z.string().array().optional().nullable(),
  lt: z.string().optional(),
  lte: z.string().optional(),
  gt: z.string().optional(),
  gte: z.string().optional(),
  contains: z.string().optional(),
  startsWith: z.string().optional(),
  endsWith: z.string().optional(),
  not: z.union([ z.string(),z.lazy(() => NestedStringNullableFilterSchema) ]).optional().nullable(),
}).strict();

export const NestedIntNullableFilterSchema: z.ZodType<Prisma.NestedIntNullableFilter> = z.object({
  equals: z.number().optional().nullable(),
  in: z.number().array().optional().nullable(),
  notIn: z.number().array().optional().nullable(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedIntNullableFilterSchema) ]).optional().nullable(),
}).strict();

export const NestedBoolFilterSchema: z.ZodType<Prisma.NestedBoolFilter> = z.object({
  equals: z.boolean().optional(),
  not: z.union([ z.boolean(),z.lazy(() => NestedBoolFilterSchema) ]).optional(),
}).strict();

export const NestedFloatFilterSchema: z.ZodType<Prisma.NestedFloatFilter> = z.object({
  equals: z.number().optional(),
  in: z.number().array().optional(),
  notIn: z.number().array().optional(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedFloatFilterSchema) ]).optional(),
}).strict();

export const NestedUuidNullableFilterSchema: z.ZodType<Prisma.NestedUuidNullableFilter> = z.object({
  equals: z.string().optional().nullable(),
  in: z.string().array().optional().nullable(),
  notIn: z.string().array().optional().nullable(),
  lt: z.string().optional(),
  lte: z.string().optional(),
  gt: z.string().optional(),
  gte: z.string().optional(),
  not: z.union([ z.string(),z.lazy(() => NestedUuidNullableFilterSchema) ]).optional().nullable(),
}).strict();

export const NestedStringNullableWithAggregatesFilterSchema: z.ZodType<Prisma.NestedStringNullableWithAggregatesFilter> = z.object({
  equals: z.string().optional().nullable(),
  in: z.string().array().optional().nullable(),
  notIn: z.string().array().optional().nullable(),
  lt: z.string().optional(),
  lte: z.string().optional(),
  gt: z.string().optional(),
  gte: z.string().optional(),
  contains: z.string().optional(),
  startsWith: z.string().optional(),
  endsWith: z.string().optional(),
  not: z.union([ z.string(),z.lazy(() => NestedStringNullableWithAggregatesFilterSchema) ]).optional().nullable(),
  _count: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _min: z.lazy(() => NestedStringNullableFilterSchema).optional(),
  _max: z.lazy(() => NestedStringNullableFilterSchema).optional()
}).strict();

export const NestedIntWithAggregatesFilterSchema: z.ZodType<Prisma.NestedIntWithAggregatesFilter> = z.object({
  equals: z.number().optional(),
  in: z.number().array().optional(),
  notIn: z.number().array().optional(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedIntWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _avg: z.lazy(() => NestedFloatFilterSchema).optional(),
  _sum: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedIntFilterSchema).optional(),
  _max: z.lazy(() => NestedIntFilterSchema).optional()
}).strict();

export const NestedIntNullableWithAggregatesFilterSchema: z.ZodType<Prisma.NestedIntNullableWithAggregatesFilter> = z.object({
  equals: z.number().optional().nullable(),
  in: z.number().array().optional().nullable(),
  notIn: z.number().array().optional().nullable(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedIntNullableWithAggregatesFilterSchema) ]).optional().nullable(),
  _count: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _avg: z.lazy(() => NestedFloatNullableFilterSchema).optional(),
  _sum: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _min: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _max: z.lazy(() => NestedIntNullableFilterSchema).optional()
}).strict();

export const NestedFloatNullableFilterSchema: z.ZodType<Prisma.NestedFloatNullableFilter> = z.object({
  equals: z.number().optional().nullable(),
  in: z.number().array().optional().nullable(),
  notIn: z.number().array().optional().nullable(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedFloatNullableFilterSchema) ]).optional().nullable(),
}).strict();

export const NestedBoolWithAggregatesFilterSchema: z.ZodType<Prisma.NestedBoolWithAggregatesFilter> = z.object({
  equals: z.boolean().optional(),
  not: z.union([ z.boolean(),z.lazy(() => NestedBoolWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedBoolFilterSchema).optional(),
  _max: z.lazy(() => NestedBoolFilterSchema).optional()
}).strict();

export const NestedFloatWithAggregatesFilterSchema: z.ZodType<Prisma.NestedFloatWithAggregatesFilter> = z.object({
  equals: z.number().optional(),
  in: z.number().array().optional(),
  notIn: z.number().array().optional(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedFloatWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _avg: z.lazy(() => NestedFloatFilterSchema).optional(),
  _sum: z.lazy(() => NestedFloatFilterSchema).optional(),
  _min: z.lazy(() => NestedFloatFilterSchema).optional(),
  _max: z.lazy(() => NestedFloatFilterSchema).optional()
}).strict();

export const NestedUuidNullableWithAggregatesFilterSchema: z.ZodType<Prisma.NestedUuidNullableWithAggregatesFilter> = z.object({
  equals: z.string().optional().nullable(),
  in: z.string().array().optional().nullable(),
  notIn: z.string().array().optional().nullable(),
  lt: z.string().optional(),
  lte: z.string().optional(),
  gt: z.string().optional(),
  gte: z.string().optional(),
  not: z.union([ z.string(),z.lazy(() => NestedUuidNullableWithAggregatesFilterSchema) ]).optional().nullable(),
  _count: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _min: z.lazy(() => NestedStringNullableFilterSchema).optional(),
  _max: z.lazy(() => NestedStringNullableFilterSchema).optional()
}).strict();

export const NestedBoolNullableFilterSchema: z.ZodType<Prisma.NestedBoolNullableFilter> = z.object({
  equals: z.boolean().optional().nullable(),
  not: z.union([ z.boolean(),z.lazy(() => NestedBoolNullableFilterSchema) ]).optional().nullable(),
}).strict();

export const NestedBoolNullableWithAggregatesFilterSchema: z.ZodType<Prisma.NestedBoolNullableWithAggregatesFilter> = z.object({
  equals: z.boolean().optional().nullable(),
  not: z.union([ z.boolean(),z.lazy(() => NestedBoolNullableWithAggregatesFilterSchema) ]).optional().nullable(),
  _count: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _min: z.lazy(() => NestedBoolNullableFilterSchema).optional(),
  _max: z.lazy(() => NestedBoolNullableFilterSchema).optional()
}).strict();

export const NestedUuidFilterSchema: z.ZodType<Prisma.NestedUuidFilter> = z.object({
  equals: z.string().optional(),
  in: z.string().array().optional(),
  notIn: z.string().array().optional(),
  lt: z.string().optional(),
  lte: z.string().optional(),
  gt: z.string().optional(),
  gte: z.string().optional(),
  not: z.union([ z.string(),z.lazy(() => NestedUuidFilterSchema) ]).optional(),
}).strict();

export const NestedUuidWithAggregatesFilterSchema: z.ZodType<Prisma.NestedUuidWithAggregatesFilter> = z.object({
  equals: z.string().optional(),
  in: z.string().array().optional(),
  notIn: z.string().array().optional(),
  lt: z.string().optional(),
  lte: z.string().optional(),
  gt: z.string().optional(),
  gte: z.string().optional(),
  not: z.union([ z.string(),z.lazy(() => NestedUuidWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedStringFilterSchema).optional(),
  _max: z.lazy(() => NestedStringFilterSchema).optional()
}).strict();

export const NestedDateTimeNullableFilterSchema: z.ZodType<Prisma.NestedDateTimeNullableFilter> = z.object({
  equals: z.coerce.date().optional().nullable(),
  in: z.coerce.date().array().optional().nullable(),
  notIn: z.coerce.date().array().optional().nullable(),
  lt: z.coerce.date().optional(),
  lte: z.coerce.date().optional(),
  gt: z.coerce.date().optional(),
  gte: z.coerce.date().optional(),
  not: z.union([ z.coerce.date(),z.lazy(() => NestedDateTimeNullableFilterSchema) ]).optional().nullable(),
}).strict();

export const NestedEnumRoleFilterSchema: z.ZodType<Prisma.NestedEnumRoleFilter> = z.object({
  equals: z.lazy(() => RoleSchema).optional(),
  in: z.lazy(() => RoleSchema).array().optional(),
  notIn: z.lazy(() => RoleSchema).array().optional(),
  not: z.union([ z.lazy(() => RoleSchema),z.lazy(() => NestedEnumRoleFilterSchema) ]).optional(),
}).strict();

export const NestedDateTimeNullableWithAggregatesFilterSchema: z.ZodType<Prisma.NestedDateTimeNullableWithAggregatesFilter> = z.object({
  equals: z.coerce.date().optional().nullable(),
  in: z.coerce.date().array().optional().nullable(),
  notIn: z.coerce.date().array().optional().nullable(),
  lt: z.coerce.date().optional(),
  lte: z.coerce.date().optional(),
  gt: z.coerce.date().optional(),
  gte: z.coerce.date().optional(),
  not: z.union([ z.coerce.date(),z.lazy(() => NestedDateTimeNullableWithAggregatesFilterSchema) ]).optional().nullable(),
  _count: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _min: z.lazy(() => NestedDateTimeNullableFilterSchema).optional(),
  _max: z.lazy(() => NestedDateTimeNullableFilterSchema).optional()
}).strict();

export const NestedEnumRoleWithAggregatesFilterSchema: z.ZodType<Prisma.NestedEnumRoleWithAggregatesFilter> = z.object({
  equals: z.lazy(() => RoleSchema).optional(),
  in: z.lazy(() => RoleSchema).array().optional(),
  notIn: z.lazy(() => RoleSchema).array().optional(),
  not: z.union([ z.lazy(() => RoleSchema),z.lazy(() => NestedEnumRoleWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumRoleFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumRoleFilterSchema).optional()
}).strict();

export const UserCreateWithoutWorldsInputSchema: z.ZodType<Prisma.UserCreateWithoutWorldsInput> = z.object({
  id: z.string().optional(),
  name: z.string().optional().nullable(),
  email: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  lastSeen: z.coerce.date().optional().nullable(),
  emailVerified: z.coerce.date().optional().nullable(),
  role: z.lazy(() => RoleSchema).optional(),
  image: z.string().optional().nullable(),
  accounts: z.lazy(() => AccountCreateNestedManyWithoutUserInputSchema).optional(),
  sessions: z.lazy(() => SessionCreateNestedManyWithoutUserInputSchema).optional(),
  slaps: z.lazy(() => SlapCreateNestedManyWithoutCreatorInputSchema).optional()
}).strict();

export const UserUncheckedCreateWithoutWorldsInputSchema: z.ZodType<Prisma.UserUncheckedCreateWithoutWorldsInput> = z.object({
  id: z.string().optional(),
  name: z.string().optional().nullable(),
  email: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  lastSeen: z.coerce.date().optional().nullable(),
  emailVerified: z.coerce.date().optional().nullable(),
  role: z.lazy(() => RoleSchema).optional(),
  image: z.string().optional().nullable(),
  accounts: z.lazy(() => AccountUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  sessions: z.lazy(() => SessionUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  slaps: z.lazy(() => SlapUncheckedCreateNestedManyWithoutCreatorInputSchema).optional()
}).strict();

export const UserCreateOrConnectWithoutWorldsInputSchema: z.ZodType<Prisma.UserCreateOrConnectWithoutWorldsInput> = z.object({
  where: z.lazy(() => UserWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => UserCreateWithoutWorldsInputSchema),z.lazy(() => UserUncheckedCreateWithoutWorldsInputSchema) ]),
}).strict();

export const SlapCreateWithoutWorldsInputSchema: z.ZodType<Prisma.SlapCreateWithoutWorldsInput> = z.object({
  id: z.string().optional(),
  slug: z.string().optional().nullable(),
  title: z.string(),
  bpm: z.number().int(),
  key: z.string(),
  currentBpm: z.number().int().optional().nullable(),
  currentKey: z.string().optional().nullable(),
  isRoot: z.boolean().optional(),
  isPremade: z.boolean().optional(),
  isDeleted: z.boolean().optional(),
  duration: z.number(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  skin: z.lazy(() => SkinCreateNestedOneWithoutSlapsInputSchema),
  creator: z.lazy(() => UserCreateNestedOneWithoutSlapsInputSchema).optional(),
  tags: z.lazy(() => TagCreateNestedManyWithoutSlapsInputSchema).optional(),
  tracks: z.lazy(() => TrackCreateNestedManyWithoutSlapInputSchema).optional(),
  parent: z.lazy(() => SlapCreateNestedOneWithoutChildrenInputSchema).optional(),
  root: z.lazy(() => SlapCreateNestedOneWithoutDescendantsInputSchema).optional(),
  children: z.lazy(() => SlapCreateNestedManyWithoutParentInputSchema).optional(),
  descendants: z.lazy(() => SlapCreateNestedManyWithoutRootInputSchema).optional()
}).strict();

export const SlapUncheckedCreateWithoutWorldsInputSchema: z.ZodType<Prisma.SlapUncheckedCreateWithoutWorldsInput> = z.object({
  id: z.string().optional(),
  slug: z.string().optional().nullable(),
  title: z.string(),
  bpm: z.number().int(),
  key: z.string(),
  currentBpm: z.number().int().optional().nullable(),
  currentKey: z.string().optional().nullable(),
  isRoot: z.boolean().optional(),
  isPremade: z.boolean().optional(),
  isDeleted: z.boolean().optional(),
  duration: z.number(),
  skinId: z.string(),
  parentId: z.string().optional().nullable(),
  rootId: z.string().optional().nullable(),
  creatorId: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  tags: z.lazy(() => TagUncheckedCreateNestedManyWithoutSlapsInputSchema).optional(),
  tracks: z.lazy(() => TrackUncheckedCreateNestedManyWithoutSlapInputSchema).optional(),
  children: z.lazy(() => SlapUncheckedCreateNestedManyWithoutParentInputSchema).optional(),
  descendants: z.lazy(() => SlapUncheckedCreateNestedManyWithoutRootInputSchema).optional()
}).strict();

export const SlapCreateOrConnectWithoutWorldsInputSchema: z.ZodType<Prisma.SlapCreateOrConnectWithoutWorldsInput> = z.object({
  where: z.lazy(() => SlapWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => SlapCreateWithoutWorldsInputSchema),z.lazy(() => SlapUncheckedCreateWithoutWorldsInputSchema) ]),
}).strict();

export const TagCreateWithoutWorldsInputSchema: z.ZodType<Prisma.TagCreateWithoutWorldsInput> = z.object({
  id: z.string().optional(),
  title: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  slaps: z.lazy(() => SlapCreateNestedManyWithoutTagsInputSchema).optional()
}).strict();

export const TagUncheckedCreateWithoutWorldsInputSchema: z.ZodType<Prisma.TagUncheckedCreateWithoutWorldsInput> = z.object({
  id: z.string().optional(),
  title: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  slaps: z.lazy(() => SlapUncheckedCreateNestedManyWithoutTagsInputSchema).optional()
}).strict();

export const TagCreateOrConnectWithoutWorldsInputSchema: z.ZodType<Prisma.TagCreateOrConnectWithoutWorldsInput> = z.object({
  where: z.lazy(() => TagWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => TagCreateWithoutWorldsInputSchema),z.lazy(() => TagUncheckedCreateWithoutWorldsInputSchema) ]),
}).strict();

export const UserUpsertWithWhereUniqueWithoutWorldsInputSchema: z.ZodType<Prisma.UserUpsertWithWhereUniqueWithoutWorldsInput> = z.object({
  where: z.lazy(() => UserWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => UserUpdateWithoutWorldsInputSchema),z.lazy(() => UserUncheckedUpdateWithoutWorldsInputSchema) ]),
  create: z.union([ z.lazy(() => UserCreateWithoutWorldsInputSchema),z.lazy(() => UserUncheckedCreateWithoutWorldsInputSchema) ]),
}).strict();

export const UserUpdateWithWhereUniqueWithoutWorldsInputSchema: z.ZodType<Prisma.UserUpdateWithWhereUniqueWithoutWorldsInput> = z.object({
  where: z.lazy(() => UserWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => UserUpdateWithoutWorldsInputSchema),z.lazy(() => UserUncheckedUpdateWithoutWorldsInputSchema) ]),
}).strict();

export const UserUpdateManyWithWhereWithoutWorldsInputSchema: z.ZodType<Prisma.UserUpdateManyWithWhereWithoutWorldsInput> = z.object({
  where: z.lazy(() => UserScalarWhereInputSchema),
  data: z.union([ z.lazy(() => UserUpdateManyMutationInputSchema),z.lazy(() => UserUncheckedUpdateManyWithoutWorldsInputSchema) ]),
}).strict();

export const UserScalarWhereInputSchema: z.ZodType<Prisma.UserScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => UserScalarWhereInputSchema),z.lazy(() => UserScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => UserScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => UserScalarWhereInputSchema),z.lazy(() => UserScalarWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => UuidFilterSchema),z.string() ]).optional(),
  name: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  email: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  lastSeen: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  emailVerified: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  role: z.union([ z.lazy(() => EnumRoleFilterSchema),z.lazy(() => RoleSchema) ]).optional(),
  image: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
}).strict();

export const SlapUpsertWithWhereUniqueWithoutWorldsInputSchema: z.ZodType<Prisma.SlapUpsertWithWhereUniqueWithoutWorldsInput> = z.object({
  where: z.lazy(() => SlapWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => SlapUpdateWithoutWorldsInputSchema),z.lazy(() => SlapUncheckedUpdateWithoutWorldsInputSchema) ]),
  create: z.union([ z.lazy(() => SlapCreateWithoutWorldsInputSchema),z.lazy(() => SlapUncheckedCreateWithoutWorldsInputSchema) ]),
}).strict();

export const SlapUpdateWithWhereUniqueWithoutWorldsInputSchema: z.ZodType<Prisma.SlapUpdateWithWhereUniqueWithoutWorldsInput> = z.object({
  where: z.lazy(() => SlapWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => SlapUpdateWithoutWorldsInputSchema),z.lazy(() => SlapUncheckedUpdateWithoutWorldsInputSchema) ]),
}).strict();

export const SlapUpdateManyWithWhereWithoutWorldsInputSchema: z.ZodType<Prisma.SlapUpdateManyWithWhereWithoutWorldsInput> = z.object({
  where: z.lazy(() => SlapScalarWhereInputSchema),
  data: z.union([ z.lazy(() => SlapUpdateManyMutationInputSchema),z.lazy(() => SlapUncheckedUpdateManyWithoutWorldsInputSchema) ]),
}).strict();

export const SlapScalarWhereInputSchema: z.ZodType<Prisma.SlapScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => SlapScalarWhereInputSchema),z.lazy(() => SlapScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => SlapScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => SlapScalarWhereInputSchema),z.lazy(() => SlapScalarWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  slug: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  title: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  bpm: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  key: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  currentBpm: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  currentKey: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  isRoot: z.union([ z.lazy(() => BoolFilterSchema),z.boolean() ]).optional(),
  isPremade: z.union([ z.lazy(() => BoolFilterSchema),z.boolean() ]).optional(),
  isDeleted: z.union([ z.lazy(() => BoolFilterSchema),z.boolean() ]).optional(),
  duration: z.union([ z.lazy(() => FloatFilterSchema),z.number() ]).optional(),
  skinId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  parentId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  rootId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  creatorId: z.union([ z.lazy(() => UuidNullableFilterSchema),z.string() ]).optional().nullable(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
}).strict();

export const TagUpsertWithWhereUniqueWithoutWorldsInputSchema: z.ZodType<Prisma.TagUpsertWithWhereUniqueWithoutWorldsInput> = z.object({
  where: z.lazy(() => TagWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => TagUpdateWithoutWorldsInputSchema),z.lazy(() => TagUncheckedUpdateWithoutWorldsInputSchema) ]),
  create: z.union([ z.lazy(() => TagCreateWithoutWorldsInputSchema),z.lazy(() => TagUncheckedCreateWithoutWorldsInputSchema) ]),
}).strict();

export const TagUpdateWithWhereUniqueWithoutWorldsInputSchema: z.ZodType<Prisma.TagUpdateWithWhereUniqueWithoutWorldsInput> = z.object({
  where: z.lazy(() => TagWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => TagUpdateWithoutWorldsInputSchema),z.lazy(() => TagUncheckedUpdateWithoutWorldsInputSchema) ]),
}).strict();

export const TagUpdateManyWithWhereWithoutWorldsInputSchema: z.ZodType<Prisma.TagUpdateManyWithWhereWithoutWorldsInput> = z.object({
  where: z.lazy(() => TagScalarWhereInputSchema),
  data: z.union([ z.lazy(() => TagUpdateManyMutationInputSchema),z.lazy(() => TagUncheckedUpdateManyWithoutWorldsInputSchema) ]),
}).strict();

export const TagScalarWhereInputSchema: z.ZodType<Prisma.TagScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => TagScalarWhereInputSchema),z.lazy(() => TagScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => TagScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => TagScalarWhereInputSchema),z.lazy(() => TagScalarWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  title: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
}).strict();

export const SlapCreateWithoutTagsInputSchema: z.ZodType<Prisma.SlapCreateWithoutTagsInput> = z.object({
  id: z.string().optional(),
  slug: z.string().optional().nullable(),
  title: z.string(),
  bpm: z.number().int(),
  key: z.string(),
  currentBpm: z.number().int().optional().nullable(),
  currentKey: z.string().optional().nullable(),
  isRoot: z.boolean().optional(),
  isPremade: z.boolean().optional(),
  isDeleted: z.boolean().optional(),
  duration: z.number(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  skin: z.lazy(() => SkinCreateNestedOneWithoutSlapsInputSchema),
  creator: z.lazy(() => UserCreateNestedOneWithoutSlapsInputSchema).optional(),
  worlds: z.lazy(() => WorldCreateNestedManyWithoutSlapsInputSchema).optional(),
  tracks: z.lazy(() => TrackCreateNestedManyWithoutSlapInputSchema).optional(),
  parent: z.lazy(() => SlapCreateNestedOneWithoutChildrenInputSchema).optional(),
  root: z.lazy(() => SlapCreateNestedOneWithoutDescendantsInputSchema).optional(),
  children: z.lazy(() => SlapCreateNestedManyWithoutParentInputSchema).optional(),
  descendants: z.lazy(() => SlapCreateNestedManyWithoutRootInputSchema).optional()
}).strict();

export const SlapUncheckedCreateWithoutTagsInputSchema: z.ZodType<Prisma.SlapUncheckedCreateWithoutTagsInput> = z.object({
  id: z.string().optional(),
  slug: z.string().optional().nullable(),
  title: z.string(),
  bpm: z.number().int(),
  key: z.string(),
  currentBpm: z.number().int().optional().nullable(),
  currentKey: z.string().optional().nullable(),
  isRoot: z.boolean().optional(),
  isPremade: z.boolean().optional(),
  isDeleted: z.boolean().optional(),
  duration: z.number(),
  skinId: z.string(),
  parentId: z.string().optional().nullable(),
  rootId: z.string().optional().nullable(),
  creatorId: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  worlds: z.lazy(() => WorldUncheckedCreateNestedManyWithoutSlapsInputSchema).optional(),
  tracks: z.lazy(() => TrackUncheckedCreateNestedManyWithoutSlapInputSchema).optional(),
  children: z.lazy(() => SlapUncheckedCreateNestedManyWithoutParentInputSchema).optional(),
  descendants: z.lazy(() => SlapUncheckedCreateNestedManyWithoutRootInputSchema).optional()
}).strict();

export const SlapCreateOrConnectWithoutTagsInputSchema: z.ZodType<Prisma.SlapCreateOrConnectWithoutTagsInput> = z.object({
  where: z.lazy(() => SlapWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => SlapCreateWithoutTagsInputSchema),z.lazy(() => SlapUncheckedCreateWithoutTagsInputSchema) ]),
}).strict();

export const WorldCreateWithoutTagsInputSchema: z.ZodType<Prisma.WorldCreateWithoutTagsInput> = z.object({
  id: z.string().optional(),
  title: z.string().optional(),
  video: z.string(),
  image: z.string(),
  description: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  users: z.lazy(() => UserCreateNestedManyWithoutWorldsInputSchema).optional(),
  slaps: z.lazy(() => SlapCreateNestedManyWithoutWorldsInputSchema).optional()
}).strict();

export const WorldUncheckedCreateWithoutTagsInputSchema: z.ZodType<Prisma.WorldUncheckedCreateWithoutTagsInput> = z.object({
  id: z.string().optional(),
  title: z.string().optional(),
  video: z.string(),
  image: z.string(),
  description: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  users: z.lazy(() => UserUncheckedCreateNestedManyWithoutWorldsInputSchema).optional(),
  slaps: z.lazy(() => SlapUncheckedCreateNestedManyWithoutWorldsInputSchema).optional()
}).strict();

export const WorldCreateOrConnectWithoutTagsInputSchema: z.ZodType<Prisma.WorldCreateOrConnectWithoutTagsInput> = z.object({
  where: z.lazy(() => WorldWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => WorldCreateWithoutTagsInputSchema),z.lazy(() => WorldUncheckedCreateWithoutTagsInputSchema) ]),
}).strict();

export const SlapUpsertWithWhereUniqueWithoutTagsInputSchema: z.ZodType<Prisma.SlapUpsertWithWhereUniqueWithoutTagsInput> = z.object({
  where: z.lazy(() => SlapWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => SlapUpdateWithoutTagsInputSchema),z.lazy(() => SlapUncheckedUpdateWithoutTagsInputSchema) ]),
  create: z.union([ z.lazy(() => SlapCreateWithoutTagsInputSchema),z.lazy(() => SlapUncheckedCreateWithoutTagsInputSchema) ]),
}).strict();

export const SlapUpdateWithWhereUniqueWithoutTagsInputSchema: z.ZodType<Prisma.SlapUpdateWithWhereUniqueWithoutTagsInput> = z.object({
  where: z.lazy(() => SlapWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => SlapUpdateWithoutTagsInputSchema),z.lazy(() => SlapUncheckedUpdateWithoutTagsInputSchema) ]),
}).strict();

export const SlapUpdateManyWithWhereWithoutTagsInputSchema: z.ZodType<Prisma.SlapUpdateManyWithWhereWithoutTagsInput> = z.object({
  where: z.lazy(() => SlapScalarWhereInputSchema),
  data: z.union([ z.lazy(() => SlapUpdateManyMutationInputSchema),z.lazy(() => SlapUncheckedUpdateManyWithoutTagsInputSchema) ]),
}).strict();

export const WorldUpsertWithWhereUniqueWithoutTagsInputSchema: z.ZodType<Prisma.WorldUpsertWithWhereUniqueWithoutTagsInput> = z.object({
  where: z.lazy(() => WorldWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => WorldUpdateWithoutTagsInputSchema),z.lazy(() => WorldUncheckedUpdateWithoutTagsInputSchema) ]),
  create: z.union([ z.lazy(() => WorldCreateWithoutTagsInputSchema),z.lazy(() => WorldUncheckedCreateWithoutTagsInputSchema) ]),
}).strict();

export const WorldUpdateWithWhereUniqueWithoutTagsInputSchema: z.ZodType<Prisma.WorldUpdateWithWhereUniqueWithoutTagsInput> = z.object({
  where: z.lazy(() => WorldWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => WorldUpdateWithoutTagsInputSchema),z.lazy(() => WorldUncheckedUpdateWithoutTagsInputSchema) ]),
}).strict();

export const WorldUpdateManyWithWhereWithoutTagsInputSchema: z.ZodType<Prisma.WorldUpdateManyWithWhereWithoutTagsInput> = z.object({
  where: z.lazy(() => WorldScalarWhereInputSchema),
  data: z.union([ z.lazy(() => WorldUpdateManyMutationInputSchema),z.lazy(() => WorldUncheckedUpdateManyWithoutTagsInputSchema) ]),
}).strict();

export const WorldScalarWhereInputSchema: z.ZodType<Prisma.WorldScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => WorldScalarWhereInputSchema),z.lazy(() => WorldScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => WorldScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => WorldScalarWhereInputSchema),z.lazy(() => WorldScalarWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  title: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  video: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  image: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  description: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
}).strict();

export const SkinCreateWithoutSlapsInputSchema: z.ZodType<Prisma.SkinCreateWithoutSlapsInput> = z.object({
  id: z.string().optional(),
  video: z.string(),
  image: z.string(),
  bgColor: z.string(),
  textColor: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const SkinUncheckedCreateWithoutSlapsInputSchema: z.ZodType<Prisma.SkinUncheckedCreateWithoutSlapsInput> = z.object({
  id: z.string().optional(),
  video: z.string(),
  image: z.string(),
  bgColor: z.string(),
  textColor: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const SkinCreateOrConnectWithoutSlapsInputSchema: z.ZodType<Prisma.SkinCreateOrConnectWithoutSlapsInput> = z.object({
  where: z.lazy(() => SkinWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => SkinCreateWithoutSlapsInputSchema),z.lazy(() => SkinUncheckedCreateWithoutSlapsInputSchema) ]),
}).strict();

export const UserCreateWithoutSlapsInputSchema: z.ZodType<Prisma.UserCreateWithoutSlapsInput> = z.object({
  id: z.string().optional(),
  name: z.string().optional().nullable(),
  email: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  lastSeen: z.coerce.date().optional().nullable(),
  emailVerified: z.coerce.date().optional().nullable(),
  role: z.lazy(() => RoleSchema).optional(),
  image: z.string().optional().nullable(),
  accounts: z.lazy(() => AccountCreateNestedManyWithoutUserInputSchema).optional(),
  sessions: z.lazy(() => SessionCreateNestedManyWithoutUserInputSchema).optional(),
  worlds: z.lazy(() => WorldCreateNestedManyWithoutUsersInputSchema).optional()
}).strict();

export const UserUncheckedCreateWithoutSlapsInputSchema: z.ZodType<Prisma.UserUncheckedCreateWithoutSlapsInput> = z.object({
  id: z.string().optional(),
  name: z.string().optional().nullable(),
  email: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  lastSeen: z.coerce.date().optional().nullable(),
  emailVerified: z.coerce.date().optional().nullable(),
  role: z.lazy(() => RoleSchema).optional(),
  image: z.string().optional().nullable(),
  accounts: z.lazy(() => AccountUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  sessions: z.lazy(() => SessionUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  worlds: z.lazy(() => WorldUncheckedCreateNestedManyWithoutUsersInputSchema).optional()
}).strict();

export const UserCreateOrConnectWithoutSlapsInputSchema: z.ZodType<Prisma.UserCreateOrConnectWithoutSlapsInput> = z.object({
  where: z.lazy(() => UserWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => UserCreateWithoutSlapsInputSchema),z.lazy(() => UserUncheckedCreateWithoutSlapsInputSchema) ]),
}).strict();

export const WorldCreateWithoutSlapsInputSchema: z.ZodType<Prisma.WorldCreateWithoutSlapsInput> = z.object({
  id: z.string().optional(),
  title: z.string().optional(),
  video: z.string(),
  image: z.string(),
  description: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  users: z.lazy(() => UserCreateNestedManyWithoutWorldsInputSchema).optional(),
  tags: z.lazy(() => TagCreateNestedManyWithoutWorldsInputSchema).optional()
}).strict();

export const WorldUncheckedCreateWithoutSlapsInputSchema: z.ZodType<Prisma.WorldUncheckedCreateWithoutSlapsInput> = z.object({
  id: z.string().optional(),
  title: z.string().optional(),
  video: z.string(),
  image: z.string(),
  description: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  users: z.lazy(() => UserUncheckedCreateNestedManyWithoutWorldsInputSchema).optional(),
  tags: z.lazy(() => TagUncheckedCreateNestedManyWithoutWorldsInputSchema).optional()
}).strict();

export const WorldCreateOrConnectWithoutSlapsInputSchema: z.ZodType<Prisma.WorldCreateOrConnectWithoutSlapsInput> = z.object({
  where: z.lazy(() => WorldWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => WorldCreateWithoutSlapsInputSchema),z.lazy(() => WorldUncheckedCreateWithoutSlapsInputSchema) ]),
}).strict();

export const TagCreateWithoutSlapsInputSchema: z.ZodType<Prisma.TagCreateWithoutSlapsInput> = z.object({
  id: z.string().optional(),
  title: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  worlds: z.lazy(() => WorldCreateNestedManyWithoutTagsInputSchema).optional()
}).strict();

export const TagUncheckedCreateWithoutSlapsInputSchema: z.ZodType<Prisma.TagUncheckedCreateWithoutSlapsInput> = z.object({
  id: z.string().optional(),
  title: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  worlds: z.lazy(() => WorldUncheckedCreateNestedManyWithoutTagsInputSchema).optional()
}).strict();

export const TagCreateOrConnectWithoutSlapsInputSchema: z.ZodType<Prisma.TagCreateOrConnectWithoutSlapsInput> = z.object({
  where: z.lazy(() => TagWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => TagCreateWithoutSlapsInputSchema),z.lazy(() => TagUncheckedCreateWithoutSlapsInputSchema) ]),
}).strict();

export const TrackCreateWithoutSlapInputSchema: z.ZodType<Prisma.TrackCreateWithoutSlapInput> = z.object({
  id: z.string().optional(),
  src: z.string(),
  title: z.string(),
  data: z.union([ z.lazy(() => TrackCreatedataInputSchema),InputJsonValueSchema.array() ]).optional(),
  isSoloed: z.boolean().optional().nullable(),
  isMuted: z.boolean().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const TrackUncheckedCreateWithoutSlapInputSchema: z.ZodType<Prisma.TrackUncheckedCreateWithoutSlapInput> = z.object({
  id: z.string().optional(),
  src: z.string(),
  title: z.string(),
  data: z.union([ z.lazy(() => TrackCreatedataInputSchema),InputJsonValueSchema.array() ]).optional(),
  isSoloed: z.boolean().optional().nullable(),
  isMuted: z.boolean().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const TrackCreateOrConnectWithoutSlapInputSchema: z.ZodType<Prisma.TrackCreateOrConnectWithoutSlapInput> = z.object({
  where: z.lazy(() => TrackWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => TrackCreateWithoutSlapInputSchema),z.lazy(() => TrackUncheckedCreateWithoutSlapInputSchema) ]),
}).strict();

export const TrackCreateManySlapInputEnvelopeSchema: z.ZodType<Prisma.TrackCreateManySlapInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => TrackCreateManySlapInputSchema),z.lazy(() => TrackCreateManySlapInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const SlapCreateWithoutChildrenInputSchema: z.ZodType<Prisma.SlapCreateWithoutChildrenInput> = z.object({
  id: z.string().optional(),
  slug: z.string().optional().nullable(),
  title: z.string(),
  bpm: z.number().int(),
  key: z.string(),
  currentBpm: z.number().int().optional().nullable(),
  currentKey: z.string().optional().nullable(),
  isRoot: z.boolean().optional(),
  isPremade: z.boolean().optional(),
  isDeleted: z.boolean().optional(),
  duration: z.number(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  skin: z.lazy(() => SkinCreateNestedOneWithoutSlapsInputSchema),
  creator: z.lazy(() => UserCreateNestedOneWithoutSlapsInputSchema).optional(),
  worlds: z.lazy(() => WorldCreateNestedManyWithoutSlapsInputSchema).optional(),
  tags: z.lazy(() => TagCreateNestedManyWithoutSlapsInputSchema).optional(),
  tracks: z.lazy(() => TrackCreateNestedManyWithoutSlapInputSchema).optional(),
  parent: z.lazy(() => SlapCreateNestedOneWithoutChildrenInputSchema).optional(),
  root: z.lazy(() => SlapCreateNestedOneWithoutDescendantsInputSchema).optional(),
  descendants: z.lazy(() => SlapCreateNestedManyWithoutRootInputSchema).optional()
}).strict();

export const SlapUncheckedCreateWithoutChildrenInputSchema: z.ZodType<Prisma.SlapUncheckedCreateWithoutChildrenInput> = z.object({
  id: z.string().optional(),
  slug: z.string().optional().nullable(),
  title: z.string(),
  bpm: z.number().int(),
  key: z.string(),
  currentBpm: z.number().int().optional().nullable(),
  currentKey: z.string().optional().nullable(),
  isRoot: z.boolean().optional(),
  isPremade: z.boolean().optional(),
  isDeleted: z.boolean().optional(),
  duration: z.number(),
  skinId: z.string(),
  parentId: z.string().optional().nullable(),
  rootId: z.string().optional().nullable(),
  creatorId: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  worlds: z.lazy(() => WorldUncheckedCreateNestedManyWithoutSlapsInputSchema).optional(),
  tags: z.lazy(() => TagUncheckedCreateNestedManyWithoutSlapsInputSchema).optional(),
  tracks: z.lazy(() => TrackUncheckedCreateNestedManyWithoutSlapInputSchema).optional(),
  descendants: z.lazy(() => SlapUncheckedCreateNestedManyWithoutRootInputSchema).optional()
}).strict();

export const SlapCreateOrConnectWithoutChildrenInputSchema: z.ZodType<Prisma.SlapCreateOrConnectWithoutChildrenInput> = z.object({
  where: z.lazy(() => SlapWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => SlapCreateWithoutChildrenInputSchema),z.lazy(() => SlapUncheckedCreateWithoutChildrenInputSchema) ]),
}).strict();

export const SlapCreateWithoutDescendantsInputSchema: z.ZodType<Prisma.SlapCreateWithoutDescendantsInput> = z.object({
  id: z.string().optional(),
  slug: z.string().optional().nullable(),
  title: z.string(),
  bpm: z.number().int(),
  key: z.string(),
  currentBpm: z.number().int().optional().nullable(),
  currentKey: z.string().optional().nullable(),
  isRoot: z.boolean().optional(),
  isPremade: z.boolean().optional(),
  isDeleted: z.boolean().optional(),
  duration: z.number(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  skin: z.lazy(() => SkinCreateNestedOneWithoutSlapsInputSchema),
  creator: z.lazy(() => UserCreateNestedOneWithoutSlapsInputSchema).optional(),
  worlds: z.lazy(() => WorldCreateNestedManyWithoutSlapsInputSchema).optional(),
  tags: z.lazy(() => TagCreateNestedManyWithoutSlapsInputSchema).optional(),
  tracks: z.lazy(() => TrackCreateNestedManyWithoutSlapInputSchema).optional(),
  parent: z.lazy(() => SlapCreateNestedOneWithoutChildrenInputSchema).optional(),
  root: z.lazy(() => SlapCreateNestedOneWithoutDescendantsInputSchema).optional(),
  children: z.lazy(() => SlapCreateNestedManyWithoutParentInputSchema).optional()
}).strict();

export const SlapUncheckedCreateWithoutDescendantsInputSchema: z.ZodType<Prisma.SlapUncheckedCreateWithoutDescendantsInput> = z.object({
  id: z.string().optional(),
  slug: z.string().optional().nullable(),
  title: z.string(),
  bpm: z.number().int(),
  key: z.string(),
  currentBpm: z.number().int().optional().nullable(),
  currentKey: z.string().optional().nullable(),
  isRoot: z.boolean().optional(),
  isPremade: z.boolean().optional(),
  isDeleted: z.boolean().optional(),
  duration: z.number(),
  skinId: z.string(),
  parentId: z.string().optional().nullable(),
  rootId: z.string().optional().nullable(),
  creatorId: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  worlds: z.lazy(() => WorldUncheckedCreateNestedManyWithoutSlapsInputSchema).optional(),
  tags: z.lazy(() => TagUncheckedCreateNestedManyWithoutSlapsInputSchema).optional(),
  tracks: z.lazy(() => TrackUncheckedCreateNestedManyWithoutSlapInputSchema).optional(),
  children: z.lazy(() => SlapUncheckedCreateNestedManyWithoutParentInputSchema).optional()
}).strict();

export const SlapCreateOrConnectWithoutDescendantsInputSchema: z.ZodType<Prisma.SlapCreateOrConnectWithoutDescendantsInput> = z.object({
  where: z.lazy(() => SlapWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => SlapCreateWithoutDescendantsInputSchema),z.lazy(() => SlapUncheckedCreateWithoutDescendantsInputSchema) ]),
}).strict();

export const SlapCreateWithoutParentInputSchema: z.ZodType<Prisma.SlapCreateWithoutParentInput> = z.object({
  id: z.string().optional(),
  slug: z.string().optional().nullable(),
  title: z.string(),
  bpm: z.number().int(),
  key: z.string(),
  currentBpm: z.number().int().optional().nullable(),
  currentKey: z.string().optional().nullable(),
  isRoot: z.boolean().optional(),
  isPremade: z.boolean().optional(),
  isDeleted: z.boolean().optional(),
  duration: z.number(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  skin: z.lazy(() => SkinCreateNestedOneWithoutSlapsInputSchema),
  creator: z.lazy(() => UserCreateNestedOneWithoutSlapsInputSchema).optional(),
  worlds: z.lazy(() => WorldCreateNestedManyWithoutSlapsInputSchema).optional(),
  tags: z.lazy(() => TagCreateNestedManyWithoutSlapsInputSchema).optional(),
  tracks: z.lazy(() => TrackCreateNestedManyWithoutSlapInputSchema).optional(),
  root: z.lazy(() => SlapCreateNestedOneWithoutDescendantsInputSchema).optional(),
  children: z.lazy(() => SlapCreateNestedManyWithoutParentInputSchema).optional(),
  descendants: z.lazy(() => SlapCreateNestedManyWithoutRootInputSchema).optional()
}).strict();

export const SlapUncheckedCreateWithoutParentInputSchema: z.ZodType<Prisma.SlapUncheckedCreateWithoutParentInput> = z.object({
  id: z.string().optional(),
  slug: z.string().optional().nullable(),
  title: z.string(),
  bpm: z.number().int(),
  key: z.string(),
  currentBpm: z.number().int().optional().nullable(),
  currentKey: z.string().optional().nullable(),
  isRoot: z.boolean().optional(),
  isPremade: z.boolean().optional(),
  isDeleted: z.boolean().optional(),
  duration: z.number(),
  skinId: z.string(),
  rootId: z.string().optional().nullable(),
  creatorId: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  worlds: z.lazy(() => WorldUncheckedCreateNestedManyWithoutSlapsInputSchema).optional(),
  tags: z.lazy(() => TagUncheckedCreateNestedManyWithoutSlapsInputSchema).optional(),
  tracks: z.lazy(() => TrackUncheckedCreateNestedManyWithoutSlapInputSchema).optional(),
  children: z.lazy(() => SlapUncheckedCreateNestedManyWithoutParentInputSchema).optional(),
  descendants: z.lazy(() => SlapUncheckedCreateNestedManyWithoutRootInputSchema).optional()
}).strict();

export const SlapCreateOrConnectWithoutParentInputSchema: z.ZodType<Prisma.SlapCreateOrConnectWithoutParentInput> = z.object({
  where: z.lazy(() => SlapWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => SlapCreateWithoutParentInputSchema),z.lazy(() => SlapUncheckedCreateWithoutParentInputSchema) ]),
}).strict();

export const SlapCreateManyParentInputEnvelopeSchema: z.ZodType<Prisma.SlapCreateManyParentInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => SlapCreateManyParentInputSchema),z.lazy(() => SlapCreateManyParentInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const SlapCreateWithoutRootInputSchema: z.ZodType<Prisma.SlapCreateWithoutRootInput> = z.object({
  id: z.string().optional(),
  slug: z.string().optional().nullable(),
  title: z.string(),
  bpm: z.number().int(),
  key: z.string(),
  currentBpm: z.number().int().optional().nullable(),
  currentKey: z.string().optional().nullable(),
  isRoot: z.boolean().optional(),
  isPremade: z.boolean().optional(),
  isDeleted: z.boolean().optional(),
  duration: z.number(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  skin: z.lazy(() => SkinCreateNestedOneWithoutSlapsInputSchema),
  creator: z.lazy(() => UserCreateNestedOneWithoutSlapsInputSchema).optional(),
  worlds: z.lazy(() => WorldCreateNestedManyWithoutSlapsInputSchema).optional(),
  tags: z.lazy(() => TagCreateNestedManyWithoutSlapsInputSchema).optional(),
  tracks: z.lazy(() => TrackCreateNestedManyWithoutSlapInputSchema).optional(),
  parent: z.lazy(() => SlapCreateNestedOneWithoutChildrenInputSchema).optional(),
  children: z.lazy(() => SlapCreateNestedManyWithoutParentInputSchema).optional(),
  descendants: z.lazy(() => SlapCreateNestedManyWithoutRootInputSchema).optional()
}).strict();

export const SlapUncheckedCreateWithoutRootInputSchema: z.ZodType<Prisma.SlapUncheckedCreateWithoutRootInput> = z.object({
  id: z.string().optional(),
  slug: z.string().optional().nullable(),
  title: z.string(),
  bpm: z.number().int(),
  key: z.string(),
  currentBpm: z.number().int().optional().nullable(),
  currentKey: z.string().optional().nullable(),
  isRoot: z.boolean().optional(),
  isPremade: z.boolean().optional(),
  isDeleted: z.boolean().optional(),
  duration: z.number(),
  skinId: z.string(),
  parentId: z.string().optional().nullable(),
  creatorId: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  worlds: z.lazy(() => WorldUncheckedCreateNestedManyWithoutSlapsInputSchema).optional(),
  tags: z.lazy(() => TagUncheckedCreateNestedManyWithoutSlapsInputSchema).optional(),
  tracks: z.lazy(() => TrackUncheckedCreateNestedManyWithoutSlapInputSchema).optional(),
  children: z.lazy(() => SlapUncheckedCreateNestedManyWithoutParentInputSchema).optional(),
  descendants: z.lazy(() => SlapUncheckedCreateNestedManyWithoutRootInputSchema).optional()
}).strict();

export const SlapCreateOrConnectWithoutRootInputSchema: z.ZodType<Prisma.SlapCreateOrConnectWithoutRootInput> = z.object({
  where: z.lazy(() => SlapWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => SlapCreateWithoutRootInputSchema),z.lazy(() => SlapUncheckedCreateWithoutRootInputSchema) ]),
}).strict();

export const SlapCreateManyRootInputEnvelopeSchema: z.ZodType<Prisma.SlapCreateManyRootInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => SlapCreateManyRootInputSchema),z.lazy(() => SlapCreateManyRootInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const SkinUpsertWithoutSlapsInputSchema: z.ZodType<Prisma.SkinUpsertWithoutSlapsInput> = z.object({
  update: z.union([ z.lazy(() => SkinUpdateWithoutSlapsInputSchema),z.lazy(() => SkinUncheckedUpdateWithoutSlapsInputSchema) ]),
  create: z.union([ z.lazy(() => SkinCreateWithoutSlapsInputSchema),z.lazy(() => SkinUncheckedCreateWithoutSlapsInputSchema) ]),
  where: z.lazy(() => SkinWhereInputSchema).optional()
}).strict();

export const SkinUpdateToOneWithWhereWithoutSlapsInputSchema: z.ZodType<Prisma.SkinUpdateToOneWithWhereWithoutSlapsInput> = z.object({
  where: z.lazy(() => SkinWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => SkinUpdateWithoutSlapsInputSchema),z.lazy(() => SkinUncheckedUpdateWithoutSlapsInputSchema) ]),
}).strict();

export const SkinUpdateWithoutSlapsInputSchema: z.ZodType<Prisma.SkinUpdateWithoutSlapsInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  video: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  image: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  bgColor: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  textColor: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const SkinUncheckedUpdateWithoutSlapsInputSchema: z.ZodType<Prisma.SkinUncheckedUpdateWithoutSlapsInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  video: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  image: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  bgColor: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  textColor: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const UserUpsertWithoutSlapsInputSchema: z.ZodType<Prisma.UserUpsertWithoutSlapsInput> = z.object({
  update: z.union([ z.lazy(() => UserUpdateWithoutSlapsInputSchema),z.lazy(() => UserUncheckedUpdateWithoutSlapsInputSchema) ]),
  create: z.union([ z.lazy(() => UserCreateWithoutSlapsInputSchema),z.lazy(() => UserUncheckedCreateWithoutSlapsInputSchema) ]),
  where: z.lazy(() => UserWhereInputSchema).optional()
}).strict();

export const UserUpdateToOneWithWhereWithoutSlapsInputSchema: z.ZodType<Prisma.UserUpdateToOneWithWhereWithoutSlapsInput> = z.object({
  where: z.lazy(() => UserWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => UserUpdateWithoutSlapsInputSchema),z.lazy(() => UserUncheckedUpdateWithoutSlapsInputSchema) ]),
}).strict();

export const UserUpdateWithoutSlapsInputSchema: z.ZodType<Prisma.UserUpdateWithoutSlapsInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  lastSeen: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerified: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  role: z.union([ z.lazy(() => RoleSchema),z.lazy(() => EnumRoleFieldUpdateOperationsInputSchema) ]).optional(),
  image: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  accounts: z.lazy(() => AccountUpdateManyWithoutUserNestedInputSchema).optional(),
  sessions: z.lazy(() => SessionUpdateManyWithoutUserNestedInputSchema).optional(),
  worlds: z.lazy(() => WorldUpdateManyWithoutUsersNestedInputSchema).optional()
}).strict();

export const UserUncheckedUpdateWithoutSlapsInputSchema: z.ZodType<Prisma.UserUncheckedUpdateWithoutSlapsInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  lastSeen: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerified: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  role: z.union([ z.lazy(() => RoleSchema),z.lazy(() => EnumRoleFieldUpdateOperationsInputSchema) ]).optional(),
  image: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  accounts: z.lazy(() => AccountUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  sessions: z.lazy(() => SessionUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  worlds: z.lazy(() => WorldUncheckedUpdateManyWithoutUsersNestedInputSchema).optional()
}).strict();

export const WorldUpsertWithWhereUniqueWithoutSlapsInputSchema: z.ZodType<Prisma.WorldUpsertWithWhereUniqueWithoutSlapsInput> = z.object({
  where: z.lazy(() => WorldWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => WorldUpdateWithoutSlapsInputSchema),z.lazy(() => WorldUncheckedUpdateWithoutSlapsInputSchema) ]),
  create: z.union([ z.lazy(() => WorldCreateWithoutSlapsInputSchema),z.lazy(() => WorldUncheckedCreateWithoutSlapsInputSchema) ]),
}).strict();

export const WorldUpdateWithWhereUniqueWithoutSlapsInputSchema: z.ZodType<Prisma.WorldUpdateWithWhereUniqueWithoutSlapsInput> = z.object({
  where: z.lazy(() => WorldWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => WorldUpdateWithoutSlapsInputSchema),z.lazy(() => WorldUncheckedUpdateWithoutSlapsInputSchema) ]),
}).strict();

export const WorldUpdateManyWithWhereWithoutSlapsInputSchema: z.ZodType<Prisma.WorldUpdateManyWithWhereWithoutSlapsInput> = z.object({
  where: z.lazy(() => WorldScalarWhereInputSchema),
  data: z.union([ z.lazy(() => WorldUpdateManyMutationInputSchema),z.lazy(() => WorldUncheckedUpdateManyWithoutSlapsInputSchema) ]),
}).strict();

export const TagUpsertWithWhereUniqueWithoutSlapsInputSchema: z.ZodType<Prisma.TagUpsertWithWhereUniqueWithoutSlapsInput> = z.object({
  where: z.lazy(() => TagWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => TagUpdateWithoutSlapsInputSchema),z.lazy(() => TagUncheckedUpdateWithoutSlapsInputSchema) ]),
  create: z.union([ z.lazy(() => TagCreateWithoutSlapsInputSchema),z.lazy(() => TagUncheckedCreateWithoutSlapsInputSchema) ]),
}).strict();

export const TagUpdateWithWhereUniqueWithoutSlapsInputSchema: z.ZodType<Prisma.TagUpdateWithWhereUniqueWithoutSlapsInput> = z.object({
  where: z.lazy(() => TagWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => TagUpdateWithoutSlapsInputSchema),z.lazy(() => TagUncheckedUpdateWithoutSlapsInputSchema) ]),
}).strict();

export const TagUpdateManyWithWhereWithoutSlapsInputSchema: z.ZodType<Prisma.TagUpdateManyWithWhereWithoutSlapsInput> = z.object({
  where: z.lazy(() => TagScalarWhereInputSchema),
  data: z.union([ z.lazy(() => TagUpdateManyMutationInputSchema),z.lazy(() => TagUncheckedUpdateManyWithoutSlapsInputSchema) ]),
}).strict();

export const TrackUpsertWithWhereUniqueWithoutSlapInputSchema: z.ZodType<Prisma.TrackUpsertWithWhereUniqueWithoutSlapInput> = z.object({
  where: z.lazy(() => TrackWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => TrackUpdateWithoutSlapInputSchema),z.lazy(() => TrackUncheckedUpdateWithoutSlapInputSchema) ]),
  create: z.union([ z.lazy(() => TrackCreateWithoutSlapInputSchema),z.lazy(() => TrackUncheckedCreateWithoutSlapInputSchema) ]),
}).strict();

export const TrackUpdateWithWhereUniqueWithoutSlapInputSchema: z.ZodType<Prisma.TrackUpdateWithWhereUniqueWithoutSlapInput> = z.object({
  where: z.lazy(() => TrackWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => TrackUpdateWithoutSlapInputSchema),z.lazy(() => TrackUncheckedUpdateWithoutSlapInputSchema) ]),
}).strict();

export const TrackUpdateManyWithWhereWithoutSlapInputSchema: z.ZodType<Prisma.TrackUpdateManyWithWhereWithoutSlapInput> = z.object({
  where: z.lazy(() => TrackScalarWhereInputSchema),
  data: z.union([ z.lazy(() => TrackUpdateManyMutationInputSchema),z.lazy(() => TrackUncheckedUpdateManyWithoutSlapInputSchema) ]),
}).strict();

export const TrackScalarWhereInputSchema: z.ZodType<Prisma.TrackScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => TrackScalarWhereInputSchema),z.lazy(() => TrackScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => TrackScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => TrackScalarWhereInputSchema),z.lazy(() => TrackScalarWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  src: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  title: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  data: z.lazy(() => JsonNullableListFilterSchema).optional(),
  isSoloed: z.union([ z.lazy(() => BoolNullableFilterSchema),z.boolean() ]).optional().nullable(),
  isMuted: z.union([ z.lazy(() => BoolNullableFilterSchema),z.boolean() ]).optional().nullable(),
  slapId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
}).strict();

export const SlapUpsertWithoutChildrenInputSchema: z.ZodType<Prisma.SlapUpsertWithoutChildrenInput> = z.object({
  update: z.union([ z.lazy(() => SlapUpdateWithoutChildrenInputSchema),z.lazy(() => SlapUncheckedUpdateWithoutChildrenInputSchema) ]),
  create: z.union([ z.lazy(() => SlapCreateWithoutChildrenInputSchema),z.lazy(() => SlapUncheckedCreateWithoutChildrenInputSchema) ]),
  where: z.lazy(() => SlapWhereInputSchema).optional()
}).strict();

export const SlapUpdateToOneWithWhereWithoutChildrenInputSchema: z.ZodType<Prisma.SlapUpdateToOneWithWhereWithoutChildrenInput> = z.object({
  where: z.lazy(() => SlapWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => SlapUpdateWithoutChildrenInputSchema),z.lazy(() => SlapUncheckedUpdateWithoutChildrenInputSchema) ]),
}).strict();

export const SlapUpdateWithoutChildrenInputSchema: z.ZodType<Prisma.SlapUpdateWithoutChildrenInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  slug: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  title: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  bpm: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  key: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  currentBpm: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currentKey: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  isRoot: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  isPremade: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  isDeleted: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  duration: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  skin: z.lazy(() => SkinUpdateOneRequiredWithoutSlapsNestedInputSchema).optional(),
  creator: z.lazy(() => UserUpdateOneWithoutSlapsNestedInputSchema).optional(),
  worlds: z.lazy(() => WorldUpdateManyWithoutSlapsNestedInputSchema).optional(),
  tags: z.lazy(() => TagUpdateManyWithoutSlapsNestedInputSchema).optional(),
  tracks: z.lazy(() => TrackUpdateManyWithoutSlapNestedInputSchema).optional(),
  parent: z.lazy(() => SlapUpdateOneWithoutChildrenNestedInputSchema).optional(),
  root: z.lazy(() => SlapUpdateOneWithoutDescendantsNestedInputSchema).optional(),
  descendants: z.lazy(() => SlapUpdateManyWithoutRootNestedInputSchema).optional()
}).strict();

export const SlapUncheckedUpdateWithoutChildrenInputSchema: z.ZodType<Prisma.SlapUncheckedUpdateWithoutChildrenInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  slug: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  title: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  bpm: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  key: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  currentBpm: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currentKey: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  isRoot: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  isPremade: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  isDeleted: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  duration: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  skinId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  parentId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  rootId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  creatorId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  worlds: z.lazy(() => WorldUncheckedUpdateManyWithoutSlapsNestedInputSchema).optional(),
  tags: z.lazy(() => TagUncheckedUpdateManyWithoutSlapsNestedInputSchema).optional(),
  tracks: z.lazy(() => TrackUncheckedUpdateManyWithoutSlapNestedInputSchema).optional(),
  descendants: z.lazy(() => SlapUncheckedUpdateManyWithoutRootNestedInputSchema).optional()
}).strict();

export const SlapUpsertWithoutDescendantsInputSchema: z.ZodType<Prisma.SlapUpsertWithoutDescendantsInput> = z.object({
  update: z.union([ z.lazy(() => SlapUpdateWithoutDescendantsInputSchema),z.lazy(() => SlapUncheckedUpdateWithoutDescendantsInputSchema) ]),
  create: z.union([ z.lazy(() => SlapCreateWithoutDescendantsInputSchema),z.lazy(() => SlapUncheckedCreateWithoutDescendantsInputSchema) ]),
  where: z.lazy(() => SlapWhereInputSchema).optional()
}).strict();

export const SlapUpdateToOneWithWhereWithoutDescendantsInputSchema: z.ZodType<Prisma.SlapUpdateToOneWithWhereWithoutDescendantsInput> = z.object({
  where: z.lazy(() => SlapWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => SlapUpdateWithoutDescendantsInputSchema),z.lazy(() => SlapUncheckedUpdateWithoutDescendantsInputSchema) ]),
}).strict();

export const SlapUpdateWithoutDescendantsInputSchema: z.ZodType<Prisma.SlapUpdateWithoutDescendantsInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  slug: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  title: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  bpm: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  key: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  currentBpm: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currentKey: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  isRoot: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  isPremade: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  isDeleted: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  duration: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  skin: z.lazy(() => SkinUpdateOneRequiredWithoutSlapsNestedInputSchema).optional(),
  creator: z.lazy(() => UserUpdateOneWithoutSlapsNestedInputSchema).optional(),
  worlds: z.lazy(() => WorldUpdateManyWithoutSlapsNestedInputSchema).optional(),
  tags: z.lazy(() => TagUpdateManyWithoutSlapsNestedInputSchema).optional(),
  tracks: z.lazy(() => TrackUpdateManyWithoutSlapNestedInputSchema).optional(),
  parent: z.lazy(() => SlapUpdateOneWithoutChildrenNestedInputSchema).optional(),
  root: z.lazy(() => SlapUpdateOneWithoutDescendantsNestedInputSchema).optional(),
  children: z.lazy(() => SlapUpdateManyWithoutParentNestedInputSchema).optional()
}).strict();

export const SlapUncheckedUpdateWithoutDescendantsInputSchema: z.ZodType<Prisma.SlapUncheckedUpdateWithoutDescendantsInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  slug: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  title: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  bpm: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  key: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  currentBpm: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currentKey: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  isRoot: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  isPremade: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  isDeleted: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  duration: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  skinId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  parentId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  rootId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  creatorId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  worlds: z.lazy(() => WorldUncheckedUpdateManyWithoutSlapsNestedInputSchema).optional(),
  tags: z.lazy(() => TagUncheckedUpdateManyWithoutSlapsNestedInputSchema).optional(),
  tracks: z.lazy(() => TrackUncheckedUpdateManyWithoutSlapNestedInputSchema).optional(),
  children: z.lazy(() => SlapUncheckedUpdateManyWithoutParentNestedInputSchema).optional()
}).strict();

export const SlapUpsertWithWhereUniqueWithoutParentInputSchema: z.ZodType<Prisma.SlapUpsertWithWhereUniqueWithoutParentInput> = z.object({
  where: z.lazy(() => SlapWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => SlapUpdateWithoutParentInputSchema),z.lazy(() => SlapUncheckedUpdateWithoutParentInputSchema) ]),
  create: z.union([ z.lazy(() => SlapCreateWithoutParentInputSchema),z.lazy(() => SlapUncheckedCreateWithoutParentInputSchema) ]),
}).strict();

export const SlapUpdateWithWhereUniqueWithoutParentInputSchema: z.ZodType<Prisma.SlapUpdateWithWhereUniqueWithoutParentInput> = z.object({
  where: z.lazy(() => SlapWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => SlapUpdateWithoutParentInputSchema),z.lazy(() => SlapUncheckedUpdateWithoutParentInputSchema) ]),
}).strict();

export const SlapUpdateManyWithWhereWithoutParentInputSchema: z.ZodType<Prisma.SlapUpdateManyWithWhereWithoutParentInput> = z.object({
  where: z.lazy(() => SlapScalarWhereInputSchema),
  data: z.union([ z.lazy(() => SlapUpdateManyMutationInputSchema),z.lazy(() => SlapUncheckedUpdateManyWithoutParentInputSchema) ]),
}).strict();

export const SlapUpsertWithWhereUniqueWithoutRootInputSchema: z.ZodType<Prisma.SlapUpsertWithWhereUniqueWithoutRootInput> = z.object({
  where: z.lazy(() => SlapWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => SlapUpdateWithoutRootInputSchema),z.lazy(() => SlapUncheckedUpdateWithoutRootInputSchema) ]),
  create: z.union([ z.lazy(() => SlapCreateWithoutRootInputSchema),z.lazy(() => SlapUncheckedCreateWithoutRootInputSchema) ]),
}).strict();

export const SlapUpdateWithWhereUniqueWithoutRootInputSchema: z.ZodType<Prisma.SlapUpdateWithWhereUniqueWithoutRootInput> = z.object({
  where: z.lazy(() => SlapWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => SlapUpdateWithoutRootInputSchema),z.lazy(() => SlapUncheckedUpdateWithoutRootInputSchema) ]),
}).strict();

export const SlapUpdateManyWithWhereWithoutRootInputSchema: z.ZodType<Prisma.SlapUpdateManyWithWhereWithoutRootInput> = z.object({
  where: z.lazy(() => SlapScalarWhereInputSchema),
  data: z.union([ z.lazy(() => SlapUpdateManyMutationInputSchema),z.lazy(() => SlapUncheckedUpdateManyWithoutRootInputSchema) ]),
}).strict();

export const SlapCreateWithoutSkinInputSchema: z.ZodType<Prisma.SlapCreateWithoutSkinInput> = z.object({
  id: z.string().optional(),
  slug: z.string().optional().nullable(),
  title: z.string(),
  bpm: z.number().int(),
  key: z.string(),
  currentBpm: z.number().int().optional().nullable(),
  currentKey: z.string().optional().nullable(),
  isRoot: z.boolean().optional(),
  isPremade: z.boolean().optional(),
  isDeleted: z.boolean().optional(),
  duration: z.number(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  creator: z.lazy(() => UserCreateNestedOneWithoutSlapsInputSchema).optional(),
  worlds: z.lazy(() => WorldCreateNestedManyWithoutSlapsInputSchema).optional(),
  tags: z.lazy(() => TagCreateNestedManyWithoutSlapsInputSchema).optional(),
  tracks: z.lazy(() => TrackCreateNestedManyWithoutSlapInputSchema).optional(),
  parent: z.lazy(() => SlapCreateNestedOneWithoutChildrenInputSchema).optional(),
  root: z.lazy(() => SlapCreateNestedOneWithoutDescendantsInputSchema).optional(),
  children: z.lazy(() => SlapCreateNestedManyWithoutParentInputSchema).optional(),
  descendants: z.lazy(() => SlapCreateNestedManyWithoutRootInputSchema).optional()
}).strict();

export const SlapUncheckedCreateWithoutSkinInputSchema: z.ZodType<Prisma.SlapUncheckedCreateWithoutSkinInput> = z.object({
  id: z.string().optional(),
  slug: z.string().optional().nullable(),
  title: z.string(),
  bpm: z.number().int(),
  key: z.string(),
  currentBpm: z.number().int().optional().nullable(),
  currentKey: z.string().optional().nullable(),
  isRoot: z.boolean().optional(),
  isPremade: z.boolean().optional(),
  isDeleted: z.boolean().optional(),
  duration: z.number(),
  parentId: z.string().optional().nullable(),
  rootId: z.string().optional().nullable(),
  creatorId: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  worlds: z.lazy(() => WorldUncheckedCreateNestedManyWithoutSlapsInputSchema).optional(),
  tags: z.lazy(() => TagUncheckedCreateNestedManyWithoutSlapsInputSchema).optional(),
  tracks: z.lazy(() => TrackUncheckedCreateNestedManyWithoutSlapInputSchema).optional(),
  children: z.lazy(() => SlapUncheckedCreateNestedManyWithoutParentInputSchema).optional(),
  descendants: z.lazy(() => SlapUncheckedCreateNestedManyWithoutRootInputSchema).optional()
}).strict();

export const SlapCreateOrConnectWithoutSkinInputSchema: z.ZodType<Prisma.SlapCreateOrConnectWithoutSkinInput> = z.object({
  where: z.lazy(() => SlapWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => SlapCreateWithoutSkinInputSchema),z.lazy(() => SlapUncheckedCreateWithoutSkinInputSchema) ]),
}).strict();

export const SlapCreateManySkinInputEnvelopeSchema: z.ZodType<Prisma.SlapCreateManySkinInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => SlapCreateManySkinInputSchema),z.lazy(() => SlapCreateManySkinInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const SlapUpsertWithWhereUniqueWithoutSkinInputSchema: z.ZodType<Prisma.SlapUpsertWithWhereUniqueWithoutSkinInput> = z.object({
  where: z.lazy(() => SlapWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => SlapUpdateWithoutSkinInputSchema),z.lazy(() => SlapUncheckedUpdateWithoutSkinInputSchema) ]),
  create: z.union([ z.lazy(() => SlapCreateWithoutSkinInputSchema),z.lazy(() => SlapUncheckedCreateWithoutSkinInputSchema) ]),
}).strict();

export const SlapUpdateWithWhereUniqueWithoutSkinInputSchema: z.ZodType<Prisma.SlapUpdateWithWhereUniqueWithoutSkinInput> = z.object({
  where: z.lazy(() => SlapWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => SlapUpdateWithoutSkinInputSchema),z.lazy(() => SlapUncheckedUpdateWithoutSkinInputSchema) ]),
}).strict();

export const SlapUpdateManyWithWhereWithoutSkinInputSchema: z.ZodType<Prisma.SlapUpdateManyWithWhereWithoutSkinInput> = z.object({
  where: z.lazy(() => SlapScalarWhereInputSchema),
  data: z.union([ z.lazy(() => SlapUpdateManyMutationInputSchema),z.lazy(() => SlapUncheckedUpdateManyWithoutSkinInputSchema) ]),
}).strict();

export const SlapCreateWithoutTracksInputSchema: z.ZodType<Prisma.SlapCreateWithoutTracksInput> = z.object({
  id: z.string().optional(),
  slug: z.string().optional().nullable(),
  title: z.string(),
  bpm: z.number().int(),
  key: z.string(),
  currentBpm: z.number().int().optional().nullable(),
  currentKey: z.string().optional().nullable(),
  isRoot: z.boolean().optional(),
  isPremade: z.boolean().optional(),
  isDeleted: z.boolean().optional(),
  duration: z.number(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  skin: z.lazy(() => SkinCreateNestedOneWithoutSlapsInputSchema),
  creator: z.lazy(() => UserCreateNestedOneWithoutSlapsInputSchema).optional(),
  worlds: z.lazy(() => WorldCreateNestedManyWithoutSlapsInputSchema).optional(),
  tags: z.lazy(() => TagCreateNestedManyWithoutSlapsInputSchema).optional(),
  parent: z.lazy(() => SlapCreateNestedOneWithoutChildrenInputSchema).optional(),
  root: z.lazy(() => SlapCreateNestedOneWithoutDescendantsInputSchema).optional(),
  children: z.lazy(() => SlapCreateNestedManyWithoutParentInputSchema).optional(),
  descendants: z.lazy(() => SlapCreateNestedManyWithoutRootInputSchema).optional()
}).strict();

export const SlapUncheckedCreateWithoutTracksInputSchema: z.ZodType<Prisma.SlapUncheckedCreateWithoutTracksInput> = z.object({
  id: z.string().optional(),
  slug: z.string().optional().nullable(),
  title: z.string(),
  bpm: z.number().int(),
  key: z.string(),
  currentBpm: z.number().int().optional().nullable(),
  currentKey: z.string().optional().nullable(),
  isRoot: z.boolean().optional(),
  isPremade: z.boolean().optional(),
  isDeleted: z.boolean().optional(),
  duration: z.number(),
  skinId: z.string(),
  parentId: z.string().optional().nullable(),
  rootId: z.string().optional().nullable(),
  creatorId: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  worlds: z.lazy(() => WorldUncheckedCreateNestedManyWithoutSlapsInputSchema).optional(),
  tags: z.lazy(() => TagUncheckedCreateNestedManyWithoutSlapsInputSchema).optional(),
  children: z.lazy(() => SlapUncheckedCreateNestedManyWithoutParentInputSchema).optional(),
  descendants: z.lazy(() => SlapUncheckedCreateNestedManyWithoutRootInputSchema).optional()
}).strict();

export const SlapCreateOrConnectWithoutTracksInputSchema: z.ZodType<Prisma.SlapCreateOrConnectWithoutTracksInput> = z.object({
  where: z.lazy(() => SlapWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => SlapCreateWithoutTracksInputSchema),z.lazy(() => SlapUncheckedCreateWithoutTracksInputSchema) ]),
}).strict();

export const SlapUpsertWithoutTracksInputSchema: z.ZodType<Prisma.SlapUpsertWithoutTracksInput> = z.object({
  update: z.union([ z.lazy(() => SlapUpdateWithoutTracksInputSchema),z.lazy(() => SlapUncheckedUpdateWithoutTracksInputSchema) ]),
  create: z.union([ z.lazy(() => SlapCreateWithoutTracksInputSchema),z.lazy(() => SlapUncheckedCreateWithoutTracksInputSchema) ]),
  where: z.lazy(() => SlapWhereInputSchema).optional()
}).strict();

export const SlapUpdateToOneWithWhereWithoutTracksInputSchema: z.ZodType<Prisma.SlapUpdateToOneWithWhereWithoutTracksInput> = z.object({
  where: z.lazy(() => SlapWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => SlapUpdateWithoutTracksInputSchema),z.lazy(() => SlapUncheckedUpdateWithoutTracksInputSchema) ]),
}).strict();

export const SlapUpdateWithoutTracksInputSchema: z.ZodType<Prisma.SlapUpdateWithoutTracksInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  slug: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  title: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  bpm: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  key: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  currentBpm: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currentKey: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  isRoot: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  isPremade: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  isDeleted: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  duration: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  skin: z.lazy(() => SkinUpdateOneRequiredWithoutSlapsNestedInputSchema).optional(),
  creator: z.lazy(() => UserUpdateOneWithoutSlapsNestedInputSchema).optional(),
  worlds: z.lazy(() => WorldUpdateManyWithoutSlapsNestedInputSchema).optional(),
  tags: z.lazy(() => TagUpdateManyWithoutSlapsNestedInputSchema).optional(),
  parent: z.lazy(() => SlapUpdateOneWithoutChildrenNestedInputSchema).optional(),
  root: z.lazy(() => SlapUpdateOneWithoutDescendantsNestedInputSchema).optional(),
  children: z.lazy(() => SlapUpdateManyWithoutParentNestedInputSchema).optional(),
  descendants: z.lazy(() => SlapUpdateManyWithoutRootNestedInputSchema).optional()
}).strict();

export const SlapUncheckedUpdateWithoutTracksInputSchema: z.ZodType<Prisma.SlapUncheckedUpdateWithoutTracksInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  slug: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  title: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  bpm: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  key: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  currentBpm: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currentKey: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  isRoot: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  isPremade: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  isDeleted: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  duration: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  skinId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  parentId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  rootId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  creatorId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  worlds: z.lazy(() => WorldUncheckedUpdateManyWithoutSlapsNestedInputSchema).optional(),
  tags: z.lazy(() => TagUncheckedUpdateManyWithoutSlapsNestedInputSchema).optional(),
  children: z.lazy(() => SlapUncheckedUpdateManyWithoutParentNestedInputSchema).optional(),
  descendants: z.lazy(() => SlapUncheckedUpdateManyWithoutRootNestedInputSchema).optional()
}).strict();

export const UserCreateWithoutAccountsInputSchema: z.ZodType<Prisma.UserCreateWithoutAccountsInput> = z.object({
  id: z.string().optional(),
  name: z.string().optional().nullable(),
  email: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  lastSeen: z.coerce.date().optional().nullable(),
  emailVerified: z.coerce.date().optional().nullable(),
  role: z.lazy(() => RoleSchema).optional(),
  image: z.string().optional().nullable(),
  sessions: z.lazy(() => SessionCreateNestedManyWithoutUserInputSchema).optional(),
  slaps: z.lazy(() => SlapCreateNestedManyWithoutCreatorInputSchema).optional(),
  worlds: z.lazy(() => WorldCreateNestedManyWithoutUsersInputSchema).optional()
}).strict();

export const UserUncheckedCreateWithoutAccountsInputSchema: z.ZodType<Prisma.UserUncheckedCreateWithoutAccountsInput> = z.object({
  id: z.string().optional(),
  name: z.string().optional().nullable(),
  email: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  lastSeen: z.coerce.date().optional().nullable(),
  emailVerified: z.coerce.date().optional().nullable(),
  role: z.lazy(() => RoleSchema).optional(),
  image: z.string().optional().nullable(),
  sessions: z.lazy(() => SessionUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  slaps: z.lazy(() => SlapUncheckedCreateNestedManyWithoutCreatorInputSchema).optional(),
  worlds: z.lazy(() => WorldUncheckedCreateNestedManyWithoutUsersInputSchema).optional()
}).strict();

export const UserCreateOrConnectWithoutAccountsInputSchema: z.ZodType<Prisma.UserCreateOrConnectWithoutAccountsInput> = z.object({
  where: z.lazy(() => UserWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => UserCreateWithoutAccountsInputSchema),z.lazy(() => UserUncheckedCreateWithoutAccountsInputSchema) ]),
}).strict();

export const UserUpsertWithoutAccountsInputSchema: z.ZodType<Prisma.UserUpsertWithoutAccountsInput> = z.object({
  update: z.union([ z.lazy(() => UserUpdateWithoutAccountsInputSchema),z.lazy(() => UserUncheckedUpdateWithoutAccountsInputSchema) ]),
  create: z.union([ z.lazy(() => UserCreateWithoutAccountsInputSchema),z.lazy(() => UserUncheckedCreateWithoutAccountsInputSchema) ]),
  where: z.lazy(() => UserWhereInputSchema).optional()
}).strict();

export const UserUpdateToOneWithWhereWithoutAccountsInputSchema: z.ZodType<Prisma.UserUpdateToOneWithWhereWithoutAccountsInput> = z.object({
  where: z.lazy(() => UserWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => UserUpdateWithoutAccountsInputSchema),z.lazy(() => UserUncheckedUpdateWithoutAccountsInputSchema) ]),
}).strict();

export const UserUpdateWithoutAccountsInputSchema: z.ZodType<Prisma.UserUpdateWithoutAccountsInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  lastSeen: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerified: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  role: z.union([ z.lazy(() => RoleSchema),z.lazy(() => EnumRoleFieldUpdateOperationsInputSchema) ]).optional(),
  image: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  sessions: z.lazy(() => SessionUpdateManyWithoutUserNestedInputSchema).optional(),
  slaps: z.lazy(() => SlapUpdateManyWithoutCreatorNestedInputSchema).optional(),
  worlds: z.lazy(() => WorldUpdateManyWithoutUsersNestedInputSchema).optional()
}).strict();

export const UserUncheckedUpdateWithoutAccountsInputSchema: z.ZodType<Prisma.UserUncheckedUpdateWithoutAccountsInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  lastSeen: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerified: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  role: z.union([ z.lazy(() => RoleSchema),z.lazy(() => EnumRoleFieldUpdateOperationsInputSchema) ]).optional(),
  image: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  sessions: z.lazy(() => SessionUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  slaps: z.lazy(() => SlapUncheckedUpdateManyWithoutCreatorNestedInputSchema).optional(),
  worlds: z.lazy(() => WorldUncheckedUpdateManyWithoutUsersNestedInputSchema).optional()
}).strict();

export const UserCreateWithoutSessionsInputSchema: z.ZodType<Prisma.UserCreateWithoutSessionsInput> = z.object({
  id: z.string().optional(),
  name: z.string().optional().nullable(),
  email: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  lastSeen: z.coerce.date().optional().nullable(),
  emailVerified: z.coerce.date().optional().nullable(),
  role: z.lazy(() => RoleSchema).optional(),
  image: z.string().optional().nullable(),
  accounts: z.lazy(() => AccountCreateNestedManyWithoutUserInputSchema).optional(),
  slaps: z.lazy(() => SlapCreateNestedManyWithoutCreatorInputSchema).optional(),
  worlds: z.lazy(() => WorldCreateNestedManyWithoutUsersInputSchema).optional()
}).strict();

export const UserUncheckedCreateWithoutSessionsInputSchema: z.ZodType<Prisma.UserUncheckedCreateWithoutSessionsInput> = z.object({
  id: z.string().optional(),
  name: z.string().optional().nullable(),
  email: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  lastSeen: z.coerce.date().optional().nullable(),
  emailVerified: z.coerce.date().optional().nullable(),
  role: z.lazy(() => RoleSchema).optional(),
  image: z.string().optional().nullable(),
  accounts: z.lazy(() => AccountUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  slaps: z.lazy(() => SlapUncheckedCreateNestedManyWithoutCreatorInputSchema).optional(),
  worlds: z.lazy(() => WorldUncheckedCreateNestedManyWithoutUsersInputSchema).optional()
}).strict();

export const UserCreateOrConnectWithoutSessionsInputSchema: z.ZodType<Prisma.UserCreateOrConnectWithoutSessionsInput> = z.object({
  where: z.lazy(() => UserWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => UserCreateWithoutSessionsInputSchema),z.lazy(() => UserUncheckedCreateWithoutSessionsInputSchema) ]),
}).strict();

export const UserUpsertWithoutSessionsInputSchema: z.ZodType<Prisma.UserUpsertWithoutSessionsInput> = z.object({
  update: z.union([ z.lazy(() => UserUpdateWithoutSessionsInputSchema),z.lazy(() => UserUncheckedUpdateWithoutSessionsInputSchema) ]),
  create: z.union([ z.lazy(() => UserCreateWithoutSessionsInputSchema),z.lazy(() => UserUncheckedCreateWithoutSessionsInputSchema) ]),
  where: z.lazy(() => UserWhereInputSchema).optional()
}).strict();

export const UserUpdateToOneWithWhereWithoutSessionsInputSchema: z.ZodType<Prisma.UserUpdateToOneWithWhereWithoutSessionsInput> = z.object({
  where: z.lazy(() => UserWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => UserUpdateWithoutSessionsInputSchema),z.lazy(() => UserUncheckedUpdateWithoutSessionsInputSchema) ]),
}).strict();

export const UserUpdateWithoutSessionsInputSchema: z.ZodType<Prisma.UserUpdateWithoutSessionsInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  lastSeen: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerified: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  role: z.union([ z.lazy(() => RoleSchema),z.lazy(() => EnumRoleFieldUpdateOperationsInputSchema) ]).optional(),
  image: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  accounts: z.lazy(() => AccountUpdateManyWithoutUserNestedInputSchema).optional(),
  slaps: z.lazy(() => SlapUpdateManyWithoutCreatorNestedInputSchema).optional(),
  worlds: z.lazy(() => WorldUpdateManyWithoutUsersNestedInputSchema).optional()
}).strict();

export const UserUncheckedUpdateWithoutSessionsInputSchema: z.ZodType<Prisma.UserUncheckedUpdateWithoutSessionsInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  lastSeen: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerified: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  role: z.union([ z.lazy(() => RoleSchema),z.lazy(() => EnumRoleFieldUpdateOperationsInputSchema) ]).optional(),
  image: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  accounts: z.lazy(() => AccountUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  slaps: z.lazy(() => SlapUncheckedUpdateManyWithoutCreatorNestedInputSchema).optional(),
  worlds: z.lazy(() => WorldUncheckedUpdateManyWithoutUsersNestedInputSchema).optional()
}).strict();

export const AccountCreateWithoutUserInputSchema: z.ZodType<Prisma.AccountCreateWithoutUserInput> = z.object({
  id: z.string().optional(),
  type: z.string(),
  provider: z.string(),
  providerAccountId: z.string(),
  refresh_token: z.string().optional().nullable(),
  access_token: z.string().optional().nullable(),
  expires_at: z.number().int().optional().nullable(),
  token_type: z.string().optional().nullable(),
  scope: z.string().optional().nullable(),
  id_token: z.string().optional().nullable(),
  session_state: z.string().optional().nullable()
}).strict();

export const AccountUncheckedCreateWithoutUserInputSchema: z.ZodType<Prisma.AccountUncheckedCreateWithoutUserInput> = z.object({
  id: z.string().optional(),
  type: z.string(),
  provider: z.string(),
  providerAccountId: z.string(),
  refresh_token: z.string().optional().nullable(),
  access_token: z.string().optional().nullable(),
  expires_at: z.number().int().optional().nullable(),
  token_type: z.string().optional().nullable(),
  scope: z.string().optional().nullable(),
  id_token: z.string().optional().nullable(),
  session_state: z.string().optional().nullable()
}).strict();

export const AccountCreateOrConnectWithoutUserInputSchema: z.ZodType<Prisma.AccountCreateOrConnectWithoutUserInput> = z.object({
  where: z.lazy(() => AccountWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => AccountCreateWithoutUserInputSchema),z.lazy(() => AccountUncheckedCreateWithoutUserInputSchema) ]),
}).strict();

export const AccountCreateManyUserInputEnvelopeSchema: z.ZodType<Prisma.AccountCreateManyUserInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => AccountCreateManyUserInputSchema),z.lazy(() => AccountCreateManyUserInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const SessionCreateWithoutUserInputSchema: z.ZodType<Prisma.SessionCreateWithoutUserInput> = z.object({
  id: z.string().optional(),
  sessionToken: z.string(),
  expires: z.coerce.date()
}).strict();

export const SessionUncheckedCreateWithoutUserInputSchema: z.ZodType<Prisma.SessionUncheckedCreateWithoutUserInput> = z.object({
  id: z.string().optional(),
  sessionToken: z.string(),
  expires: z.coerce.date()
}).strict();

export const SessionCreateOrConnectWithoutUserInputSchema: z.ZodType<Prisma.SessionCreateOrConnectWithoutUserInput> = z.object({
  where: z.lazy(() => SessionWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => SessionCreateWithoutUserInputSchema),z.lazy(() => SessionUncheckedCreateWithoutUserInputSchema) ]),
}).strict();

export const SessionCreateManyUserInputEnvelopeSchema: z.ZodType<Prisma.SessionCreateManyUserInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => SessionCreateManyUserInputSchema),z.lazy(() => SessionCreateManyUserInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const SlapCreateWithoutCreatorInputSchema: z.ZodType<Prisma.SlapCreateWithoutCreatorInput> = z.object({
  id: z.string().optional(),
  slug: z.string().optional().nullable(),
  title: z.string(),
  bpm: z.number().int(),
  key: z.string(),
  currentBpm: z.number().int().optional().nullable(),
  currentKey: z.string().optional().nullable(),
  isRoot: z.boolean().optional(),
  isPremade: z.boolean().optional(),
  isDeleted: z.boolean().optional(),
  duration: z.number(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  skin: z.lazy(() => SkinCreateNestedOneWithoutSlapsInputSchema),
  worlds: z.lazy(() => WorldCreateNestedManyWithoutSlapsInputSchema).optional(),
  tags: z.lazy(() => TagCreateNestedManyWithoutSlapsInputSchema).optional(),
  tracks: z.lazy(() => TrackCreateNestedManyWithoutSlapInputSchema).optional(),
  parent: z.lazy(() => SlapCreateNestedOneWithoutChildrenInputSchema).optional(),
  root: z.lazy(() => SlapCreateNestedOneWithoutDescendantsInputSchema).optional(),
  children: z.lazy(() => SlapCreateNestedManyWithoutParentInputSchema).optional(),
  descendants: z.lazy(() => SlapCreateNestedManyWithoutRootInputSchema).optional()
}).strict();

export const SlapUncheckedCreateWithoutCreatorInputSchema: z.ZodType<Prisma.SlapUncheckedCreateWithoutCreatorInput> = z.object({
  id: z.string().optional(),
  slug: z.string().optional().nullable(),
  title: z.string(),
  bpm: z.number().int(),
  key: z.string(),
  currentBpm: z.number().int().optional().nullable(),
  currentKey: z.string().optional().nullable(),
  isRoot: z.boolean().optional(),
  isPremade: z.boolean().optional(),
  isDeleted: z.boolean().optional(),
  duration: z.number(),
  skinId: z.string(),
  parentId: z.string().optional().nullable(),
  rootId: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  worlds: z.lazy(() => WorldUncheckedCreateNestedManyWithoutSlapsInputSchema).optional(),
  tags: z.lazy(() => TagUncheckedCreateNestedManyWithoutSlapsInputSchema).optional(),
  tracks: z.lazy(() => TrackUncheckedCreateNestedManyWithoutSlapInputSchema).optional(),
  children: z.lazy(() => SlapUncheckedCreateNestedManyWithoutParentInputSchema).optional(),
  descendants: z.lazy(() => SlapUncheckedCreateNestedManyWithoutRootInputSchema).optional()
}).strict();

export const SlapCreateOrConnectWithoutCreatorInputSchema: z.ZodType<Prisma.SlapCreateOrConnectWithoutCreatorInput> = z.object({
  where: z.lazy(() => SlapWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => SlapCreateWithoutCreatorInputSchema),z.lazy(() => SlapUncheckedCreateWithoutCreatorInputSchema) ]),
}).strict();

export const SlapCreateManyCreatorInputEnvelopeSchema: z.ZodType<Prisma.SlapCreateManyCreatorInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => SlapCreateManyCreatorInputSchema),z.lazy(() => SlapCreateManyCreatorInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const WorldCreateWithoutUsersInputSchema: z.ZodType<Prisma.WorldCreateWithoutUsersInput> = z.object({
  id: z.string().optional(),
  title: z.string().optional(),
  video: z.string(),
  image: z.string(),
  description: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  slaps: z.lazy(() => SlapCreateNestedManyWithoutWorldsInputSchema).optional(),
  tags: z.lazy(() => TagCreateNestedManyWithoutWorldsInputSchema).optional()
}).strict();

export const WorldUncheckedCreateWithoutUsersInputSchema: z.ZodType<Prisma.WorldUncheckedCreateWithoutUsersInput> = z.object({
  id: z.string().optional(),
  title: z.string().optional(),
  video: z.string(),
  image: z.string(),
  description: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  slaps: z.lazy(() => SlapUncheckedCreateNestedManyWithoutWorldsInputSchema).optional(),
  tags: z.lazy(() => TagUncheckedCreateNestedManyWithoutWorldsInputSchema).optional()
}).strict();

export const WorldCreateOrConnectWithoutUsersInputSchema: z.ZodType<Prisma.WorldCreateOrConnectWithoutUsersInput> = z.object({
  where: z.lazy(() => WorldWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => WorldCreateWithoutUsersInputSchema),z.lazy(() => WorldUncheckedCreateWithoutUsersInputSchema) ]),
}).strict();

export const AccountUpsertWithWhereUniqueWithoutUserInputSchema: z.ZodType<Prisma.AccountUpsertWithWhereUniqueWithoutUserInput> = z.object({
  where: z.lazy(() => AccountWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => AccountUpdateWithoutUserInputSchema),z.lazy(() => AccountUncheckedUpdateWithoutUserInputSchema) ]),
  create: z.union([ z.lazy(() => AccountCreateWithoutUserInputSchema),z.lazy(() => AccountUncheckedCreateWithoutUserInputSchema) ]),
}).strict();

export const AccountUpdateWithWhereUniqueWithoutUserInputSchema: z.ZodType<Prisma.AccountUpdateWithWhereUniqueWithoutUserInput> = z.object({
  where: z.lazy(() => AccountWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => AccountUpdateWithoutUserInputSchema),z.lazy(() => AccountUncheckedUpdateWithoutUserInputSchema) ]),
}).strict();

export const AccountUpdateManyWithWhereWithoutUserInputSchema: z.ZodType<Prisma.AccountUpdateManyWithWhereWithoutUserInput> = z.object({
  where: z.lazy(() => AccountScalarWhereInputSchema),
  data: z.union([ z.lazy(() => AccountUpdateManyMutationInputSchema),z.lazy(() => AccountUncheckedUpdateManyWithoutUserInputSchema) ]),
}).strict();

export const AccountScalarWhereInputSchema: z.ZodType<Prisma.AccountScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => AccountScalarWhereInputSchema),z.lazy(() => AccountScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => AccountScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => AccountScalarWhereInputSchema),z.lazy(() => AccountScalarWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => UuidFilterSchema),z.string() ]).optional(),
  userId: z.union([ z.lazy(() => UuidFilterSchema),z.string() ]).optional(),
  type: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  provider: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  providerAccountId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  refresh_token: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  access_token: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  expires_at: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  token_type: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  scope: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  id_token: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  session_state: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
}).strict();

export const SessionUpsertWithWhereUniqueWithoutUserInputSchema: z.ZodType<Prisma.SessionUpsertWithWhereUniqueWithoutUserInput> = z.object({
  where: z.lazy(() => SessionWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => SessionUpdateWithoutUserInputSchema),z.lazy(() => SessionUncheckedUpdateWithoutUserInputSchema) ]),
  create: z.union([ z.lazy(() => SessionCreateWithoutUserInputSchema),z.lazy(() => SessionUncheckedCreateWithoutUserInputSchema) ]),
}).strict();

export const SessionUpdateWithWhereUniqueWithoutUserInputSchema: z.ZodType<Prisma.SessionUpdateWithWhereUniqueWithoutUserInput> = z.object({
  where: z.lazy(() => SessionWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => SessionUpdateWithoutUserInputSchema),z.lazy(() => SessionUncheckedUpdateWithoutUserInputSchema) ]),
}).strict();

export const SessionUpdateManyWithWhereWithoutUserInputSchema: z.ZodType<Prisma.SessionUpdateManyWithWhereWithoutUserInput> = z.object({
  where: z.lazy(() => SessionScalarWhereInputSchema),
  data: z.union([ z.lazy(() => SessionUpdateManyMutationInputSchema),z.lazy(() => SessionUncheckedUpdateManyWithoutUserInputSchema) ]),
}).strict();

export const SessionScalarWhereInputSchema: z.ZodType<Prisma.SessionScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => SessionScalarWhereInputSchema),z.lazy(() => SessionScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => SessionScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => SessionScalarWhereInputSchema),z.lazy(() => SessionScalarWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => UuidFilterSchema),z.string() ]).optional(),
  sessionToken: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  userId: z.union([ z.lazy(() => UuidFilterSchema),z.string() ]).optional(),
  expires: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
}).strict();

export const SlapUpsertWithWhereUniqueWithoutCreatorInputSchema: z.ZodType<Prisma.SlapUpsertWithWhereUniqueWithoutCreatorInput> = z.object({
  where: z.lazy(() => SlapWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => SlapUpdateWithoutCreatorInputSchema),z.lazy(() => SlapUncheckedUpdateWithoutCreatorInputSchema) ]),
  create: z.union([ z.lazy(() => SlapCreateWithoutCreatorInputSchema),z.lazy(() => SlapUncheckedCreateWithoutCreatorInputSchema) ]),
}).strict();

export const SlapUpdateWithWhereUniqueWithoutCreatorInputSchema: z.ZodType<Prisma.SlapUpdateWithWhereUniqueWithoutCreatorInput> = z.object({
  where: z.lazy(() => SlapWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => SlapUpdateWithoutCreatorInputSchema),z.lazy(() => SlapUncheckedUpdateWithoutCreatorInputSchema) ]),
}).strict();

export const SlapUpdateManyWithWhereWithoutCreatorInputSchema: z.ZodType<Prisma.SlapUpdateManyWithWhereWithoutCreatorInput> = z.object({
  where: z.lazy(() => SlapScalarWhereInputSchema),
  data: z.union([ z.lazy(() => SlapUpdateManyMutationInputSchema),z.lazy(() => SlapUncheckedUpdateManyWithoutCreatorInputSchema) ]),
}).strict();

export const WorldUpsertWithWhereUniqueWithoutUsersInputSchema: z.ZodType<Prisma.WorldUpsertWithWhereUniqueWithoutUsersInput> = z.object({
  where: z.lazy(() => WorldWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => WorldUpdateWithoutUsersInputSchema),z.lazy(() => WorldUncheckedUpdateWithoutUsersInputSchema) ]),
  create: z.union([ z.lazy(() => WorldCreateWithoutUsersInputSchema),z.lazy(() => WorldUncheckedCreateWithoutUsersInputSchema) ]),
}).strict();

export const WorldUpdateWithWhereUniqueWithoutUsersInputSchema: z.ZodType<Prisma.WorldUpdateWithWhereUniqueWithoutUsersInput> = z.object({
  where: z.lazy(() => WorldWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => WorldUpdateWithoutUsersInputSchema),z.lazy(() => WorldUncheckedUpdateWithoutUsersInputSchema) ]),
}).strict();

export const WorldUpdateManyWithWhereWithoutUsersInputSchema: z.ZodType<Prisma.WorldUpdateManyWithWhereWithoutUsersInput> = z.object({
  where: z.lazy(() => WorldScalarWhereInputSchema),
  data: z.union([ z.lazy(() => WorldUpdateManyMutationInputSchema),z.lazy(() => WorldUncheckedUpdateManyWithoutUsersInputSchema) ]),
}).strict();

export const UserUpdateWithoutWorldsInputSchema: z.ZodType<Prisma.UserUpdateWithoutWorldsInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  lastSeen: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerified: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  role: z.union([ z.lazy(() => RoleSchema),z.lazy(() => EnumRoleFieldUpdateOperationsInputSchema) ]).optional(),
  image: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  accounts: z.lazy(() => AccountUpdateManyWithoutUserNestedInputSchema).optional(),
  sessions: z.lazy(() => SessionUpdateManyWithoutUserNestedInputSchema).optional(),
  slaps: z.lazy(() => SlapUpdateManyWithoutCreatorNestedInputSchema).optional()
}).strict();

export const UserUncheckedUpdateWithoutWorldsInputSchema: z.ZodType<Prisma.UserUncheckedUpdateWithoutWorldsInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  lastSeen: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerified: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  role: z.union([ z.lazy(() => RoleSchema),z.lazy(() => EnumRoleFieldUpdateOperationsInputSchema) ]).optional(),
  image: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  accounts: z.lazy(() => AccountUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  sessions: z.lazy(() => SessionUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  slaps: z.lazy(() => SlapUncheckedUpdateManyWithoutCreatorNestedInputSchema).optional()
}).strict();

export const UserUncheckedUpdateManyWithoutWorldsInputSchema: z.ZodType<Prisma.UserUncheckedUpdateManyWithoutWorldsInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  lastSeen: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerified: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  role: z.union([ z.lazy(() => RoleSchema),z.lazy(() => EnumRoleFieldUpdateOperationsInputSchema) ]).optional(),
  image: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const SlapUpdateWithoutWorldsInputSchema: z.ZodType<Prisma.SlapUpdateWithoutWorldsInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  slug: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  title: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  bpm: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  key: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  currentBpm: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currentKey: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  isRoot: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  isPremade: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  isDeleted: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  duration: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  skin: z.lazy(() => SkinUpdateOneRequiredWithoutSlapsNestedInputSchema).optional(),
  creator: z.lazy(() => UserUpdateOneWithoutSlapsNestedInputSchema).optional(),
  tags: z.lazy(() => TagUpdateManyWithoutSlapsNestedInputSchema).optional(),
  tracks: z.lazy(() => TrackUpdateManyWithoutSlapNestedInputSchema).optional(),
  parent: z.lazy(() => SlapUpdateOneWithoutChildrenNestedInputSchema).optional(),
  root: z.lazy(() => SlapUpdateOneWithoutDescendantsNestedInputSchema).optional(),
  children: z.lazy(() => SlapUpdateManyWithoutParentNestedInputSchema).optional(),
  descendants: z.lazy(() => SlapUpdateManyWithoutRootNestedInputSchema).optional()
}).strict();

export const SlapUncheckedUpdateWithoutWorldsInputSchema: z.ZodType<Prisma.SlapUncheckedUpdateWithoutWorldsInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  slug: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  title: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  bpm: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  key: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  currentBpm: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currentKey: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  isRoot: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  isPremade: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  isDeleted: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  duration: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  skinId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  parentId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  rootId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  creatorId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  tags: z.lazy(() => TagUncheckedUpdateManyWithoutSlapsNestedInputSchema).optional(),
  tracks: z.lazy(() => TrackUncheckedUpdateManyWithoutSlapNestedInputSchema).optional(),
  children: z.lazy(() => SlapUncheckedUpdateManyWithoutParentNestedInputSchema).optional(),
  descendants: z.lazy(() => SlapUncheckedUpdateManyWithoutRootNestedInputSchema).optional()
}).strict();

export const SlapUncheckedUpdateManyWithoutWorldsInputSchema: z.ZodType<Prisma.SlapUncheckedUpdateManyWithoutWorldsInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  slug: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  title: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  bpm: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  key: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  currentBpm: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currentKey: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  isRoot: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  isPremade: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  isDeleted: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  duration: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  skinId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  parentId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  rootId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  creatorId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const TagUpdateWithoutWorldsInputSchema: z.ZodType<Prisma.TagUpdateWithoutWorldsInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  title: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  slaps: z.lazy(() => SlapUpdateManyWithoutTagsNestedInputSchema).optional()
}).strict();

export const TagUncheckedUpdateWithoutWorldsInputSchema: z.ZodType<Prisma.TagUncheckedUpdateWithoutWorldsInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  title: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  slaps: z.lazy(() => SlapUncheckedUpdateManyWithoutTagsNestedInputSchema).optional()
}).strict();

export const TagUncheckedUpdateManyWithoutWorldsInputSchema: z.ZodType<Prisma.TagUncheckedUpdateManyWithoutWorldsInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  title: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const SlapUpdateWithoutTagsInputSchema: z.ZodType<Prisma.SlapUpdateWithoutTagsInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  slug: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  title: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  bpm: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  key: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  currentBpm: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currentKey: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  isRoot: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  isPremade: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  isDeleted: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  duration: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  skin: z.lazy(() => SkinUpdateOneRequiredWithoutSlapsNestedInputSchema).optional(),
  creator: z.lazy(() => UserUpdateOneWithoutSlapsNestedInputSchema).optional(),
  worlds: z.lazy(() => WorldUpdateManyWithoutSlapsNestedInputSchema).optional(),
  tracks: z.lazy(() => TrackUpdateManyWithoutSlapNestedInputSchema).optional(),
  parent: z.lazy(() => SlapUpdateOneWithoutChildrenNestedInputSchema).optional(),
  root: z.lazy(() => SlapUpdateOneWithoutDescendantsNestedInputSchema).optional(),
  children: z.lazy(() => SlapUpdateManyWithoutParentNestedInputSchema).optional(),
  descendants: z.lazy(() => SlapUpdateManyWithoutRootNestedInputSchema).optional()
}).strict();

export const SlapUncheckedUpdateWithoutTagsInputSchema: z.ZodType<Prisma.SlapUncheckedUpdateWithoutTagsInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  slug: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  title: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  bpm: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  key: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  currentBpm: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currentKey: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  isRoot: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  isPremade: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  isDeleted: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  duration: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  skinId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  parentId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  rootId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  creatorId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  worlds: z.lazy(() => WorldUncheckedUpdateManyWithoutSlapsNestedInputSchema).optional(),
  tracks: z.lazy(() => TrackUncheckedUpdateManyWithoutSlapNestedInputSchema).optional(),
  children: z.lazy(() => SlapUncheckedUpdateManyWithoutParentNestedInputSchema).optional(),
  descendants: z.lazy(() => SlapUncheckedUpdateManyWithoutRootNestedInputSchema).optional()
}).strict();

export const SlapUncheckedUpdateManyWithoutTagsInputSchema: z.ZodType<Prisma.SlapUncheckedUpdateManyWithoutTagsInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  slug: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  title: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  bpm: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  key: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  currentBpm: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currentKey: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  isRoot: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  isPremade: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  isDeleted: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  duration: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  skinId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  parentId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  rootId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  creatorId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const WorldUpdateWithoutTagsInputSchema: z.ZodType<Prisma.WorldUpdateWithoutTagsInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  title: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  video: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  image: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  description: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  users: z.lazy(() => UserUpdateManyWithoutWorldsNestedInputSchema).optional(),
  slaps: z.lazy(() => SlapUpdateManyWithoutWorldsNestedInputSchema).optional()
}).strict();

export const WorldUncheckedUpdateWithoutTagsInputSchema: z.ZodType<Prisma.WorldUncheckedUpdateWithoutTagsInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  title: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  video: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  image: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  description: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  users: z.lazy(() => UserUncheckedUpdateManyWithoutWorldsNestedInputSchema).optional(),
  slaps: z.lazy(() => SlapUncheckedUpdateManyWithoutWorldsNestedInputSchema).optional()
}).strict();

export const WorldUncheckedUpdateManyWithoutTagsInputSchema: z.ZodType<Prisma.WorldUncheckedUpdateManyWithoutTagsInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  title: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  video: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  image: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  description: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const TrackCreateManySlapInputSchema: z.ZodType<Prisma.TrackCreateManySlapInput> = z.object({
  id: z.string().optional(),
  src: z.string(),
  title: z.string(),
  data: z.union([ z.lazy(() => TrackCreatedataInputSchema),InputJsonValueSchema.array() ]).optional(),
  isSoloed: z.boolean().optional().nullable(),
  isMuted: z.boolean().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const SlapCreateManyParentInputSchema: z.ZodType<Prisma.SlapCreateManyParentInput> = z.object({
  id: z.string().optional(),
  slug: z.string().optional().nullable(),
  title: z.string(),
  bpm: z.number().int(),
  key: z.string(),
  currentBpm: z.number().int().optional().nullable(),
  currentKey: z.string().optional().nullable(),
  isRoot: z.boolean().optional(),
  isPremade: z.boolean().optional(),
  isDeleted: z.boolean().optional(),
  duration: z.number(),
  skinId: z.string(),
  rootId: z.string().optional().nullable(),
  creatorId: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const SlapCreateManyRootInputSchema: z.ZodType<Prisma.SlapCreateManyRootInput> = z.object({
  id: z.string().optional(),
  slug: z.string().optional().nullable(),
  title: z.string(),
  bpm: z.number().int(),
  key: z.string(),
  currentBpm: z.number().int().optional().nullable(),
  currentKey: z.string().optional().nullable(),
  isRoot: z.boolean().optional(),
  isPremade: z.boolean().optional(),
  isDeleted: z.boolean().optional(),
  duration: z.number(),
  skinId: z.string(),
  parentId: z.string().optional().nullable(),
  creatorId: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const WorldUpdateWithoutSlapsInputSchema: z.ZodType<Prisma.WorldUpdateWithoutSlapsInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  title: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  video: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  image: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  description: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  users: z.lazy(() => UserUpdateManyWithoutWorldsNestedInputSchema).optional(),
  tags: z.lazy(() => TagUpdateManyWithoutWorldsNestedInputSchema).optional()
}).strict();

export const WorldUncheckedUpdateWithoutSlapsInputSchema: z.ZodType<Prisma.WorldUncheckedUpdateWithoutSlapsInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  title: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  video: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  image: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  description: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  users: z.lazy(() => UserUncheckedUpdateManyWithoutWorldsNestedInputSchema).optional(),
  tags: z.lazy(() => TagUncheckedUpdateManyWithoutWorldsNestedInputSchema).optional()
}).strict();

export const WorldUncheckedUpdateManyWithoutSlapsInputSchema: z.ZodType<Prisma.WorldUncheckedUpdateManyWithoutSlapsInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  title: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  video: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  image: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  description: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const TagUpdateWithoutSlapsInputSchema: z.ZodType<Prisma.TagUpdateWithoutSlapsInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  title: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  worlds: z.lazy(() => WorldUpdateManyWithoutTagsNestedInputSchema).optional()
}).strict();

export const TagUncheckedUpdateWithoutSlapsInputSchema: z.ZodType<Prisma.TagUncheckedUpdateWithoutSlapsInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  title: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  worlds: z.lazy(() => WorldUncheckedUpdateManyWithoutTagsNestedInputSchema).optional()
}).strict();

export const TagUncheckedUpdateManyWithoutSlapsInputSchema: z.ZodType<Prisma.TagUncheckedUpdateManyWithoutSlapsInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  title: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const TrackUpdateWithoutSlapInputSchema: z.ZodType<Prisma.TrackUpdateWithoutSlapInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  src: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  title: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  data: z.union([ z.lazy(() => TrackUpdatedataInputSchema),InputJsonValueSchema.array() ]).optional(),
  isSoloed: z.union([ z.boolean(),z.lazy(() => NullableBoolFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  isMuted: z.union([ z.boolean(),z.lazy(() => NullableBoolFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const TrackUncheckedUpdateWithoutSlapInputSchema: z.ZodType<Prisma.TrackUncheckedUpdateWithoutSlapInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  src: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  title: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  data: z.union([ z.lazy(() => TrackUpdatedataInputSchema),InputJsonValueSchema.array() ]).optional(),
  isSoloed: z.union([ z.boolean(),z.lazy(() => NullableBoolFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  isMuted: z.union([ z.boolean(),z.lazy(() => NullableBoolFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const TrackUncheckedUpdateManyWithoutSlapInputSchema: z.ZodType<Prisma.TrackUncheckedUpdateManyWithoutSlapInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  src: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  title: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  data: z.union([ z.lazy(() => TrackUpdatedataInputSchema),InputJsonValueSchema.array() ]).optional(),
  isSoloed: z.union([ z.boolean(),z.lazy(() => NullableBoolFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  isMuted: z.union([ z.boolean(),z.lazy(() => NullableBoolFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const SlapUpdateWithoutParentInputSchema: z.ZodType<Prisma.SlapUpdateWithoutParentInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  slug: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  title: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  bpm: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  key: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  currentBpm: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currentKey: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  isRoot: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  isPremade: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  isDeleted: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  duration: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  skin: z.lazy(() => SkinUpdateOneRequiredWithoutSlapsNestedInputSchema).optional(),
  creator: z.lazy(() => UserUpdateOneWithoutSlapsNestedInputSchema).optional(),
  worlds: z.lazy(() => WorldUpdateManyWithoutSlapsNestedInputSchema).optional(),
  tags: z.lazy(() => TagUpdateManyWithoutSlapsNestedInputSchema).optional(),
  tracks: z.lazy(() => TrackUpdateManyWithoutSlapNestedInputSchema).optional(),
  root: z.lazy(() => SlapUpdateOneWithoutDescendantsNestedInputSchema).optional(),
  children: z.lazy(() => SlapUpdateManyWithoutParentNestedInputSchema).optional(),
  descendants: z.lazy(() => SlapUpdateManyWithoutRootNestedInputSchema).optional()
}).strict();

export const SlapUncheckedUpdateWithoutParentInputSchema: z.ZodType<Prisma.SlapUncheckedUpdateWithoutParentInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  slug: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  title: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  bpm: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  key: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  currentBpm: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currentKey: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  isRoot: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  isPremade: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  isDeleted: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  duration: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  skinId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  rootId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  creatorId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  worlds: z.lazy(() => WorldUncheckedUpdateManyWithoutSlapsNestedInputSchema).optional(),
  tags: z.lazy(() => TagUncheckedUpdateManyWithoutSlapsNestedInputSchema).optional(),
  tracks: z.lazy(() => TrackUncheckedUpdateManyWithoutSlapNestedInputSchema).optional(),
  children: z.lazy(() => SlapUncheckedUpdateManyWithoutParentNestedInputSchema).optional(),
  descendants: z.lazy(() => SlapUncheckedUpdateManyWithoutRootNestedInputSchema).optional()
}).strict();

export const SlapUncheckedUpdateManyWithoutParentInputSchema: z.ZodType<Prisma.SlapUncheckedUpdateManyWithoutParentInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  slug: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  title: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  bpm: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  key: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  currentBpm: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currentKey: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  isRoot: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  isPremade: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  isDeleted: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  duration: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  skinId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  rootId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  creatorId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const SlapUpdateWithoutRootInputSchema: z.ZodType<Prisma.SlapUpdateWithoutRootInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  slug: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  title: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  bpm: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  key: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  currentBpm: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currentKey: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  isRoot: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  isPremade: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  isDeleted: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  duration: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  skin: z.lazy(() => SkinUpdateOneRequiredWithoutSlapsNestedInputSchema).optional(),
  creator: z.lazy(() => UserUpdateOneWithoutSlapsNestedInputSchema).optional(),
  worlds: z.lazy(() => WorldUpdateManyWithoutSlapsNestedInputSchema).optional(),
  tags: z.lazy(() => TagUpdateManyWithoutSlapsNestedInputSchema).optional(),
  tracks: z.lazy(() => TrackUpdateManyWithoutSlapNestedInputSchema).optional(),
  parent: z.lazy(() => SlapUpdateOneWithoutChildrenNestedInputSchema).optional(),
  children: z.lazy(() => SlapUpdateManyWithoutParentNestedInputSchema).optional(),
  descendants: z.lazy(() => SlapUpdateManyWithoutRootNestedInputSchema).optional()
}).strict();

export const SlapUncheckedUpdateWithoutRootInputSchema: z.ZodType<Prisma.SlapUncheckedUpdateWithoutRootInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  slug: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  title: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  bpm: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  key: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  currentBpm: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currentKey: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  isRoot: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  isPremade: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  isDeleted: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  duration: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  skinId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  parentId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  creatorId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  worlds: z.lazy(() => WorldUncheckedUpdateManyWithoutSlapsNestedInputSchema).optional(),
  tags: z.lazy(() => TagUncheckedUpdateManyWithoutSlapsNestedInputSchema).optional(),
  tracks: z.lazy(() => TrackUncheckedUpdateManyWithoutSlapNestedInputSchema).optional(),
  children: z.lazy(() => SlapUncheckedUpdateManyWithoutParentNestedInputSchema).optional(),
  descendants: z.lazy(() => SlapUncheckedUpdateManyWithoutRootNestedInputSchema).optional()
}).strict();

export const SlapUncheckedUpdateManyWithoutRootInputSchema: z.ZodType<Prisma.SlapUncheckedUpdateManyWithoutRootInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  slug: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  title: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  bpm: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  key: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  currentBpm: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currentKey: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  isRoot: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  isPremade: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  isDeleted: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  duration: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  skinId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  parentId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  creatorId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const SlapCreateManySkinInputSchema: z.ZodType<Prisma.SlapCreateManySkinInput> = z.object({
  id: z.string().optional(),
  slug: z.string().optional().nullable(),
  title: z.string(),
  bpm: z.number().int(),
  key: z.string(),
  currentBpm: z.number().int().optional().nullable(),
  currentKey: z.string().optional().nullable(),
  isRoot: z.boolean().optional(),
  isPremade: z.boolean().optional(),
  isDeleted: z.boolean().optional(),
  duration: z.number(),
  parentId: z.string().optional().nullable(),
  rootId: z.string().optional().nullable(),
  creatorId: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const SlapUpdateWithoutSkinInputSchema: z.ZodType<Prisma.SlapUpdateWithoutSkinInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  slug: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  title: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  bpm: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  key: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  currentBpm: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currentKey: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  isRoot: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  isPremade: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  isDeleted: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  duration: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  creator: z.lazy(() => UserUpdateOneWithoutSlapsNestedInputSchema).optional(),
  worlds: z.lazy(() => WorldUpdateManyWithoutSlapsNestedInputSchema).optional(),
  tags: z.lazy(() => TagUpdateManyWithoutSlapsNestedInputSchema).optional(),
  tracks: z.lazy(() => TrackUpdateManyWithoutSlapNestedInputSchema).optional(),
  parent: z.lazy(() => SlapUpdateOneWithoutChildrenNestedInputSchema).optional(),
  root: z.lazy(() => SlapUpdateOneWithoutDescendantsNestedInputSchema).optional(),
  children: z.lazy(() => SlapUpdateManyWithoutParentNestedInputSchema).optional(),
  descendants: z.lazy(() => SlapUpdateManyWithoutRootNestedInputSchema).optional()
}).strict();

export const SlapUncheckedUpdateWithoutSkinInputSchema: z.ZodType<Prisma.SlapUncheckedUpdateWithoutSkinInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  slug: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  title: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  bpm: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  key: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  currentBpm: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currentKey: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  isRoot: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  isPremade: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  isDeleted: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  duration: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  parentId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  rootId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  creatorId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  worlds: z.lazy(() => WorldUncheckedUpdateManyWithoutSlapsNestedInputSchema).optional(),
  tags: z.lazy(() => TagUncheckedUpdateManyWithoutSlapsNestedInputSchema).optional(),
  tracks: z.lazy(() => TrackUncheckedUpdateManyWithoutSlapNestedInputSchema).optional(),
  children: z.lazy(() => SlapUncheckedUpdateManyWithoutParentNestedInputSchema).optional(),
  descendants: z.lazy(() => SlapUncheckedUpdateManyWithoutRootNestedInputSchema).optional()
}).strict();

export const SlapUncheckedUpdateManyWithoutSkinInputSchema: z.ZodType<Prisma.SlapUncheckedUpdateManyWithoutSkinInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  slug: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  title: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  bpm: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  key: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  currentBpm: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currentKey: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  isRoot: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  isPremade: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  isDeleted: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  duration: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  parentId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  rootId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  creatorId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const AccountCreateManyUserInputSchema: z.ZodType<Prisma.AccountCreateManyUserInput> = z.object({
  id: z.string().optional(),
  type: z.string(),
  provider: z.string(),
  providerAccountId: z.string(),
  refresh_token: z.string().optional().nullable(),
  access_token: z.string().optional().nullable(),
  expires_at: z.number().int().optional().nullable(),
  token_type: z.string().optional().nullable(),
  scope: z.string().optional().nullable(),
  id_token: z.string().optional().nullable(),
  session_state: z.string().optional().nullable()
}).strict();

export const SessionCreateManyUserInputSchema: z.ZodType<Prisma.SessionCreateManyUserInput> = z.object({
  id: z.string().optional(),
  sessionToken: z.string(),
  expires: z.coerce.date()
}).strict();

export const SlapCreateManyCreatorInputSchema: z.ZodType<Prisma.SlapCreateManyCreatorInput> = z.object({
  id: z.string().optional(),
  slug: z.string().optional().nullable(),
  title: z.string(),
  bpm: z.number().int(),
  key: z.string(),
  currentBpm: z.number().int().optional().nullable(),
  currentKey: z.string().optional().nullable(),
  isRoot: z.boolean().optional(),
  isPremade: z.boolean().optional(),
  isDeleted: z.boolean().optional(),
  duration: z.number(),
  skinId: z.string(),
  parentId: z.string().optional().nullable(),
  rootId: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const AccountUpdateWithoutUserInputSchema: z.ZodType<Prisma.AccountUpdateWithoutUserInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  provider: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  providerAccountId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  refresh_token: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  access_token: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  expires_at: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  token_type: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  scope: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  id_token: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  session_state: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const AccountUncheckedUpdateWithoutUserInputSchema: z.ZodType<Prisma.AccountUncheckedUpdateWithoutUserInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  provider: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  providerAccountId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  refresh_token: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  access_token: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  expires_at: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  token_type: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  scope: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  id_token: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  session_state: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const AccountUncheckedUpdateManyWithoutUserInputSchema: z.ZodType<Prisma.AccountUncheckedUpdateManyWithoutUserInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  provider: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  providerAccountId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  refresh_token: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  access_token: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  expires_at: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  token_type: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  scope: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  id_token: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  session_state: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const SessionUpdateWithoutUserInputSchema: z.ZodType<Prisma.SessionUpdateWithoutUserInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  sessionToken: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  expires: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const SessionUncheckedUpdateWithoutUserInputSchema: z.ZodType<Prisma.SessionUncheckedUpdateWithoutUserInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  sessionToken: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  expires: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const SessionUncheckedUpdateManyWithoutUserInputSchema: z.ZodType<Prisma.SessionUncheckedUpdateManyWithoutUserInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  sessionToken: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  expires: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const SlapUpdateWithoutCreatorInputSchema: z.ZodType<Prisma.SlapUpdateWithoutCreatorInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  slug: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  title: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  bpm: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  key: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  currentBpm: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currentKey: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  isRoot: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  isPremade: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  isDeleted: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  duration: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  skin: z.lazy(() => SkinUpdateOneRequiredWithoutSlapsNestedInputSchema).optional(),
  worlds: z.lazy(() => WorldUpdateManyWithoutSlapsNestedInputSchema).optional(),
  tags: z.lazy(() => TagUpdateManyWithoutSlapsNestedInputSchema).optional(),
  tracks: z.lazy(() => TrackUpdateManyWithoutSlapNestedInputSchema).optional(),
  parent: z.lazy(() => SlapUpdateOneWithoutChildrenNestedInputSchema).optional(),
  root: z.lazy(() => SlapUpdateOneWithoutDescendantsNestedInputSchema).optional(),
  children: z.lazy(() => SlapUpdateManyWithoutParentNestedInputSchema).optional(),
  descendants: z.lazy(() => SlapUpdateManyWithoutRootNestedInputSchema).optional()
}).strict();

export const SlapUncheckedUpdateWithoutCreatorInputSchema: z.ZodType<Prisma.SlapUncheckedUpdateWithoutCreatorInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  slug: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  title: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  bpm: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  key: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  currentBpm: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currentKey: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  isRoot: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  isPremade: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  isDeleted: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  duration: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  skinId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  parentId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  rootId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  worlds: z.lazy(() => WorldUncheckedUpdateManyWithoutSlapsNestedInputSchema).optional(),
  tags: z.lazy(() => TagUncheckedUpdateManyWithoutSlapsNestedInputSchema).optional(),
  tracks: z.lazy(() => TrackUncheckedUpdateManyWithoutSlapNestedInputSchema).optional(),
  children: z.lazy(() => SlapUncheckedUpdateManyWithoutParentNestedInputSchema).optional(),
  descendants: z.lazy(() => SlapUncheckedUpdateManyWithoutRootNestedInputSchema).optional()
}).strict();

export const SlapUncheckedUpdateManyWithoutCreatorInputSchema: z.ZodType<Prisma.SlapUncheckedUpdateManyWithoutCreatorInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  slug: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  title: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  bpm: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  key: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  currentBpm: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currentKey: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  isRoot: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  isPremade: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  isDeleted: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  duration: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  skinId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  parentId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  rootId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const WorldUpdateWithoutUsersInputSchema: z.ZodType<Prisma.WorldUpdateWithoutUsersInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  title: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  video: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  image: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  description: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  slaps: z.lazy(() => SlapUpdateManyWithoutWorldsNestedInputSchema).optional(),
  tags: z.lazy(() => TagUpdateManyWithoutWorldsNestedInputSchema).optional()
}).strict();

export const WorldUncheckedUpdateWithoutUsersInputSchema: z.ZodType<Prisma.WorldUncheckedUpdateWithoutUsersInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  title: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  video: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  image: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  description: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  slaps: z.lazy(() => SlapUncheckedUpdateManyWithoutWorldsNestedInputSchema).optional(),
  tags: z.lazy(() => TagUncheckedUpdateManyWithoutWorldsNestedInputSchema).optional()
}).strict();

export const WorldUncheckedUpdateManyWithoutUsersInputSchema: z.ZodType<Prisma.WorldUncheckedUpdateManyWithoutUsersInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  title: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  video: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  image: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  description: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

/////////////////////////////////////////
// ARGS
/////////////////////////////////////////

export const WorldFindFirstArgsSchema: z.ZodType<Prisma.WorldFindFirstArgs> = z.object({
  select: WorldSelectSchema.optional(),
  include: WorldIncludeSchema.optional(),
  where: WorldWhereInputSchema.optional(),
  orderBy: z.union([ WorldOrderByWithRelationInputSchema.array(),WorldOrderByWithRelationInputSchema ]).optional(),
  cursor: WorldWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ WorldScalarFieldEnumSchema,WorldScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const WorldFindFirstOrThrowArgsSchema: z.ZodType<Prisma.WorldFindFirstOrThrowArgs> = z.object({
  select: WorldSelectSchema.optional(),
  include: WorldIncludeSchema.optional(),
  where: WorldWhereInputSchema.optional(),
  orderBy: z.union([ WorldOrderByWithRelationInputSchema.array(),WorldOrderByWithRelationInputSchema ]).optional(),
  cursor: WorldWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ WorldScalarFieldEnumSchema,WorldScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const WorldFindManyArgsSchema: z.ZodType<Prisma.WorldFindManyArgs> = z.object({
  select: WorldSelectSchema.optional(),
  include: WorldIncludeSchema.optional(),
  where: WorldWhereInputSchema.optional(),
  orderBy: z.union([ WorldOrderByWithRelationInputSchema.array(),WorldOrderByWithRelationInputSchema ]).optional(),
  cursor: WorldWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ WorldScalarFieldEnumSchema,WorldScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const WorldAggregateArgsSchema: z.ZodType<Prisma.WorldAggregateArgs> = z.object({
  where: WorldWhereInputSchema.optional(),
  orderBy: z.union([ WorldOrderByWithRelationInputSchema.array(),WorldOrderByWithRelationInputSchema ]).optional(),
  cursor: WorldWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const WorldGroupByArgsSchema: z.ZodType<Prisma.WorldGroupByArgs> = z.object({
  where: WorldWhereInputSchema.optional(),
  orderBy: z.union([ WorldOrderByWithAggregationInputSchema.array(),WorldOrderByWithAggregationInputSchema ]).optional(),
  by: WorldScalarFieldEnumSchema.array(),
  having: WorldScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const WorldFindUniqueArgsSchema: z.ZodType<Prisma.WorldFindUniqueArgs> = z.object({
  select: WorldSelectSchema.optional(),
  include: WorldIncludeSchema.optional(),
  where: WorldWhereUniqueInputSchema,
}).strict() ;

export const WorldFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.WorldFindUniqueOrThrowArgs> = z.object({
  select: WorldSelectSchema.optional(),
  include: WorldIncludeSchema.optional(),
  where: WorldWhereUniqueInputSchema,
}).strict() ;

export const TagFindFirstArgsSchema: z.ZodType<Prisma.TagFindFirstArgs> = z.object({
  select: TagSelectSchema.optional(),
  include: TagIncludeSchema.optional(),
  where: TagWhereInputSchema.optional(),
  orderBy: z.union([ TagOrderByWithRelationInputSchema.array(),TagOrderByWithRelationInputSchema ]).optional(),
  cursor: TagWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ TagScalarFieldEnumSchema,TagScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const TagFindFirstOrThrowArgsSchema: z.ZodType<Prisma.TagFindFirstOrThrowArgs> = z.object({
  select: TagSelectSchema.optional(),
  include: TagIncludeSchema.optional(),
  where: TagWhereInputSchema.optional(),
  orderBy: z.union([ TagOrderByWithRelationInputSchema.array(),TagOrderByWithRelationInputSchema ]).optional(),
  cursor: TagWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ TagScalarFieldEnumSchema,TagScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const TagFindManyArgsSchema: z.ZodType<Prisma.TagFindManyArgs> = z.object({
  select: TagSelectSchema.optional(),
  include: TagIncludeSchema.optional(),
  where: TagWhereInputSchema.optional(),
  orderBy: z.union([ TagOrderByWithRelationInputSchema.array(),TagOrderByWithRelationInputSchema ]).optional(),
  cursor: TagWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ TagScalarFieldEnumSchema,TagScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const TagAggregateArgsSchema: z.ZodType<Prisma.TagAggregateArgs> = z.object({
  where: TagWhereInputSchema.optional(),
  orderBy: z.union([ TagOrderByWithRelationInputSchema.array(),TagOrderByWithRelationInputSchema ]).optional(),
  cursor: TagWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const TagGroupByArgsSchema: z.ZodType<Prisma.TagGroupByArgs> = z.object({
  where: TagWhereInputSchema.optional(),
  orderBy: z.union([ TagOrderByWithAggregationInputSchema.array(),TagOrderByWithAggregationInputSchema ]).optional(),
  by: TagScalarFieldEnumSchema.array(),
  having: TagScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const TagFindUniqueArgsSchema: z.ZodType<Prisma.TagFindUniqueArgs> = z.object({
  select: TagSelectSchema.optional(),
  include: TagIncludeSchema.optional(),
  where: TagWhereUniqueInputSchema,
}).strict() ;

export const TagFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.TagFindUniqueOrThrowArgs> = z.object({
  select: TagSelectSchema.optional(),
  include: TagIncludeSchema.optional(),
  where: TagWhereUniqueInputSchema,
}).strict() ;

export const SlapFindFirstArgsSchema: z.ZodType<Prisma.SlapFindFirstArgs> = z.object({
  select: SlapSelectSchema.optional(),
  include: SlapIncludeSchema.optional(),
  where: SlapWhereInputSchema.optional(),
  orderBy: z.union([ SlapOrderByWithRelationInputSchema.array(),SlapOrderByWithRelationInputSchema ]).optional(),
  cursor: SlapWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ SlapScalarFieldEnumSchema,SlapScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const SlapFindFirstOrThrowArgsSchema: z.ZodType<Prisma.SlapFindFirstOrThrowArgs> = z.object({
  select: SlapSelectSchema.optional(),
  include: SlapIncludeSchema.optional(),
  where: SlapWhereInputSchema.optional(),
  orderBy: z.union([ SlapOrderByWithRelationInputSchema.array(),SlapOrderByWithRelationInputSchema ]).optional(),
  cursor: SlapWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ SlapScalarFieldEnumSchema,SlapScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const SlapFindManyArgsSchema: z.ZodType<Prisma.SlapFindManyArgs> = z.object({
  select: SlapSelectSchema.optional(),
  include: SlapIncludeSchema.optional(),
  where: SlapWhereInputSchema.optional(),
  orderBy: z.union([ SlapOrderByWithRelationInputSchema.array(),SlapOrderByWithRelationInputSchema ]).optional(),
  cursor: SlapWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ SlapScalarFieldEnumSchema,SlapScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const SlapAggregateArgsSchema: z.ZodType<Prisma.SlapAggregateArgs> = z.object({
  where: SlapWhereInputSchema.optional(),
  orderBy: z.union([ SlapOrderByWithRelationInputSchema.array(),SlapOrderByWithRelationInputSchema ]).optional(),
  cursor: SlapWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const SlapGroupByArgsSchema: z.ZodType<Prisma.SlapGroupByArgs> = z.object({
  where: SlapWhereInputSchema.optional(),
  orderBy: z.union([ SlapOrderByWithAggregationInputSchema.array(),SlapOrderByWithAggregationInputSchema ]).optional(),
  by: SlapScalarFieldEnumSchema.array(),
  having: SlapScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const SlapFindUniqueArgsSchema: z.ZodType<Prisma.SlapFindUniqueArgs> = z.object({
  select: SlapSelectSchema.optional(),
  include: SlapIncludeSchema.optional(),
  where: SlapWhereUniqueInputSchema,
}).strict() ;

export const SlapFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.SlapFindUniqueOrThrowArgs> = z.object({
  select: SlapSelectSchema.optional(),
  include: SlapIncludeSchema.optional(),
  where: SlapWhereUniqueInputSchema,
}).strict() ;

export const SkinFindFirstArgsSchema: z.ZodType<Prisma.SkinFindFirstArgs> = z.object({
  select: SkinSelectSchema.optional(),
  include: SkinIncludeSchema.optional(),
  where: SkinWhereInputSchema.optional(),
  orderBy: z.union([ SkinOrderByWithRelationInputSchema.array(),SkinOrderByWithRelationInputSchema ]).optional(),
  cursor: SkinWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ SkinScalarFieldEnumSchema,SkinScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const SkinFindFirstOrThrowArgsSchema: z.ZodType<Prisma.SkinFindFirstOrThrowArgs> = z.object({
  select: SkinSelectSchema.optional(),
  include: SkinIncludeSchema.optional(),
  where: SkinWhereInputSchema.optional(),
  orderBy: z.union([ SkinOrderByWithRelationInputSchema.array(),SkinOrderByWithRelationInputSchema ]).optional(),
  cursor: SkinWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ SkinScalarFieldEnumSchema,SkinScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const SkinFindManyArgsSchema: z.ZodType<Prisma.SkinFindManyArgs> = z.object({
  select: SkinSelectSchema.optional(),
  include: SkinIncludeSchema.optional(),
  where: SkinWhereInputSchema.optional(),
  orderBy: z.union([ SkinOrderByWithRelationInputSchema.array(),SkinOrderByWithRelationInputSchema ]).optional(),
  cursor: SkinWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ SkinScalarFieldEnumSchema,SkinScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const SkinAggregateArgsSchema: z.ZodType<Prisma.SkinAggregateArgs> = z.object({
  where: SkinWhereInputSchema.optional(),
  orderBy: z.union([ SkinOrderByWithRelationInputSchema.array(),SkinOrderByWithRelationInputSchema ]).optional(),
  cursor: SkinWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const SkinGroupByArgsSchema: z.ZodType<Prisma.SkinGroupByArgs> = z.object({
  where: SkinWhereInputSchema.optional(),
  orderBy: z.union([ SkinOrderByWithAggregationInputSchema.array(),SkinOrderByWithAggregationInputSchema ]).optional(),
  by: SkinScalarFieldEnumSchema.array(),
  having: SkinScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const SkinFindUniqueArgsSchema: z.ZodType<Prisma.SkinFindUniqueArgs> = z.object({
  select: SkinSelectSchema.optional(),
  include: SkinIncludeSchema.optional(),
  where: SkinWhereUniqueInputSchema,
}).strict() ;

export const SkinFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.SkinFindUniqueOrThrowArgs> = z.object({
  select: SkinSelectSchema.optional(),
  include: SkinIncludeSchema.optional(),
  where: SkinWhereUniqueInputSchema,
}).strict() ;

export const TrackFindFirstArgsSchema: z.ZodType<Prisma.TrackFindFirstArgs> = z.object({
  select: TrackSelectSchema.optional(),
  include: TrackIncludeSchema.optional(),
  where: TrackWhereInputSchema.optional(),
  orderBy: z.union([ TrackOrderByWithRelationInputSchema.array(),TrackOrderByWithRelationInputSchema ]).optional(),
  cursor: TrackWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ TrackScalarFieldEnumSchema,TrackScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const TrackFindFirstOrThrowArgsSchema: z.ZodType<Prisma.TrackFindFirstOrThrowArgs> = z.object({
  select: TrackSelectSchema.optional(),
  include: TrackIncludeSchema.optional(),
  where: TrackWhereInputSchema.optional(),
  orderBy: z.union([ TrackOrderByWithRelationInputSchema.array(),TrackOrderByWithRelationInputSchema ]).optional(),
  cursor: TrackWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ TrackScalarFieldEnumSchema,TrackScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const TrackFindManyArgsSchema: z.ZodType<Prisma.TrackFindManyArgs> = z.object({
  select: TrackSelectSchema.optional(),
  include: TrackIncludeSchema.optional(),
  where: TrackWhereInputSchema.optional(),
  orderBy: z.union([ TrackOrderByWithRelationInputSchema.array(),TrackOrderByWithRelationInputSchema ]).optional(),
  cursor: TrackWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ TrackScalarFieldEnumSchema,TrackScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const TrackAggregateArgsSchema: z.ZodType<Prisma.TrackAggregateArgs> = z.object({
  where: TrackWhereInputSchema.optional(),
  orderBy: z.union([ TrackOrderByWithRelationInputSchema.array(),TrackOrderByWithRelationInputSchema ]).optional(),
  cursor: TrackWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const TrackGroupByArgsSchema: z.ZodType<Prisma.TrackGroupByArgs> = z.object({
  where: TrackWhereInputSchema.optional(),
  orderBy: z.union([ TrackOrderByWithAggregationInputSchema.array(),TrackOrderByWithAggregationInputSchema ]).optional(),
  by: TrackScalarFieldEnumSchema.array(),
  having: TrackScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const TrackFindUniqueArgsSchema: z.ZodType<Prisma.TrackFindUniqueArgs> = z.object({
  select: TrackSelectSchema.optional(),
  include: TrackIncludeSchema.optional(),
  where: TrackWhereUniqueInputSchema,
}).strict() ;

export const TrackFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.TrackFindUniqueOrThrowArgs> = z.object({
  select: TrackSelectSchema.optional(),
  include: TrackIncludeSchema.optional(),
  where: TrackWhereUniqueInputSchema,
}).strict() ;

export const AccountFindFirstArgsSchema: z.ZodType<Prisma.AccountFindFirstArgs> = z.object({
  select: AccountSelectSchema.optional(),
  include: AccountIncludeSchema.optional(),
  where: AccountWhereInputSchema.optional(),
  orderBy: z.union([ AccountOrderByWithRelationInputSchema.array(),AccountOrderByWithRelationInputSchema ]).optional(),
  cursor: AccountWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ AccountScalarFieldEnumSchema,AccountScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const AccountFindFirstOrThrowArgsSchema: z.ZodType<Prisma.AccountFindFirstOrThrowArgs> = z.object({
  select: AccountSelectSchema.optional(),
  include: AccountIncludeSchema.optional(),
  where: AccountWhereInputSchema.optional(),
  orderBy: z.union([ AccountOrderByWithRelationInputSchema.array(),AccountOrderByWithRelationInputSchema ]).optional(),
  cursor: AccountWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ AccountScalarFieldEnumSchema,AccountScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const AccountFindManyArgsSchema: z.ZodType<Prisma.AccountFindManyArgs> = z.object({
  select: AccountSelectSchema.optional(),
  include: AccountIncludeSchema.optional(),
  where: AccountWhereInputSchema.optional(),
  orderBy: z.union([ AccountOrderByWithRelationInputSchema.array(),AccountOrderByWithRelationInputSchema ]).optional(),
  cursor: AccountWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ AccountScalarFieldEnumSchema,AccountScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const AccountAggregateArgsSchema: z.ZodType<Prisma.AccountAggregateArgs> = z.object({
  where: AccountWhereInputSchema.optional(),
  orderBy: z.union([ AccountOrderByWithRelationInputSchema.array(),AccountOrderByWithRelationInputSchema ]).optional(),
  cursor: AccountWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const AccountGroupByArgsSchema: z.ZodType<Prisma.AccountGroupByArgs> = z.object({
  where: AccountWhereInputSchema.optional(),
  orderBy: z.union([ AccountOrderByWithAggregationInputSchema.array(),AccountOrderByWithAggregationInputSchema ]).optional(),
  by: AccountScalarFieldEnumSchema.array(),
  having: AccountScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const AccountFindUniqueArgsSchema: z.ZodType<Prisma.AccountFindUniqueArgs> = z.object({
  select: AccountSelectSchema.optional(),
  include: AccountIncludeSchema.optional(),
  where: AccountWhereUniqueInputSchema,
}).strict() ;

export const AccountFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.AccountFindUniqueOrThrowArgs> = z.object({
  select: AccountSelectSchema.optional(),
  include: AccountIncludeSchema.optional(),
  where: AccountWhereUniqueInputSchema,
}).strict() ;

export const SessionFindFirstArgsSchema: z.ZodType<Prisma.SessionFindFirstArgs> = z.object({
  select: SessionSelectSchema.optional(),
  include: SessionIncludeSchema.optional(),
  where: SessionWhereInputSchema.optional(),
  orderBy: z.union([ SessionOrderByWithRelationInputSchema.array(),SessionOrderByWithRelationInputSchema ]).optional(),
  cursor: SessionWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ SessionScalarFieldEnumSchema,SessionScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const SessionFindFirstOrThrowArgsSchema: z.ZodType<Prisma.SessionFindFirstOrThrowArgs> = z.object({
  select: SessionSelectSchema.optional(),
  include: SessionIncludeSchema.optional(),
  where: SessionWhereInputSchema.optional(),
  orderBy: z.union([ SessionOrderByWithRelationInputSchema.array(),SessionOrderByWithRelationInputSchema ]).optional(),
  cursor: SessionWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ SessionScalarFieldEnumSchema,SessionScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const SessionFindManyArgsSchema: z.ZodType<Prisma.SessionFindManyArgs> = z.object({
  select: SessionSelectSchema.optional(),
  include: SessionIncludeSchema.optional(),
  where: SessionWhereInputSchema.optional(),
  orderBy: z.union([ SessionOrderByWithRelationInputSchema.array(),SessionOrderByWithRelationInputSchema ]).optional(),
  cursor: SessionWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ SessionScalarFieldEnumSchema,SessionScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const SessionAggregateArgsSchema: z.ZodType<Prisma.SessionAggregateArgs> = z.object({
  where: SessionWhereInputSchema.optional(),
  orderBy: z.union([ SessionOrderByWithRelationInputSchema.array(),SessionOrderByWithRelationInputSchema ]).optional(),
  cursor: SessionWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const SessionGroupByArgsSchema: z.ZodType<Prisma.SessionGroupByArgs> = z.object({
  where: SessionWhereInputSchema.optional(),
  orderBy: z.union([ SessionOrderByWithAggregationInputSchema.array(),SessionOrderByWithAggregationInputSchema ]).optional(),
  by: SessionScalarFieldEnumSchema.array(),
  having: SessionScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const SessionFindUniqueArgsSchema: z.ZodType<Prisma.SessionFindUniqueArgs> = z.object({
  select: SessionSelectSchema.optional(),
  include: SessionIncludeSchema.optional(),
  where: SessionWhereUniqueInputSchema,
}).strict() ;

export const SessionFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.SessionFindUniqueOrThrowArgs> = z.object({
  select: SessionSelectSchema.optional(),
  include: SessionIncludeSchema.optional(),
  where: SessionWhereUniqueInputSchema,
}).strict() ;

export const VerificationTokenFindFirstArgsSchema: z.ZodType<Prisma.VerificationTokenFindFirstArgs> = z.object({
  select: VerificationTokenSelectSchema.optional(),
  where: VerificationTokenWhereInputSchema.optional(),
  orderBy: z.union([ VerificationTokenOrderByWithRelationInputSchema.array(),VerificationTokenOrderByWithRelationInputSchema ]).optional(),
  cursor: VerificationTokenWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ VerificationTokenScalarFieldEnumSchema,VerificationTokenScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const VerificationTokenFindFirstOrThrowArgsSchema: z.ZodType<Prisma.VerificationTokenFindFirstOrThrowArgs> = z.object({
  select: VerificationTokenSelectSchema.optional(),
  where: VerificationTokenWhereInputSchema.optional(),
  orderBy: z.union([ VerificationTokenOrderByWithRelationInputSchema.array(),VerificationTokenOrderByWithRelationInputSchema ]).optional(),
  cursor: VerificationTokenWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ VerificationTokenScalarFieldEnumSchema,VerificationTokenScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const VerificationTokenFindManyArgsSchema: z.ZodType<Prisma.VerificationTokenFindManyArgs> = z.object({
  select: VerificationTokenSelectSchema.optional(),
  where: VerificationTokenWhereInputSchema.optional(),
  orderBy: z.union([ VerificationTokenOrderByWithRelationInputSchema.array(),VerificationTokenOrderByWithRelationInputSchema ]).optional(),
  cursor: VerificationTokenWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ VerificationTokenScalarFieldEnumSchema,VerificationTokenScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const VerificationTokenAggregateArgsSchema: z.ZodType<Prisma.VerificationTokenAggregateArgs> = z.object({
  where: VerificationTokenWhereInputSchema.optional(),
  orderBy: z.union([ VerificationTokenOrderByWithRelationInputSchema.array(),VerificationTokenOrderByWithRelationInputSchema ]).optional(),
  cursor: VerificationTokenWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const VerificationTokenGroupByArgsSchema: z.ZodType<Prisma.VerificationTokenGroupByArgs> = z.object({
  where: VerificationTokenWhereInputSchema.optional(),
  orderBy: z.union([ VerificationTokenOrderByWithAggregationInputSchema.array(),VerificationTokenOrderByWithAggregationInputSchema ]).optional(),
  by: VerificationTokenScalarFieldEnumSchema.array(),
  having: VerificationTokenScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const VerificationTokenFindUniqueArgsSchema: z.ZodType<Prisma.VerificationTokenFindUniqueArgs> = z.object({
  select: VerificationTokenSelectSchema.optional(),
  where: VerificationTokenWhereUniqueInputSchema,
}).strict() ;

export const VerificationTokenFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.VerificationTokenFindUniqueOrThrowArgs> = z.object({
  select: VerificationTokenSelectSchema.optional(),
  where: VerificationTokenWhereUniqueInputSchema,
}).strict() ;

export const UserFindFirstArgsSchema: z.ZodType<Prisma.UserFindFirstArgs> = z.object({
  select: UserSelectSchema.optional(),
  include: UserIncludeSchema.optional(),
  where: UserWhereInputSchema.optional(),
  orderBy: z.union([ UserOrderByWithRelationInputSchema.array(),UserOrderByWithRelationInputSchema ]).optional(),
  cursor: UserWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ UserScalarFieldEnumSchema,UserScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const UserFindFirstOrThrowArgsSchema: z.ZodType<Prisma.UserFindFirstOrThrowArgs> = z.object({
  select: UserSelectSchema.optional(),
  include: UserIncludeSchema.optional(),
  where: UserWhereInputSchema.optional(),
  orderBy: z.union([ UserOrderByWithRelationInputSchema.array(),UserOrderByWithRelationInputSchema ]).optional(),
  cursor: UserWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ UserScalarFieldEnumSchema,UserScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const UserFindManyArgsSchema: z.ZodType<Prisma.UserFindManyArgs> = z.object({
  select: UserSelectSchema.optional(),
  include: UserIncludeSchema.optional(),
  where: UserWhereInputSchema.optional(),
  orderBy: z.union([ UserOrderByWithRelationInputSchema.array(),UserOrderByWithRelationInputSchema ]).optional(),
  cursor: UserWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ UserScalarFieldEnumSchema,UserScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const UserAggregateArgsSchema: z.ZodType<Prisma.UserAggregateArgs> = z.object({
  where: UserWhereInputSchema.optional(),
  orderBy: z.union([ UserOrderByWithRelationInputSchema.array(),UserOrderByWithRelationInputSchema ]).optional(),
  cursor: UserWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const UserGroupByArgsSchema: z.ZodType<Prisma.UserGroupByArgs> = z.object({
  where: UserWhereInputSchema.optional(),
  orderBy: z.union([ UserOrderByWithAggregationInputSchema.array(),UserOrderByWithAggregationInputSchema ]).optional(),
  by: UserScalarFieldEnumSchema.array(),
  having: UserScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const UserFindUniqueArgsSchema: z.ZodType<Prisma.UserFindUniqueArgs> = z.object({
  select: UserSelectSchema.optional(),
  include: UserIncludeSchema.optional(),
  where: UserWhereUniqueInputSchema,
}).strict() ;

export const UserFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.UserFindUniqueOrThrowArgs> = z.object({
  select: UserSelectSchema.optional(),
  include: UserIncludeSchema.optional(),
  where: UserWhereUniqueInputSchema,
}).strict() ;

export const SampleFindFirstArgsSchema: z.ZodType<Prisma.SampleFindFirstArgs> = z.object({
  select: SampleSelectSchema.optional(),
  where: SampleWhereInputSchema.optional(),
  orderBy: z.union([ SampleOrderByWithRelationInputSchema.array(),SampleOrderByWithRelationInputSchema ]).optional(),
  cursor: SampleWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ SampleScalarFieldEnumSchema,SampleScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const SampleFindFirstOrThrowArgsSchema: z.ZodType<Prisma.SampleFindFirstOrThrowArgs> = z.object({
  select: SampleSelectSchema.optional(),
  where: SampleWhereInputSchema.optional(),
  orderBy: z.union([ SampleOrderByWithRelationInputSchema.array(),SampleOrderByWithRelationInputSchema ]).optional(),
  cursor: SampleWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ SampleScalarFieldEnumSchema,SampleScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const SampleFindManyArgsSchema: z.ZodType<Prisma.SampleFindManyArgs> = z.object({
  select: SampleSelectSchema.optional(),
  where: SampleWhereInputSchema.optional(),
  orderBy: z.union([ SampleOrderByWithRelationInputSchema.array(),SampleOrderByWithRelationInputSchema ]).optional(),
  cursor: SampleWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ SampleScalarFieldEnumSchema,SampleScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const SampleAggregateArgsSchema: z.ZodType<Prisma.SampleAggregateArgs> = z.object({
  where: SampleWhereInputSchema.optional(),
  orderBy: z.union([ SampleOrderByWithRelationInputSchema.array(),SampleOrderByWithRelationInputSchema ]).optional(),
  cursor: SampleWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const SampleGroupByArgsSchema: z.ZodType<Prisma.SampleGroupByArgs> = z.object({
  where: SampleWhereInputSchema.optional(),
  orderBy: z.union([ SampleOrderByWithAggregationInputSchema.array(),SampleOrderByWithAggregationInputSchema ]).optional(),
  by: SampleScalarFieldEnumSchema.array(),
  having: SampleScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const SampleFindUniqueArgsSchema: z.ZodType<Prisma.SampleFindUniqueArgs> = z.object({
  select: SampleSelectSchema.optional(),
  where: SampleWhereUniqueInputSchema,
}).strict() ;

export const SampleFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.SampleFindUniqueOrThrowArgs> = z.object({
  select: SampleSelectSchema.optional(),
  where: SampleWhereUniqueInputSchema,
}).strict() ;

export const WorldCreateArgsSchema: z.ZodType<Prisma.WorldCreateArgs> = z.object({
  select: WorldSelectSchema.optional(),
  include: WorldIncludeSchema.optional(),
  data: z.union([ WorldCreateInputSchema,WorldUncheckedCreateInputSchema ]),
}).strict() ;

export const WorldUpsertArgsSchema: z.ZodType<Prisma.WorldUpsertArgs> = z.object({
  select: WorldSelectSchema.optional(),
  include: WorldIncludeSchema.optional(),
  where: WorldWhereUniqueInputSchema,
  create: z.union([ WorldCreateInputSchema,WorldUncheckedCreateInputSchema ]),
  update: z.union([ WorldUpdateInputSchema,WorldUncheckedUpdateInputSchema ]),
}).strict() ;

export const WorldCreateManyArgsSchema: z.ZodType<Prisma.WorldCreateManyArgs> = z.object({
  data: z.union([ WorldCreateManyInputSchema,WorldCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const WorldCreateManyAndReturnArgsSchema: z.ZodType<Prisma.WorldCreateManyAndReturnArgs> = z.object({
  data: z.union([ WorldCreateManyInputSchema,WorldCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const WorldDeleteArgsSchema: z.ZodType<Prisma.WorldDeleteArgs> = z.object({
  select: WorldSelectSchema.optional(),
  include: WorldIncludeSchema.optional(),
  where: WorldWhereUniqueInputSchema,
}).strict() ;

export const WorldUpdateArgsSchema: z.ZodType<Prisma.WorldUpdateArgs> = z.object({
  select: WorldSelectSchema.optional(),
  include: WorldIncludeSchema.optional(),
  data: z.union([ WorldUpdateInputSchema,WorldUncheckedUpdateInputSchema ]),
  where: WorldWhereUniqueInputSchema,
}).strict() ;

export const WorldUpdateManyArgsSchema: z.ZodType<Prisma.WorldUpdateManyArgs> = z.object({
  data: z.union([ WorldUpdateManyMutationInputSchema,WorldUncheckedUpdateManyInputSchema ]),
  where: WorldWhereInputSchema.optional(),
}).strict() ;

export const WorldDeleteManyArgsSchema: z.ZodType<Prisma.WorldDeleteManyArgs> = z.object({
  where: WorldWhereInputSchema.optional(),
}).strict() ;

export const TagCreateArgsSchema: z.ZodType<Prisma.TagCreateArgs> = z.object({
  select: TagSelectSchema.optional(),
  include: TagIncludeSchema.optional(),
  data: z.union([ TagCreateInputSchema,TagUncheckedCreateInputSchema ]),
}).strict() ;

export const TagUpsertArgsSchema: z.ZodType<Prisma.TagUpsertArgs> = z.object({
  select: TagSelectSchema.optional(),
  include: TagIncludeSchema.optional(),
  where: TagWhereUniqueInputSchema,
  create: z.union([ TagCreateInputSchema,TagUncheckedCreateInputSchema ]),
  update: z.union([ TagUpdateInputSchema,TagUncheckedUpdateInputSchema ]),
}).strict() ;

export const TagCreateManyArgsSchema: z.ZodType<Prisma.TagCreateManyArgs> = z.object({
  data: z.union([ TagCreateManyInputSchema,TagCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const TagCreateManyAndReturnArgsSchema: z.ZodType<Prisma.TagCreateManyAndReturnArgs> = z.object({
  data: z.union([ TagCreateManyInputSchema,TagCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const TagDeleteArgsSchema: z.ZodType<Prisma.TagDeleteArgs> = z.object({
  select: TagSelectSchema.optional(),
  include: TagIncludeSchema.optional(),
  where: TagWhereUniqueInputSchema,
}).strict() ;

export const TagUpdateArgsSchema: z.ZodType<Prisma.TagUpdateArgs> = z.object({
  select: TagSelectSchema.optional(),
  include: TagIncludeSchema.optional(),
  data: z.union([ TagUpdateInputSchema,TagUncheckedUpdateInputSchema ]),
  where: TagWhereUniqueInputSchema,
}).strict() ;

export const TagUpdateManyArgsSchema: z.ZodType<Prisma.TagUpdateManyArgs> = z.object({
  data: z.union([ TagUpdateManyMutationInputSchema,TagUncheckedUpdateManyInputSchema ]),
  where: TagWhereInputSchema.optional(),
}).strict() ;

export const TagDeleteManyArgsSchema: z.ZodType<Prisma.TagDeleteManyArgs> = z.object({
  where: TagWhereInputSchema.optional(),
}).strict() ;

export const SlapCreateArgsSchema: z.ZodType<Prisma.SlapCreateArgs> = z.object({
  select: SlapSelectSchema.optional(),
  include: SlapIncludeSchema.optional(),
  data: z.union([ SlapCreateInputSchema,SlapUncheckedCreateInputSchema ]),
}).strict() ;

export const SlapUpsertArgsSchema: z.ZodType<Prisma.SlapUpsertArgs> = z.object({
  select: SlapSelectSchema.optional(),
  include: SlapIncludeSchema.optional(),
  where: SlapWhereUniqueInputSchema,
  create: z.union([ SlapCreateInputSchema,SlapUncheckedCreateInputSchema ]),
  update: z.union([ SlapUpdateInputSchema,SlapUncheckedUpdateInputSchema ]),
}).strict() ;

export const SlapCreateManyArgsSchema: z.ZodType<Prisma.SlapCreateManyArgs> = z.object({
  data: z.union([ SlapCreateManyInputSchema,SlapCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const SlapCreateManyAndReturnArgsSchema: z.ZodType<Prisma.SlapCreateManyAndReturnArgs> = z.object({
  data: z.union([ SlapCreateManyInputSchema,SlapCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const SlapDeleteArgsSchema: z.ZodType<Prisma.SlapDeleteArgs> = z.object({
  select: SlapSelectSchema.optional(),
  include: SlapIncludeSchema.optional(),
  where: SlapWhereUniqueInputSchema,
}).strict() ;

export const SlapUpdateArgsSchema: z.ZodType<Prisma.SlapUpdateArgs> = z.object({
  select: SlapSelectSchema.optional(),
  include: SlapIncludeSchema.optional(),
  data: z.union([ SlapUpdateInputSchema,SlapUncheckedUpdateInputSchema ]),
  where: SlapWhereUniqueInputSchema,
}).strict() ;

export const SlapUpdateManyArgsSchema: z.ZodType<Prisma.SlapUpdateManyArgs> = z.object({
  data: z.union([ SlapUpdateManyMutationInputSchema,SlapUncheckedUpdateManyInputSchema ]),
  where: SlapWhereInputSchema.optional(),
}).strict() ;

export const SlapDeleteManyArgsSchema: z.ZodType<Prisma.SlapDeleteManyArgs> = z.object({
  where: SlapWhereInputSchema.optional(),
}).strict() ;

export const SkinCreateArgsSchema: z.ZodType<Prisma.SkinCreateArgs> = z.object({
  select: SkinSelectSchema.optional(),
  include: SkinIncludeSchema.optional(),
  data: z.union([ SkinCreateInputSchema,SkinUncheckedCreateInputSchema ]),
}).strict() ;

export const SkinUpsertArgsSchema: z.ZodType<Prisma.SkinUpsertArgs> = z.object({
  select: SkinSelectSchema.optional(),
  include: SkinIncludeSchema.optional(),
  where: SkinWhereUniqueInputSchema,
  create: z.union([ SkinCreateInputSchema,SkinUncheckedCreateInputSchema ]),
  update: z.union([ SkinUpdateInputSchema,SkinUncheckedUpdateInputSchema ]),
}).strict() ;

export const SkinCreateManyArgsSchema: z.ZodType<Prisma.SkinCreateManyArgs> = z.object({
  data: z.union([ SkinCreateManyInputSchema,SkinCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const SkinCreateManyAndReturnArgsSchema: z.ZodType<Prisma.SkinCreateManyAndReturnArgs> = z.object({
  data: z.union([ SkinCreateManyInputSchema,SkinCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const SkinDeleteArgsSchema: z.ZodType<Prisma.SkinDeleteArgs> = z.object({
  select: SkinSelectSchema.optional(),
  include: SkinIncludeSchema.optional(),
  where: SkinWhereUniqueInputSchema,
}).strict() ;

export const SkinUpdateArgsSchema: z.ZodType<Prisma.SkinUpdateArgs> = z.object({
  select: SkinSelectSchema.optional(),
  include: SkinIncludeSchema.optional(),
  data: z.union([ SkinUpdateInputSchema,SkinUncheckedUpdateInputSchema ]),
  where: SkinWhereUniqueInputSchema,
}).strict() ;

export const SkinUpdateManyArgsSchema: z.ZodType<Prisma.SkinUpdateManyArgs> = z.object({
  data: z.union([ SkinUpdateManyMutationInputSchema,SkinUncheckedUpdateManyInputSchema ]),
  where: SkinWhereInputSchema.optional(),
}).strict() ;

export const SkinDeleteManyArgsSchema: z.ZodType<Prisma.SkinDeleteManyArgs> = z.object({
  where: SkinWhereInputSchema.optional(),
}).strict() ;

export const TrackCreateArgsSchema: z.ZodType<Prisma.TrackCreateArgs> = z.object({
  select: TrackSelectSchema.optional(),
  include: TrackIncludeSchema.optional(),
  data: z.union([ TrackCreateInputSchema,TrackUncheckedCreateInputSchema ]),
}).strict() ;

export const TrackUpsertArgsSchema: z.ZodType<Prisma.TrackUpsertArgs> = z.object({
  select: TrackSelectSchema.optional(),
  include: TrackIncludeSchema.optional(),
  where: TrackWhereUniqueInputSchema,
  create: z.union([ TrackCreateInputSchema,TrackUncheckedCreateInputSchema ]),
  update: z.union([ TrackUpdateInputSchema,TrackUncheckedUpdateInputSchema ]),
}).strict() ;

export const TrackCreateManyArgsSchema: z.ZodType<Prisma.TrackCreateManyArgs> = z.object({
  data: z.union([ TrackCreateManyInputSchema,TrackCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const TrackCreateManyAndReturnArgsSchema: z.ZodType<Prisma.TrackCreateManyAndReturnArgs> = z.object({
  data: z.union([ TrackCreateManyInputSchema,TrackCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const TrackDeleteArgsSchema: z.ZodType<Prisma.TrackDeleteArgs> = z.object({
  select: TrackSelectSchema.optional(),
  include: TrackIncludeSchema.optional(),
  where: TrackWhereUniqueInputSchema,
}).strict() ;

export const TrackUpdateArgsSchema: z.ZodType<Prisma.TrackUpdateArgs> = z.object({
  select: TrackSelectSchema.optional(),
  include: TrackIncludeSchema.optional(),
  data: z.union([ TrackUpdateInputSchema,TrackUncheckedUpdateInputSchema ]),
  where: TrackWhereUniqueInputSchema,
}).strict() ;

export const TrackUpdateManyArgsSchema: z.ZodType<Prisma.TrackUpdateManyArgs> = z.object({
  data: z.union([ TrackUpdateManyMutationInputSchema,TrackUncheckedUpdateManyInputSchema ]),
  where: TrackWhereInputSchema.optional(),
}).strict() ;

export const TrackDeleteManyArgsSchema: z.ZodType<Prisma.TrackDeleteManyArgs> = z.object({
  where: TrackWhereInputSchema.optional(),
}).strict() ;

export const AccountCreateArgsSchema: z.ZodType<Prisma.AccountCreateArgs> = z.object({
  select: AccountSelectSchema.optional(),
  include: AccountIncludeSchema.optional(),
  data: z.union([ AccountCreateInputSchema,AccountUncheckedCreateInputSchema ]),
}).strict() ;

export const AccountUpsertArgsSchema: z.ZodType<Prisma.AccountUpsertArgs> = z.object({
  select: AccountSelectSchema.optional(),
  include: AccountIncludeSchema.optional(),
  where: AccountWhereUniqueInputSchema,
  create: z.union([ AccountCreateInputSchema,AccountUncheckedCreateInputSchema ]),
  update: z.union([ AccountUpdateInputSchema,AccountUncheckedUpdateInputSchema ]),
}).strict() ;

export const AccountCreateManyArgsSchema: z.ZodType<Prisma.AccountCreateManyArgs> = z.object({
  data: z.union([ AccountCreateManyInputSchema,AccountCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const AccountCreateManyAndReturnArgsSchema: z.ZodType<Prisma.AccountCreateManyAndReturnArgs> = z.object({
  data: z.union([ AccountCreateManyInputSchema,AccountCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const AccountDeleteArgsSchema: z.ZodType<Prisma.AccountDeleteArgs> = z.object({
  select: AccountSelectSchema.optional(),
  include: AccountIncludeSchema.optional(),
  where: AccountWhereUniqueInputSchema,
}).strict() ;

export const AccountUpdateArgsSchema: z.ZodType<Prisma.AccountUpdateArgs> = z.object({
  select: AccountSelectSchema.optional(),
  include: AccountIncludeSchema.optional(),
  data: z.union([ AccountUpdateInputSchema,AccountUncheckedUpdateInputSchema ]),
  where: AccountWhereUniqueInputSchema,
}).strict() ;

export const AccountUpdateManyArgsSchema: z.ZodType<Prisma.AccountUpdateManyArgs> = z.object({
  data: z.union([ AccountUpdateManyMutationInputSchema,AccountUncheckedUpdateManyInputSchema ]),
  where: AccountWhereInputSchema.optional(),
}).strict() ;

export const AccountDeleteManyArgsSchema: z.ZodType<Prisma.AccountDeleteManyArgs> = z.object({
  where: AccountWhereInputSchema.optional(),
}).strict() ;

export const SessionCreateArgsSchema: z.ZodType<Prisma.SessionCreateArgs> = z.object({
  select: SessionSelectSchema.optional(),
  include: SessionIncludeSchema.optional(),
  data: z.union([ SessionCreateInputSchema,SessionUncheckedCreateInputSchema ]),
}).strict() ;

export const SessionUpsertArgsSchema: z.ZodType<Prisma.SessionUpsertArgs> = z.object({
  select: SessionSelectSchema.optional(),
  include: SessionIncludeSchema.optional(),
  where: SessionWhereUniqueInputSchema,
  create: z.union([ SessionCreateInputSchema,SessionUncheckedCreateInputSchema ]),
  update: z.union([ SessionUpdateInputSchema,SessionUncheckedUpdateInputSchema ]),
}).strict() ;

export const SessionCreateManyArgsSchema: z.ZodType<Prisma.SessionCreateManyArgs> = z.object({
  data: z.union([ SessionCreateManyInputSchema,SessionCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const SessionCreateManyAndReturnArgsSchema: z.ZodType<Prisma.SessionCreateManyAndReturnArgs> = z.object({
  data: z.union([ SessionCreateManyInputSchema,SessionCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const SessionDeleteArgsSchema: z.ZodType<Prisma.SessionDeleteArgs> = z.object({
  select: SessionSelectSchema.optional(),
  include: SessionIncludeSchema.optional(),
  where: SessionWhereUniqueInputSchema,
}).strict() ;

export const SessionUpdateArgsSchema: z.ZodType<Prisma.SessionUpdateArgs> = z.object({
  select: SessionSelectSchema.optional(),
  include: SessionIncludeSchema.optional(),
  data: z.union([ SessionUpdateInputSchema,SessionUncheckedUpdateInputSchema ]),
  where: SessionWhereUniqueInputSchema,
}).strict() ;

export const SessionUpdateManyArgsSchema: z.ZodType<Prisma.SessionUpdateManyArgs> = z.object({
  data: z.union([ SessionUpdateManyMutationInputSchema,SessionUncheckedUpdateManyInputSchema ]),
  where: SessionWhereInputSchema.optional(),
}).strict() ;

export const SessionDeleteManyArgsSchema: z.ZodType<Prisma.SessionDeleteManyArgs> = z.object({
  where: SessionWhereInputSchema.optional(),
}).strict() ;

export const VerificationTokenCreateArgsSchema: z.ZodType<Prisma.VerificationTokenCreateArgs> = z.object({
  select: VerificationTokenSelectSchema.optional(),
  data: z.union([ VerificationTokenCreateInputSchema,VerificationTokenUncheckedCreateInputSchema ]),
}).strict() ;

export const VerificationTokenUpsertArgsSchema: z.ZodType<Prisma.VerificationTokenUpsertArgs> = z.object({
  select: VerificationTokenSelectSchema.optional(),
  where: VerificationTokenWhereUniqueInputSchema,
  create: z.union([ VerificationTokenCreateInputSchema,VerificationTokenUncheckedCreateInputSchema ]),
  update: z.union([ VerificationTokenUpdateInputSchema,VerificationTokenUncheckedUpdateInputSchema ]),
}).strict() ;

export const VerificationTokenCreateManyArgsSchema: z.ZodType<Prisma.VerificationTokenCreateManyArgs> = z.object({
  data: z.union([ VerificationTokenCreateManyInputSchema,VerificationTokenCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const VerificationTokenCreateManyAndReturnArgsSchema: z.ZodType<Prisma.VerificationTokenCreateManyAndReturnArgs> = z.object({
  data: z.union([ VerificationTokenCreateManyInputSchema,VerificationTokenCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const VerificationTokenDeleteArgsSchema: z.ZodType<Prisma.VerificationTokenDeleteArgs> = z.object({
  select: VerificationTokenSelectSchema.optional(),
  where: VerificationTokenWhereUniqueInputSchema,
}).strict() ;

export const VerificationTokenUpdateArgsSchema: z.ZodType<Prisma.VerificationTokenUpdateArgs> = z.object({
  select: VerificationTokenSelectSchema.optional(),
  data: z.union([ VerificationTokenUpdateInputSchema,VerificationTokenUncheckedUpdateInputSchema ]),
  where: VerificationTokenWhereUniqueInputSchema,
}).strict() ;

export const VerificationTokenUpdateManyArgsSchema: z.ZodType<Prisma.VerificationTokenUpdateManyArgs> = z.object({
  data: z.union([ VerificationTokenUpdateManyMutationInputSchema,VerificationTokenUncheckedUpdateManyInputSchema ]),
  where: VerificationTokenWhereInputSchema.optional(),
}).strict() ;

export const VerificationTokenDeleteManyArgsSchema: z.ZodType<Prisma.VerificationTokenDeleteManyArgs> = z.object({
  where: VerificationTokenWhereInputSchema.optional(),
}).strict() ;

export const UserCreateArgsSchema: z.ZodType<Prisma.UserCreateArgs> = z.object({
  select: UserSelectSchema.optional(),
  include: UserIncludeSchema.optional(),
  data: z.union([ UserCreateInputSchema,UserUncheckedCreateInputSchema ]),
}).strict() ;

export const UserUpsertArgsSchema: z.ZodType<Prisma.UserUpsertArgs> = z.object({
  select: UserSelectSchema.optional(),
  include: UserIncludeSchema.optional(),
  where: UserWhereUniqueInputSchema,
  create: z.union([ UserCreateInputSchema,UserUncheckedCreateInputSchema ]),
  update: z.union([ UserUpdateInputSchema,UserUncheckedUpdateInputSchema ]),
}).strict() ;

export const UserCreateManyArgsSchema: z.ZodType<Prisma.UserCreateManyArgs> = z.object({
  data: z.union([ UserCreateManyInputSchema,UserCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const UserCreateManyAndReturnArgsSchema: z.ZodType<Prisma.UserCreateManyAndReturnArgs> = z.object({
  data: z.union([ UserCreateManyInputSchema,UserCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const UserDeleteArgsSchema: z.ZodType<Prisma.UserDeleteArgs> = z.object({
  select: UserSelectSchema.optional(),
  include: UserIncludeSchema.optional(),
  where: UserWhereUniqueInputSchema,
}).strict() ;

export const UserUpdateArgsSchema: z.ZodType<Prisma.UserUpdateArgs> = z.object({
  select: UserSelectSchema.optional(),
  include: UserIncludeSchema.optional(),
  data: z.union([ UserUpdateInputSchema,UserUncheckedUpdateInputSchema ]),
  where: UserWhereUniqueInputSchema,
}).strict() ;

export const UserUpdateManyArgsSchema: z.ZodType<Prisma.UserUpdateManyArgs> = z.object({
  data: z.union([ UserUpdateManyMutationInputSchema,UserUncheckedUpdateManyInputSchema ]),
  where: UserWhereInputSchema.optional(),
}).strict() ;

export const UserDeleteManyArgsSchema: z.ZodType<Prisma.UserDeleteManyArgs> = z.object({
  where: UserWhereInputSchema.optional(),
}).strict() ;

export const SampleCreateArgsSchema: z.ZodType<Prisma.SampleCreateArgs> = z.object({
  select: SampleSelectSchema.optional(),
  data: z.union([ SampleCreateInputSchema,SampleUncheckedCreateInputSchema ]),
}).strict() ;

export const SampleUpsertArgsSchema: z.ZodType<Prisma.SampleUpsertArgs> = z.object({
  select: SampleSelectSchema.optional(),
  where: SampleWhereUniqueInputSchema,
  create: z.union([ SampleCreateInputSchema,SampleUncheckedCreateInputSchema ]),
  update: z.union([ SampleUpdateInputSchema,SampleUncheckedUpdateInputSchema ]),
}).strict() ;

export const SampleCreateManyArgsSchema: z.ZodType<Prisma.SampleCreateManyArgs> = z.object({
  data: z.union([ SampleCreateManyInputSchema,SampleCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const SampleCreateManyAndReturnArgsSchema: z.ZodType<Prisma.SampleCreateManyAndReturnArgs> = z.object({
  data: z.union([ SampleCreateManyInputSchema,SampleCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const SampleDeleteArgsSchema: z.ZodType<Prisma.SampleDeleteArgs> = z.object({
  select: SampleSelectSchema.optional(),
  where: SampleWhereUniqueInputSchema,
}).strict() ;

export const SampleUpdateArgsSchema: z.ZodType<Prisma.SampleUpdateArgs> = z.object({
  select: SampleSelectSchema.optional(),
  data: z.union([ SampleUpdateInputSchema,SampleUncheckedUpdateInputSchema ]),
  where: SampleWhereUniqueInputSchema,
}).strict() ;

export const SampleUpdateManyArgsSchema: z.ZodType<Prisma.SampleUpdateManyArgs> = z.object({
  data: z.union([ SampleUpdateManyMutationInputSchema,SampleUncheckedUpdateManyInputSchema ]),
  where: SampleWhereInputSchema.optional(),
}).strict() ;

export const SampleDeleteManyArgsSchema: z.ZodType<Prisma.SampleDeleteManyArgs> = z.object({
  where: SampleWhereInputSchema.optional(),
}).strict() ;