import { EndpointCompletedOutput, EndpointIncompleteOutput } from 'runpod-sdk'

import { Track } from './context/audio-provider'
import { Skin, Slap, Role, Tag } from '@prisma/client'
import { z } from 'zod'
import { SampleSchema } from 'prisma/generated/zod'
import type { IconType } from 'react-icons/lib'
import { Dispatch, FC, SetStateAction } from 'react'

export { Role }
export const isEndpointCompletedOutput = (
  result: EndpointCompletedOutput | EndpointIncompleteOutput | undefined
): result is EndpointCompletedOutput => (result as EndpointCompletedOutput)?.output !== undefined

export type SlapWithTracksSerializable = Omit<Slap, 'updatedAt' | 'createdAt'> & {
  tracks: Omit<Track, 'player'>[]
}

export type SlapWithTracksAndSkinSerializable = SlapWithTracksSerializable & {
  skin: Skin
}

export type SlapWithTracks = Slap & {
  tracks: Omit<Track, 'player'>[]
}

export type SlapWithTracksAndSkin = SlapWithTracks & {
  skin: Skin
}

export interface Loop {
  key: string
  downbeat_times: number[]
  bpm: number
  duration: number
  url: string
  genre: string
  title: string
  audio?: {} | string
}

export type SlapWithTracksAndSkinAndTags = SlapWithTracksAndSkin & {
  tags: Tag[]
}

export enum Instrument {
  BASS = 'bass',
  DRUMS = 'drums',
  GUITAR = 'guitar',
  PIANO = 'piano',
  FLUTE = 'flute',
  VOCALS = 'vocals',
  RECORDING = 'recording',
  OTHER = 'other',
  NO_VOCALS = 'no_vocals'
}

type StemsOutput = {
  stems: {
    [key in Instrument]?: string
  }
}

export const isStemsResult = (result: any): result is StemsResult =>
  (result as StemsResult)?.output !== undefined && (result as StemsResult)?.output?.stems !== undefined

export interface StemsResult {
  delayTime?: number
  executionTime?: number
  id: string
  output: StemsOutput
  status: string // it's really RunpodStatus but that messes up types
  started: boolean
  completed: boolean
  succeeded: boolean
  error?: string
}

export enum Status {
  Loading = 'loading',
  Success = 'success',
  Error = 'error',
  Empty = 'empty',
  Idle = 'idle'
}

export type SongData = {
  audio: string
  downbeat_times: number[]
  sample_rate: number
  text_prompt: string
  bpm: number
  key: string
  debug:
    | {
        downbeat_times: number[]
        end_time: number
        initial_duration: number
        start_time: number
        stretch_rate: number | null
      }
    | {}
  duration: number
}

const AudioSchema = z.union([z.object({}), z.string()])
const SampleLoadedSchema = SampleSchema.extend({
  audio: AudioSchema.optional()
})

export type Sample = z.infer<typeof SampleLoadedSchema>

export type PromptTemplate = {
  prompt: string
  label: string
  key: string
  bpm: number
  samples: Loop[]
}

export type GenreListItem = {
  name: string
  drumCount: bigint
  songstarterCount: bigint
}

export type ActionItem = {
  id: string
  name: string
  description: string
  scopes: string[]
  Icon: IconType
  execute?: (t: Track | null) => void
  Component?: FC<{
    track: Track | null
    isLoading: boolean
    setIsLoading: Dispatch<SetStateAction<boolean>>
    setIsOpen: Dispatch<SetStateAction<boolean>>
    waitForTrackToEnd: () => Promise<void>
  }>
}
